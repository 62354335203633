import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import SpaceCockpitFrame from './SpaceCockpitFrame';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { FaLongArrowAltDown, FaLongArrowAltLeft, FaLongArrowAltRight, FaLongArrowAltUp } from 'react-icons/fa';
import { BiFullscreen, BiSolidDockBottom, BiSolidDockLeft, BiSolidDockRight, BiSolidDockTop } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { IoMdSettings } from 'react-icons/io';
import { getDashboards } from '../lib/context/DashboardProvider';
import { AppState } from '../lib/context/AppProvider';

export const getSCPos = (currentSpaceCockpitPosition) => {
  return currentSpaceCockpitPosition?.position?.toLowerCase();
};

const isVerticalLayout = (currentSpaceCockpitPosition) => {
  const pos = getSCPos(currentSpaceCockpitPosition);
  return pos === "top" || pos === "bottom";
};

const CollapseButton = ({panelRef, panelMinSize, panelDefaultSize, currentPosition, suffix=""}) => {
  const pos = getSCPos(currentPosition);
  const isVertical = isVerticalLayout(currentPosition);

  const panel = panelRef.current;
  const isCollapsed = panel?.isCollapsed();
  
  const cTitle = (isCollapsed ? "Expand" : "Collapse") + suffix;

  const verticalIcon = isCollapsed === (pos === "bottom") ? 
    <FaLongArrowAltUp title={cTitle} style={{height: "20px"}} /> : 
    <FaLongArrowAltDown title={cTitle} style={{height: "20px"}} />;
  const horizontalIcon = isCollapsed === (pos === "right") ? 
    <FaLongArrowAltLeft title={cTitle} /> : 
    <FaLongArrowAltRight title={cTitle} />;

  let collapseOrientation = pos;
  if(isCollapsed) {
    collapseOrientation = isVertical ? "right" : "bottom"; // when collapsed, show buttons to the right if vertical and to the bottom if horizontal
  }

  return (
    <div 
        className={`panel-control-button collapse-handle collapse-handle-${collapseOrientation} highlight-inverse`} 
        onClick={() => {
          if(!isCollapsed) {
            try {
              panel.collapse();
            } catch (e) {console.error(e);}
          }
          else {
            panel.expand();
            if(panel.getSize() <= panelMinSize + 1) {
              // it gets stuck sometimes
              panel.resize(panelDefaultSize);
            }
          }
      }}>
        {isVertical ? verticalIcon : horizontalIcon}
      </div>
  );
};

const ControlButtons = ({
  panelRef, panelMinSize, panelDefaultSize, 
  currentPosition, setCurrentPosition,
  showDockVertical=true, showDockHorizontal=true,
  isSC, showFullscreen=true,
  isHome=false
}) => {

  const pos = getSCPos(currentPosition);
  const isVertical = isVerticalLayout(currentPosition);

  const panel = panelRef.current;
  const isCollapsed = panel?.isCollapsed();
  const direction = isVertical ? "vertical" : "horizontal";
  const orientation = isVertical !== isCollapsed ? "vertical" : "horizontal";

  const highlightClass = (isEnabled) => isEnabled ? "highlighted" : "highlight";

  let flexDir = isVertical !== isCollapsed ? "column" : "row";
  
  const shift = (pos === "left" || pos === "top") && !isCollapsed;
  const suffix = isSC ? "" : " Content";

  const navigate = useNavigate();

  return (
    <div className={`pcc-container pcc-container-orient-${orientation}`}>
      <div className={`panel-control-container pcc-${direction} pcc-orient-${orientation}`} style={{flexDirection:flexDir}}>
        {!shift && <CollapseButton 
          panelRef={panelRef} 
          panelMinSize={panelMinSize} 
          panelDefaultSize={panelDefaultSize}
          currentPosition={currentPosition}
          suffix={suffix}
        />}

        <div className={shift ? "pcb-shifted" : ""}></div>
        {showDockHorizontal && 
          <div className={`panel-control-button ${highlightClass(pos === "left")}`} 
            onClick={() => setCurrentPosition("left")}>
            <BiSolidDockLeft title="Dock to left" />
          </div>
        }

        {showDockVertical &&
          <div className={`panel-control-button ${highlightClass(pos === "bottom")}`} 
            onClick={() => setCurrentPosition("bottom")}>
            <BiSolidDockBottom title="Dock to bottom" />
          </div>
        }

        {showDockHorizontal && 
          <div className={`panel-control-button ${highlightClass(pos === "right")}`} 
            onClick={() => setCurrentPosition("right")}>
            <BiSolidDockRight title="Dock to right" />
          </div>
        }

        {showDockVertical && 
          <div className={`panel-control-button ${highlightClass(pos === "top")}`} 
            onClick={() => setCurrentPosition("top")}>
            <BiSolidDockTop title="Dock to top" />
          </div>
        }

        {showFullscreen && 
          <div className={`panel-control-button ${highlightClass(false)}`} 
            onClick={() => {
              document.getElementById(isSC ? "sc-frame" : "content-panel").requestFullscreen();
            }}>
            <BiFullscreen title={`Fullscreen${suffix}`} />
          </div>
        }

        {isHome && isSC && 
          <div className={`panel-control-button ${highlightClass(false)}`} 
            onClick={() => navigate("/settings#general")}>
            <IoMdSettings title={`Settings`} />
          </div>
        }

        {shift && <CollapseButton 
          panelRef={panelRef} 
          panelMinSize={panelMinSize} 
          panelDefaultSize={panelDefaultSize}
          currentPosition={currentPosition} 
          suffix={suffix}
        />}
      </div>
    </div>
  );
}

const SCFrame = ({scPanelRef, dragging, rerenderControl, setRerenderControl, 
    panelMinSize, panelDefaultSize, id, 
    currentSpaceCockpitPosition, setSpaceCockpitPosition, 
    sats, pois, focus, time, initialQueryParams,
    showDockVertical, isHome, rightPanel
}) => {

  const {user} = useContext(AppState);
  const pos = getSCPos(currentSpaceCockpitPosition);
  const isVertical = isVerticalLayout(currentSpaceCockpitPosition);

  useEffect(() => {
    setRerenderControl((r) => r+1);
  }, [setRerenderControl, currentSpaceCockpitPosition?.position]);

  const showingSC = rightPanel === "Space Cockpit";

  return (
    // key set to link forces remount for different dashboard
    <div style={{
      height: '100%',
      width: '100%',
      padding: "0px",
      display: "flex",
      flexDirection: isVertical ? "row" : "column"
    }}>

      <ControlButtons panelRef={scPanelRef} 
        panelMinSize={panelMinSize} panelDefaultSize={panelDefaultSize}
        currentPosition={currentSpaceCockpitPosition} setCurrentPosition={setSpaceCockpitPosition}
        showDockVertical={showDockVertical}
        isSC={true}
        isHome={isHome}
      />

      <div style={{
        height: '100%',
        width: '100%',
        padding: "16px",
        paddingTop: pos === "bottom" ? "16px" : "0px",
        paddingLeft: isVertical ? "0px" : "16px",
      }}>
        {
          showingSC ? 
            <SpaceCockpitFrame 
              key={id} 
              visible={!dragging && !scPanelRef.current?.isCollapsed()} 
              rerenderControl={rerenderControl} 
              sats={sats} 
              pois={pois}
              focus={focus} 
              time={time} 
              initialQueryParams={initialQueryParams}
            /> 
          :
            <iframe 
              id="spacefana-frame"
              title="Spacefana"
              src={getDashboards(user?.access_token).find((d) => d.name === rightPanel)?.link}
              style={{
                width: '100%',
                height: '100%',
                border: 'none'
              }}
              allow='geolocation; clipboard-write'
            ></iframe>
        }
      </div>
    </div>
  );
}

const SCPanel = ({dragging, scSpacing, minSize, id, 
  currentSpaceCockpitPosition, setSpaceCockpitPosition, 
  sats, pois, focus, time, initialQueryParams,
  showDockVertical, isHome, rightPanel
}) => {

  const scPanelRef = useRef(null);
  const [rerenderControl, setRerenderControl] = useState(0);

  return (
    <Panel collapsible defaultSize={scSpacing} collapsedSize={minSize} minSize={minSize} data-testid="dash-sc-panel"
      ref={scPanelRef}
      onResize={() => setRerenderControl(rerenderControl+1)}>
      <SCFrame {...{scPanelRef, dragging, rerenderControl, setRerenderControl}} 
        panelMinSize={minSize}
        panelDefaultSize={scSpacing}
        id={id}
        currentSpaceCockpitPosition={currentSpaceCockpitPosition}
        setSpaceCockpitPosition={setSpaceCockpitPosition}
        sats={sats}
        pois={pois}
        focus={focus}
        time={time}
        initialQueryParams={initialQueryParams}
        showDockVertical={showDockVertical}
        isHome={isHome}
        rightPanel={rightPanel}
      />
    </Panel>
  );
};

const getContentPanelStyle = (sidePadding, topPadding, bottomPadding, pos) => ({
  flex: "1",
  minHeight: "0px",
  paddingTop: topPadding ? "16px" : undefined,
  paddingLeft: sidePadding ? "16px" : undefined,
  paddingRight: (sidePadding || pos === "right") ? "16px" : undefined,
  paddingBottom: (bottomPadding || pos === "bottom") ? "16px" : undefined
});

const invertPos = (pos) => {
  switch(pos) {
    case "left":
      return "right";
    case "right":
      return "left";
    case "top":
      return "bottom";
    case "bottom":
      return "top";
    default: 
      return null;
  }  
}

const SpaceCockpitDockContainer = ({
  id, currentSpaceCockpitPosition, setSpaceCockpitPosition, 
  sats, pois, focus, time, initialQueryParams,
  sidePadding=true,
  topPadding=true,
  bottomPadding=true,
  fullWidth=true,
  fullHeight=true, // set this to false if you are using dynamic height in the parent (display: flex and align-items: stretch)
  showDockVertical=true,
  showFullscreenContent=true,
  isHome=false,
  rightPanel="Space Cockpit",
  children
}) => {
  useEffect(() => {
    // Clean up any leftover canvas as a safety measure
    const canvas = document.querySelector('canvas');
    if (canvas) {
      canvas.remove();
    }
  }, []);

  const [dragging, setDragging] = useState(false);
  
  const pos = getSCPos(currentSpaceCockpitPosition);
  const isVertical = isVerticalLayout(currentSpaceCockpitPosition);

  const scSpacing = currentSpaceCockpitPosition?.size * 100;
  const spacefanaSpacing = 100 - scSpacing;

  // minimum fraction needed to still show collapse/expand button
  const minSize = (30+16*2+4) / (isVertical ? (window.innerHeight-56-12) : window.innerWidth) * 100;

  const contentPanelRef = useRef(null);
  const contentPanelPosition = useMemo(() => {
    return {position: invertPos(getSCPos(currentSpaceCockpitPosition))};
  }, [currentSpaceCockpitPosition]);
  

  const [rerenderControl, setRerenderControl] = useState(0);

  const contentPanel = (
    <Panel collapsible defaultSize={spacefanaSpacing} collapsedSize={minSize} minSize={minSize} data-testid="dash-spacefana-panel" 
      ref={contentPanelRef}
      onResize={() => setRerenderControl(rerenderControl+1)}
    >
      <div style={{
        height: '100%',
        width: '100%',
        padding: "0px",
        display: "flex",
        flexDirection: isVertical ? "row" : "column"
      }}>
        <ControlButtons panelRef={contentPanelRef} 
          panelMinSize={minSize} panelDefaultSize={spacefanaSpacing}
          currentPosition={contentPanelPosition} setCurrentPosition={() => {}}
          showDockVertical={false} showDockHorizontal={false}
          rerenderControl={rerenderControl}
          isSC={false}
          showFullscreen={showFullscreenContent}
          isHome={isHome}
        />
        <div id="content-panel" style={{
            ...getContentPanelStyle(sidePadding, topPadding, bottomPadding, pos), 
            visibility: contentPanelRef?.current?.isCollapsed() ? "hidden" : "visible"}
        }>
          {children}
        </div>
      </div>
    </Panel>
  );

  const scPanelProps = {
    dragging, scSpacing, minSize, id, currentSpaceCockpitPosition, setSpaceCockpitPosition, 
    sats, pois, focus, time, initialQueryParams,
    showDockVertical, isHome, rightPanel
  };

  const scFirst = pos === "left" || pos === "top";
  const direction = isVertical ? "vertical" : "horizontal";

  return (  
    <>
      <div style={{
        width: fullWidth ? "100%" : undefined,
        height: fullHeight ? "100%" : undefined
      }}>
        {currentSpaceCockpitPosition?.position ? (
            <PanelGroup key={id} autoSaveId={`resize-dashboard-${id}-${pos}`} 
              direction={direction}
              data-testid="dash-panel-group"
              >
              {scFirst ? <SCPanel {...scPanelProps} /> : contentPanel}
              
              <PanelResizeHandle className={`resize-handle resize-handle-${direction}`} 
                  onDragging={(isDragging) => setDragging(isDragging)} />
              
              {scFirst ? contentPanel : <SCPanel {...scPanelProps} />}
            </PanelGroup>
          ) : children}
      </div>
    </>
  );
};

export default SpaceCockpitDockContainer;
