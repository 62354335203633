import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdOutlineSatelliteAlt } from "react-icons/md";
import { useContext } from "react";
import SatConfigureModal from "./SatConfigureModal";
import { SatConfigureState } from "../../lib/context/SatConfigureProvider";

const SatConfigureButton = () => {
    const {setShowConfigureModal, currentSatConfig} = useContext(SatConfigureState);
    
    const hasSelectedSatellites = currentSatConfig?.satellites?.length > 0;
    
    return (<>
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip>
                    Select or override TLEs
                </Tooltip>
            }
        >
            <Button
                data-testid="sat-configure-button"
                variant={hasSelectedSatellites ? "warning" : "dark"}
                onClick={() => setShowConfigureModal(true)}
                className="center-contents header-button"
                style={{
                    marginRight: "16px"
                }}
            >
                <MdOutlineSatelliteAlt />
            </Button>
        </OverlayTrigger>
        <SatConfigureModal />
    </>);
};

export default SatConfigureButton;