import { useCallback, useMemo, useState } from 'react';
import { SpaceCockpitPositions } from './DashboardProvider';
import { ProfileAlpabeticalSort } from '../../components/settings/SettingsGroup';

export const validSCPositions = new Set(["left", "right", "top", "bottom"]);
export const useSCPositionSettings = () => {
    const [scPositions, setSCPositions] = useState(() => {
        let stored = localStorage.getItem("SCPositions");
        if(stored) {
            try {
                stored = JSON.parse(stored);
            } catch (e) {
                stored = null;
            }
        }
        const initial = {};
        Object.keys(SpaceCockpitPositions).forEach((key) => {
            initial[key] = {...SpaceCockpitPositions[key]};
            if(validSCPositions.has(stored?.[key]?.position)) {
                initial[key].position = stored[key].position;
            }
        });
        return initial;
    });
    
    const getSCPosition = useCallback((dashboardName) => {
        return {...scPositions[dashboardName]} ?? null;
    }, [scPositions]);

    const setSCPosition = useCallback((dashboardName, position) => {
        if(dashboardName in scPositions && validSCPositions.has(position)) {
            scPositions[dashboardName].position = position;
            setSCPositions({...scPositions});

            localStorage.setItem("SCPositions", JSON.stringify(scPositions));
        }
    }, [scPositions, setSCPositions]);

    return {
        getSCPosition,
        setSCPosition
    };
};

const loadProfiles = () => {
    const profiles = localStorage.getItem("DefinedProfiles");
    if(profiles) {
        try {
            return JSON.parse(profiles);
        } catch (e) {
        }
    }
    return [];
};

const sanitizeSatno = (newVal) => {
    return newVal ? newVal.replaceAll(/[^0-9,]+/g, "").replaceAll(/,,+/g, ",") : "";
}

export const useLocalSettings = () => {

    const [profiles, setProfilesRaw] = useState(() => loadProfiles());
    const [selectedProfileRaw, setSelectedProfileRaw] = useState(localStorage.getItem("SelectedProfile") ?? null);

    const selectedProfile = useMemo(() => {
        const profile = profiles[selectedProfileRaw] ?? null;
        return profile ? {...profile, name: selectedProfileRaw} : null;
    }, [selectedProfileRaw, profiles]);

    const setSelectedProfile = (name) => {
        localStorage.setItem("SelectedProfile", name);
        setSelectedProfileRaw(name);
    };

    const setProfiles = (newProfiles) => {
        if(newProfiles) {
            const sanitized = Object.fromEntries(
                Object.entries(newProfiles)
                    .sort(ProfileAlpabeticalSort)
                    .map(([name, group]) => [name, {
                        ...group,
                        satellites: group?.satellites?.map(sanitizeSatno)
                    }]));
            localStorage.setItem("DefinedProfiles", JSON.stringify(sanitized));
            setProfilesRaw(sanitized);

            if(Object.keys(newProfiles).length > 0 && !selectedProfile) {
                setSelectedProfile(Object.keys(newProfiles)[0]);
            }
        } 
    }

    return {
        profiles, setProfiles, 
        selectedProfile, setSelectedProfile
    };

};