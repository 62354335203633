import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { getDomainUrl } from "../util/Utils";
import { AppState } from "../lib/context/AppProvider";
import { getSpaceCockpitUrl } from "../App";

const hasSceneChanged = (lastScene, newScene) => {
    return JSON.stringify(lastScene) !== JSON.stringify(newScene);
};

const buildScenePois = (message, pois) => {
    if(pois?.length > 0) {
        pois.forEach((poi) => {
            const ref = message.References.find((r) => r.SatNo === poi.satno);
            if(ref) {
                if(!ref.Pois) ref.Pois = [];
                ref.Pois.push(poi);
            }
        });
    }
};

const buildSceneMessage = ({sats, focus, time, pois}) => {
    console.log("POIS", pois);
    const initialMessage = {
        SimTime: time ?? new Date().toISOString(),
        SimSpeed: 1,
        Action: "clear",
    };

    if (sats?.length > 0) {
        initialMessage.References = sats.map((satno) => ({"SatNo": satno}));
        const ind = sats.findIndex((satno) => satno === focus);
        initialMessage.FocusedReferenceIndex = ind >= 0 ? ind : 0;
    }

    buildScenePois(initialMessage, pois);

    return initialMessage;
};

const SpaceCockpitFrame = ({
    containerStyle, sats, pois, focus, time, initialQueryParams, 
    visible=true, rerenderControl
}) => {
    // NOTE: if you pass initialQueryParams you may want to leave sats, focus, time as null 
    // otherwise they will still be passed as message when SC

    const [scene, setScene] = useState({sats, pois, focus, time});
    const lastScene = useRef(null);
    
    const {setSCQueryParams, userDetails, user, scQueryParams} = useContext(AppState);
    const accessToken = user?.access_token;

    const preload = useMemo(() => userDetails?.Settings?.preloadSC !== "No", [userDetails]);
    const persistent = useMemo(() => userDetails?.Settings?.persistentSC !== "No", [userDetails]);
    
    const loadUnloadFrame = useCallback((load) => {
        const frame = document.getElementById("sc-frame");
        if(frame) {
            const loaded = new URL(frame.src).origin === new URL(getDomainUrl('spacecockpit')).origin;
            if(load && !loaded) {
                frame.src = getSpaceCockpitUrl(accessToken, scQueryParams);
            }
            else if(!load) {
                frame.src = "";
            }
        }
    }, [accessToken, scQueryParams]);

    useEffect(() => {
        if(!preload || !persistent) {
            loadUnloadFrame(true);
        }

        return () => {
            if(!persistent) {
                loadUnloadFrame(false);
            }
        }
    }, [preload, persistent, loadUnloadFrame]);

    const positionFrame = useCallback(() => {
        const thisContainer = document.getElementById("sc-frame-container");
        const dimensions = thisContainer.getBoundingClientRect()

        const frameAnchor = document.getElementById("sc-frame-anchor");
        if(frameAnchor) {
            frameAnchor.style.width = dimensions.width+"px";
            frameAnchor.style.height = dimensions.height+"px";
            frameAnchor.style.marginLeft = dimensions.x+"px";
            frameAnchor.style.marginTop = dimensions.y+"px";
            frameAnchor.style.visibility = visible ? "visible" : "hidden";
        }
    }, [visible]);

    const postScene = (scene) => {
        const frame = document.getElementById("sc-frame");
        const {sats, focus, time} = scene;
        if(frame && (sats || focus || time)) {
            const initialMessage = buildSceneMessage(scene);
            console.log("POSTING TO SC", initialMessage);
            frame.contentWindow.postMessage(initialMessage, getDomainUrl('spacecockpit'));
        }
    };

    useEffect(() => {
        if(hasSceneChanged(lastScene.current, scene)) {
            postScene(scene);
            lastScene.current = scene;
        }

        const handleMessage = (e) => {
            let msg = e.data;
            if(typeof msg === "string") {
                try {
                    msg = JSON.parse(msg);
                } catch (e) {
                    // pass
                }
            }

            if(msg.ready) {
                postScene(scene);
            }
            else if(msg.type === "sc-set-scene" && msg.scene) {
                setScene(msg.scene);
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            // remove this listener before next call where we add a new listener
            window.removeEventListener('message', handleMessage); 
        };
    }, [scene]);

    useEffect(() => {
        setScene({sats, focus, time, pois});
    }, [sats, focus, time, pois]);

    useEffect(() => {
        positionFrame();
    }, [visible, rerenderControl, positionFrame]);

    useEffect(() => {
        // Anything in here is fired on component mount.
        setSCQueryParams(initialQueryParams);
        
        positionFrame();
        
        const handleResize = () => {
            positionFrame();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            // Anything in here is fired on component unmount.
            const frameAnchor = document.getElementById("sc-frame-anchor");
            if(frameAnchor) {
                frameAnchor.style.visibility = "hidden";
            }
            window.removeEventListener('resize', handleResize);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const iframeContainerStyle = containerStyle ?? {
        height: '100%',
        width: '100%'
    };

    return (
        <div style={iframeContainerStyle} id = "sc-frame-container">
        </div>
    );
};

export default SpaceCockpitFrame;