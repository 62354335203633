import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppState } from "../lib/context/AppProvider";
import { Carousel, Button, Modal, ButtonGroup, DropdownButton, Dropdown } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/Login.css";
import Header from "../components/Header";
import "../styles/page.css";
import { getDomainUrl } from "../util/Utils";
import SpaceCockpitDockContainer from "../components/SpaceCockpitDockContainer";
import SatConfigureProvider, { SatConfigureDefaults } from "../lib/context/SatConfigureProvider";
import SatConfigureModal from "../components/configure/SatConfigureModal";
import { getDayOfYear } from "../utils/time";

// Custom input component for the date picker
const CustomDateInput = React.forwardRef(({ value, onClick, onChange }, ref) => {
    const date = value ? new Date(value) : new Date();
    const dayOfYear = getDayOfYear(date);
    const displayValue = `(${dayOfYear}) ${value}`;
    
    return (
        <input
            ref={ref}
            value={displayValue}
            onClick={onClick}
            onChange={onChange}
            style={{...datePickerCustomStyles.input, width: '250px'}}
            className="form-control"
        />
    );
});

export const getType = (eventClass) => {
    if (!eventClass) return "unknown";
    
    const eventClassLower = eventClass.toLowerCase();
    
    if (eventClassLower.includes("rpo")) return "rpo";
    if (eventClassLower.includes("maneuver") || eventClassLower.includes("manoeuvre")) return "maneuver";
    if (eventClassLower.includes("launch")) return "launch";
    if (eventClassLower.includes("photometry") || eventClassLower.includes("photometric")) return "photometric";
    if (eventClassLower.includes("breakup")) return "breakup";
    
    return "unknown";
};

const fetchNotsoById = async (id, postData) => {
    const payload = {
        table: "Notsos",
        columns: ["*"],
        wheres: [{
            column: "IdUdl",
            operator: "=",
            value: id
        }]
    };

    const response = await postData("read", payload);
    if (response?.status === 200) {
        const reader = response.body.getReader();
        const textDecoder = new TextDecoder();
        let result = '';
        
        while (true) {
            const {done, value} = await reader.read();
            if (done) break;
            result += textDecoder.decode(value);
        }
        
        const data = JSON.parse(result);

        if(data?.length === 0) {
            return {
                error: "No NOTSO found matching the provided ID."
            };
        }

        const details = {
            eventClass: data[0].EventClass,
            type: getType(data[0].EventClass), 
            data
        };
        
        if (data[0]?.SatNos && data[0].SatNos.length > 0) {
            details.satellites = data[0].SatNos;
            const newPath = `/event/${details.type}/${data[0].SatNos.join(',')}/${encodeURIComponent(data[0].EventClass)}`;
            window.history.replaceState({}, "", newPath);
        }

        return details;
    }
}

const fetchNotsoBySats = async (sats, eventClass, postData) => {
    const wheres = [
        {
            column: "SatNos",
            operator: "JSON_CONTAINS",
            value: JSON.stringify(sats)
        }
    ];

    if(eventClass) {
        wheres.push({
            column: "EventClass",
            operator: "=",
            value: eventClass
        });
    }

    const payload = {
        table: "Notsos",
        columns: ["*"],
        wheres: wheres,
        orderBy: [{
            column: "CreatedAt",
            direction: "DESC"
        }]
    };

    const response = await postData("read", payload);
    if (response?.status === 200) {
        const reader = response.body.getReader();
        const textDecoder = new TextDecoder();
        let result = '';
        
        while (true) {
            const {done, value} = await reader.read();
            if (done) break;
            result += textDecoder.decode(value);
        }

        const data = JSON.parse(result);
        return {data};
    }
};

const fetchAllNotsoDetails = async (id, details, postData, setNotsoDetails) => {
    // fetch all notsos with sat
    if (details.type && details.satellites?.length > 0) {
        const res = await fetchNotsoBySats(details.satellites, details.eventClass, postData);
        if(res.data.length === 0) {
            setNotsoDetails({
                error: "No NOTSOs found matching the provided satellites."
            });
        }
        else {
            setNotsoDetails({
                type: details.type, 
                satellites: details.satellites,
                ...res,
                id: id
            });
        }
    }
}

const fetchNotsoDetails = async (eventDetails, postData, setNotsoDetails) => {
    try {
        let details = eventDetails; 
        if (eventDetails.notsoid && !eventDetails.type) {
            details = await fetchNotsoById(eventDetails.notsoid, postData);

            if(details.type && !details.satellites) {
                // NOTSO didn't come with satellite array... check event class
                const satno = details.eventClass.split(" / ")[1]?.trim();
                if(satno && /^\d{5}$/.test(satno)) { // 5 digit satno
                    // skip search for notsos of satellite since it didn't come with a satno
                    setNotsoDetails({
                        type: details.type, 
                        satellites: [satno],
                        data: details.data,
                        id: eventDetails.notsoid
                    });
                    return;
                }
            }
            else if(details.error) {
                setNotsoDetails(details);
            }
        } 
        
        await fetchAllNotsoDetails(eventDetails.notsoid, details, postData, setNotsoDetails);
    } catch (error) {
        console.error("Error fetching NOTSO details:", error);
    }
};

const getCardColor = (type) => {
    if (!type) return 'secondary';
    
    const typeLower = type.toLowerCase();
    if (typeLower.includes('rpo')) return 'danger';
    if (typeLower.includes('maneuver') || typeLower.includes('manoeuvre')) return 'primary';
    if (typeLower.includes('photometry')) return 'success';
    return 'secondary';
};

const iframeContainerStyle = {
    height: '100%',
    width: '100%',
    overflow: 'hidden'
};

const iframeStyle = {
    width: '100%',
    height: '100%',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: '#1a1d20',
    display: 'block'
};

const iframeStyleZoomed = (zoom) => ({
    ...iframeStyle,
    width: `${100/zoom}%`,
    height: `${100/zoom}%`,
    transform: `scale(${zoom})`,
    transformOrigin: '0 0',
    display: 'block'
});

const containerStyle = {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column"
};

const dashContainerStyle = {
    gap: '1rem',
    width: '100%',
    marginTop: '0rem',
    flex: "1",
    display: "flex",
    alignItems: "stretch"
};

const TabsComponent = ({tabs, notso, hasNotsoDetails}) => {
    const [activeTab, setActiveTab] = useState(() => {
        // Always select the first tab by default
        return tabs[0].id;
    });

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    const tabStyle = {
        padding: '0.5rem 1rem',
        backgroundColor: '#1a1d20',
        border: 'none',
        color: 'white',
        cursor: 'pointer',
        borderRadius: '4px 4px 0 0',
        marginRight: '0.5rem',
        opacity: 0.7,
        transition: 'opacity 0.2s'
    };

    const activeTabStyle = {
        ...tabStyle,
        opacity: 1,
        backgroundColor: '#2a2d30'
    };

    const getDomainUrlForTab = (activeTab, spacefanaUrl) => {
        if (activeTab === 'spacecockpit') {
            return getDomainUrl('spacecockpit');
        }
        return getDomainUrl('spacefana');
    };

    const renderTabIframeContent = (tabs) => {
        if (activeTab === 'images' && notso?.ImageMeta?.length > 0) {
            return (
                <div style={{ height: '100%' }}>
                    <Carousel interval={null} className="h-100">
                        {notso.ImageMeta.map((image, index) => (
                            <Carousel.Item key={image.id} style={{ height: '65vh' }}>
                                <img
                                    className="d-block w-100 h-100"
                                    src={image.url}
                                    alt={image.caption || `Image ${index + 1}`}
                                    style={{ objectFit: 'contain' }}
                                />
                                <div className="position-absolute bottom-0 start-0 w-100 p-3">
                                    <div className="bg-dark bg-opacity-75 p-2 rounded text-center">
                                        {image.caption && (
                                            <p className="mb-1">{image.caption}</p>
                                        )}
                                        <p className="mb-0 text-muted small">
                                            {image.url.split('/').pop()}
                                        </p>
                                    </div>
                                </div>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
            );
        }

        // In test environment, render all iframes at once
        if (process.env.NODE_ENV === 'test') {
            return (
                <div style={{ position: 'relative' }}>
                    {tabs.map(tab => (
                        <div key={tab.id} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', visibility: activeTab === tab.id ? 'visible' : 'hidden' }}>
                            {tab.element || (
                                <iframe
                                    src={`${getDomainUrlForTab(tab.id)}${tab.url}`}
                                    style={tab.zoom ? iframeStyleZoomed(tab.zoom) : iframeStyle}
                                    title={tab.label}
                                    aria-label={`${tab.label} Dashboard`}
                                />
                            )}
                        </div>
                    ))}
                </div>
            );
        }

        // In production, only render the active tab's iframe
        const currentTab = tabs.find(tab => tab.id === activeTab);
        if (!currentTab) return null;
        
        return currentTab.element || (
            <iframe
                src={`${getDomainUrlForTab(activeTab)}${currentTab.url}`}
                style={currentTab.zoom ? iframeStyleZoomed(currentTab.zoom) : iframeStyle}
                title={currentTab.label}
                aria-label={`${currentTab.label} Dashboard`}
            />
        );
    };

    const tabWrapperStyle = {
        flex: 1,
        backgroundColor: '#2a2d30',
        borderRadius: '0 4px 4px 4px',
        padding: '0.5rem',
        overflow: 'hidden'
    };

    return (
        <div style={containerStyle}>
            <div className="d-flex justify-content-between align-items-center ">
                <div className="d-flex">
                    {tabs.map(tab => (
                        <button 
                            key={tab.id}
                            style={activeTab === tab.id ? activeTabStyle : tabStyle}
                            onClick={() => handleTabClick(tab.id)}
                            aria-label={`Switch to ${tab.label}`}
                            aria-selected={activeTab === tab.id}
                            role="tab"
                        >
                            {tab.label}
                        </button>
                    ))}
                </div>
            </div>
            <div style={tabWrapperStyle}>
                {renderTabIframeContent(tabs)}
            </div>
        </div>
    );
}

const ResidualsFrame = ({firstSatNo, timerange, userDetails, zoom}) => {
    const {getSatName} = useContext(AppState);
    return (<>
        <SatConfigureProvider satConfigDefault={{
            ...SatConfigureDefaults["Residuals"],
            satellites: [
                {id: firstSatNo, name: getSatName(firstSatNo)}
            ]
        }}>
            <SatConfigureModal />
        </SatConfigureProvider>
        <div style={iframeContainerStyle}>
            <iframe
                id="spacefana-frame"
                src={`${getDomainUrl('spacefana')}/d/FOBAMEzVz/residuals?var-satno=${firstSatNo}&var-accesstoken=${userDetails?.AccessToken}&from=${timerange.from}&to=${timerange.to}&kiosk`}
                style={iframeStyleZoomed(zoom)}
                title="Residuals Plot"
                aria-label="Residuals Plot"
            />
        </div>
        </>
    );
};

const EventDashboards = ({ type, satellites, notso, selectedDate }) => {
    const { getData } = useContext(AppState);
    const [userDetails, setUserDetails] = useState(null);
    
    const [currentSpaceCockpitPosition, setSpaceCockpitPositionRaw] = useState({
        position: "right", 
        size: 0.5
    });

    const setSpaceCockpitPosition = (pos) => setSpaceCockpitPositionRaw((c) => ({
        position: pos, 
        size: c.size
    }));

    const hasNotsoDetails = Boolean(notso);
    
    useEffect(() => {
        const fetchUserDetails = async () => {
            const details = await getData("user-details");
            setUserDetails(details);
        };
        fetchUserDetails();
    }, [getData]);

    const firstSatNo = satellites[0];
    const secondSatNo = satellites[1];
    const satnosString = satellites.join(',');
    const regime = notso?.Regime?.[0] || 'GEO';
    const isLEO = regime === 'LEO';
    
    const getSpacefanaTimestamps = (daysPast=1, daysFuture=1) => {
        const selectedTime = selectedDate.getTime();
        const oneDayMs = 24 * 60 * 60 * 1000;
        return {
            from: selectedTime - (daysPast * oneDayMs),
            to: selectedTime + (daysFuture * oneDayMs)
        };
    };

    const getSpaceCockpitTimestamp = () => {
        return selectedDate.toISOString().slice(0, 19) + 'Z';
    };

    const zoom = 0.6;

    const getTricFrame = () => {
        const timerange = getSpacefanaTimestamps(2, 2);
        return (
            <div style={iframeContainerStyle}>
                <iframe
                    src={`${getDomainUrl('spacefana')}/d/ZXiz9jkVk/tric?var-satno=${firstSatNo}&var-satno2=${secondSatNo}&var-accesstoken=${userDetails?.AccessToken}&from=${timerange.from}&to=${timerange.to}&kiosk`}
                    style={iframeStyleZoomed(zoom)}
                    title="TRIC Dashboard"
                    aria-label="TRIC Dashboard"
                />
            </div>
        )
    };

    const getElsetHistoryFrame = () => {
        const timerange = getSpacefanaTimestamps(2, 2);
        return (
            <div style={iframeContainerStyle}>
                <iframe
                    src={`${getDomainUrl('spacefana')}/d/PO81TfmVk/elsethistory?var-satnos=${satnosString}&var-stdDev=0&var-showSourceColor=No&var-accesstoken=${userDetails?.AccessToken}&from=${timerange.from}&to=${timerange.to}&kiosk`}
                    style={iframeStyleZoomed(zoom)}
                    title="ELSET History Dashboard"
                    aria-label="ELSET History Dashboard"
                />
            </div>
        )
    };

    const getLeoWaterfallFrame = () => {
        const timerange = getSpacefanaTimestamps(2, 2);
        return (
            <div style={iframeContainerStyle}>
                <iframe
                    src={`${getDomainUrl('spacefana')}/d/aea48nghbugw0b/leo-waterfall?var-satnos=${satnosString}&var-accesstoken=${userDetails?.AccessToken}&from=${timerange.from}&to=${timerange.to}&kiosk`}
                    style={iframeStyleZoomed(zoom)}
                    title="LEO Waterfall Dashboard"
                    aria-label="LEO Waterfall Dashboard"
                />
            </div>
        )
    };

    const getPhotometryFrame = () => {
        const timerange = getSpacefanaTimestamps(2, 2);
        return (
            <div style={iframeContainerStyle}>
                <iframe
                    src={`${getDomainUrl('spacefana')}/d/RCHUUtW4z/photometry?var-satno=${firstSatNo}&var-accesstoken=${userDetails?.AccessToken}&from=${timerange.from}&to=${timerange.to}&kiosk`}
                    style={iframeStyleZoomed(zoom)}
                    title="Photometry Dashboard"
                    aria-label="Photometry Dashboard"
                />
            </div>
        );
    };

    const getResidualsFrame = () => {
        const timerange = getSpacefanaTimestamps(2, 2);
        return <ResidualsFrame 
            firstSatNo={firstSatNo} 
            timerange={timerange} 
            userDetails={userDetails} 
            zoom={zoom} />
    }

    const getWaterfallFrame = () => {
        const timerange = getSpacefanaTimestamps(2, 2);
        return (
            <div style={iframeContainerStyle}>
                <iframe
                    src={`${getDomainUrl('spacefana')}/d/KPR_hDkVz/geo-waterfall?var-satnos=${satnosString}&var-accesstoken=${userDetails?.AccessToken}&from=${timerange.from}&to=${timerange.to}&kiosk`}
                    style={iframeStyleZoomed(zoom)}
                    title="GEO Waterfall"
                    aria-label="GEO Waterfall"
                />
            </div>
        );
    };

    const dashboardConfig = {
        rpo: {
            tabs: [
                ...(!isLEO ? [
                    { id: 'tric', label: 'TRIC', element: getTricFrame() },
                    { id: 'elset', label: 'ELSET History', element: getElsetHistoryFrame() }
                ] : [])
            ]
        },
        photometric: {
            tabs: [
                { id: 'photometry', label: 'Photometry', element: getPhotometryFrame() }
            ]
        },
        maneuver: isLEO ? 
            {
                tabs: [
                    { id: 'residuals', label: 'Residuals Plot', element: getResidualsFrame() },
                    { id: 'waterfall', label: 'LEO Waterfall', element: getLeoWaterfallFrame() }
                ]
            }
            : {
                tabs: [
                    { id: 'residuals', label: 'Residuals Plot', element: getResidualsFrame() },
                    { id: 'waterfall', label: 'GEO Waterfall', element: getWaterfallFrame() },
                    { id: 'elset', label: 'ELSET History', element: getElsetHistoryFrame() }
                ]
            }
    };

    const renderDashboardContent = () => {
        const config = dashboardConfig[type];
        if (!config?.tabs) return null;
        return (
            <SpaceCockpitDockContainer 
                id="events"
                currentSpaceCockpitPosition={currentSpaceCockpitPosition}
                setSpaceCockpitPosition={setSpaceCockpitPosition}
                sats={satellites} 
                focus={firstSatNo}
                time={getSpaceCockpitTimestamp()} 
                fullHeight={false} // use flexbox stretch
            >
                <TabsComponent 
                    tabs={config.tabs} 
                    notso={notso} 
                    hasNotsoDetails={hasNotsoDetails} 
                />
            </SpaceCockpitDockContainer>
        );
    };

    return (
        <div style={dashContainerStyle}>
            {renderDashboardContent()}
        </div>
    );
};

const NotsoModal = ({ show, onHide, notso }) => {
    const handleClose = () => {
        onHide();
    };

    if (!notso?.ImageMeta?.length) return null;

    const modalStyle = {
        width: "100%"
    };

    const modalContentStyle = {
        height: '80vh',
        maxHeight: '90vh'
    };

    return (
        <Modal 
            show={show} 
            onHide={handleClose} 
            size="xl" 
            dialogClassName="modal-90w"
            style={modalStyle}
        >
            <Modal.Header closeButton className="bg-dark text-white" 
                style={{border:"none"}}>
                <Modal.Title>Analyst Images</Modal.Title>
            </Modal.Header>
            <Modal.Body 
                className={`bg-dark text-white`}
                style={{ ...modalContentStyle }}
            >
                <div style={{ height: '100%' }}>
                    <Carousel interval={null} className="h-100">
                        {notso.ImageMeta.map((image, index) => (
                            <Carousel.Item key={image.id} style={{ height: '65vh' }}>
                                <img
                                    className="d-block w-100 h-100"
                                    src={image.url}
                                    alt={image.caption || `Image ${index + 1}`}
                                    style={{ objectFit: 'contain' }}
                                    data-testid={`analyst-image-${index}`}
                                />
                                <div className="position-absolute bottom-0 start-0 w-100 p-3">
                                    <div className="bg-dark bg-opacity-75 p-2 rounded text-center">
                                        {image.caption && (
                                            <p className="mb-1">{image.caption}</p>
                                        )}
                                        <p className="mb-0 small">
                                            {image.url.split('/').pop()}
                                        </p>
                                    </div>
                                </div>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
            </Modal.Body>
            <Modal.Footer className="bg-dark text-white" 
                style={{border:"none"}}>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const unloadedEventComponent = (eventDetails, notsoDetails) => {
    return (
        <>
            <Header
                showSideBarMenu={true}
                showDashboardMenu={false}
                height={56} 
                className="pb-5"
            />
            <div id="full-page-background" className="p-4 min-h-screen">
                <h2 className="text-xl font-bold mb-4">NOTSO Event Details</h2>
                {notsoDetails ? (
                    <div className="space-y-2">
                        {
                            eventDetails.notsoid ? 
                                (<p>NOTSOID: {eventDetails.notsoid}</p>)
                                : (<p>Satellites: {eventDetails.satellites.join(", ")}</p>)
                        }
                        <pre className="bg-gray-800 p-4 rounded-lg overflow-auto">
                            {notsoDetails.error ? notsoDetails.error : JSON.stringify(notsoDetails, null, 2)}
                        </pre>
                    </div>
                ) : (
                    <p>
                        Loading NOTSO details...
                    </p>
                )}
            </div>
        </>
    );
};

const datePickerCustomStyles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: '1rem'
    },
    input: {
        backgroundColor: '#2a2d30',
        border: '1px solid #495057',
        color: 'white',
        padding: '0.5rem',
        borderRadius: '4px',
        cursor: 'pointer',
        width: '200px'
    }
};

const notsoDescriptionComponent = (notso, notsoDetails, selectedDate, handleDateChange, 
    handleModalShow, navigate, setNotso
) => {
    return (
        <>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <Button 
                        variant="warning"
                        onClick={() => navigate("/home")}
                        className="d-flex align-items-center gap-2"
                >
                    <span style={{ fontSize: '1.2rem', lineHeight: 1 }}>←</span>
                    <span>Home</span>
                </Button>
                {/* <h2 className="font-bold mb-0 text-center">
                    {notso.EventClass}
                </h2> */}
                <DropdownButton
                    as={ButtonGroup}
                    title={notso.EventClass}
                    id="bg-nested-dropdown"
                    variant="dark"
                    menuVariant="dark"
                    size="lg"
                >
                    {notsoDetails.data?.map((n) => (
                        <Dropdown.Item
                            variant="dark"
                            eventKey={n.Id}
                            onClick={(e) => setNotso(n)}
                            key={`dropdown-notso-${n.Id}`}
                            active={notso.Id === n.Id}
                        >
                            {n.Company} - {n.EventClass} ({n.CreatedAt})
                        </Dropdown.Item>
                    ))}
                </DropdownButton>
                <div className="d-flex align-items-center">
                    <div style={datePickerCustomStyles.container}>
                        <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="yyyy-MM-dd HH:mm:ss"
                            className="form-control"
                            wrapperClassName="date-picker"
                            customInput={<CustomDateInput />}
                        />
                    </div>
                    {(notsoDetails.type === 'rpo' || notsoDetails.type === 'photometric') && 
                        notso?.ImageMeta?.length > 0 && (
                            <Button 
                                variant="warning"
                                onClick={handleModalShow}
                                className="ms-2"
                            >
                                Analyst Images
                            </Button>
                        )}
                </div>
            </div>
            <div 
                className={`space-y-4 mb-4 border-${getCardColor(notso.EventClass)}`}
                style={{ 
                    whiteSpace: 'pre-wrap',
                    maxHeight: '290px', 
                    padding: '1rem', 
                    backgroundColor: '#1a1d20', 
                    borderRadius: '8px',
                    borderLeft: '4px solid'
                }}
            >
                <div style={{
                    maxHeight: "calc(200px - 2rem)",
                    overflowY: "scroll"
                }}>
                    <span className="text-sm">{notso.Company}</span> - <span className="text-sm">{notso.Description}</span>
                </div>
            </div>
        </>
    )
};

const Event = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { postData } = useContext(AppState);
    const [notsoDetails, setNotsoDetails] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    
    const [notso, setNotso] = useState(null);

    const selectedDateUtc = useMemo(() => {
        const utc = new Date(selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60 * 1000);
        return utc;
    }, [selectedDate]);

    const handleDateChange = useCallback((date) => {
        setSelectedDate(date);
    }, [setSelectedDate]);

    const setSelectedUtcDate = useCallback((utcDate) => {
        // date picker doesn't support timezones so we have to gimmick it,
        //  in order to display it in UTC 
        // if timezone offset is 420 (7 hours),
        //  then the date picker will show UTC-7 as the date
        //  so we add 7 hours to the UTC date 
        // we need to reverse and subtract it when accessing the date
        const utc = new Date(utcDate);
        handleDateChange(new Date(utc.getTime() + utc.getTimezoneOffset() * 60 * 1000));
    }, [handleDateChange]);

    useEffect(() => {
        if(!notsoDetails?.data) return;
        const firstNotso = notsoDetails.id ? notsoDetails.data.find((n) => notsoDetails.id === n.IdUdl) : notsoDetails.data[0];
        if(!notso && firstNotso) {
            setNotso(firstNotso);
        }
    }, [notsoDetails, notso]);

    useEffect(() => {
        if(notso){
            const d = notso.CreatedAt;
            const dateStr = d + (d.endsWith("Z") ? "" : "Z");
            const date = new Date(dateStr);
            setSelectedUtcDate(date);
        }
    }, [notso, setSelectedUtcDate]);

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleModalShow = () => {
        setShowModal(true);
    };

    const getEventDetails = () => {
        const pathParts = location.pathname.split('/').filter(Boolean);
        
        if (pathParts.length > 2) {
            const eventType = pathParts[1];
            const satellites = pathParts[2].split(",");
            const eventClass = pathParts[3];
            return { 
                type: eventType, satellites, 
                eventClass: eventClass ? decodeURIComponent(eventClass) : null 
            };
        }
        
        return { notsoid: pathParts[1] };
    };

    const eventDetails = getEventDetails();
    
    useEffect(() => {
        if(!notsoDetails) {
            fetchNotsoDetails(eventDetails, postData, setNotsoDetails);
        }
    }, [eventDetails, postData, notsoDetails]);

    if ((eventDetails.notsoid && !notsoDetails?.type) || notsoDetails?.error) {
        return unloadedEventComponent(eventDetails, notsoDetails);
    }

    if (!notsoDetails?.type || !notsoDetails?.satellites?.length) {
        return null;
    }
    
    return (
        <>
            <Header
                showSideBarMenu={true}
                showDashboardMenu={false}
                height={56} 
                className="pb-5"
            />
            <div id="full-page-background" className="p-4 min-h-screen" style={{display:"flex"}}>
                <div className="h-full" style={{flex: "1",display:"flex",flexDirection:"column"}}>
                    {notso && notsoDescriptionComponent(notso, notsoDetails, selectedDate, handleDateChange, 
                        handleModalShow, navigate, setNotso)}

                    {(notsoDetails.type === 'rpo' || notsoDetails.type === 'maneuver' || notsoDetails.type === 'photometric') && (
                        <EventDashboards 
                            type={notsoDetails.type}
                            satellites={notsoDetails.satellites}
                            notso={notso}
                            selectedDate={selectedDateUtc}
                        />
                    )}

                    {(notsoDetails.type === 'rpo' || notsoDetails.type === 'photometric') && notso && (
                        <NotsoModal 
                            show={showModal}
                            onHide={handleModalClose}
                            notso={notso}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default Event;
