export const getRelativeTime = (dateString) => {
    const date = new Date(dateString.endsWith("Z") ? dateString : (dateString+"Z"));
    const now = new Date();
    const diffMs = now - date;
    const diffSecs = Math.floor(diffMs / 1000);
    const diffMins = Math.floor(diffSecs / 60);
    const diffHours = Math.floor(diffMins / 60);
    const diffDays = Math.floor(diffHours / 24);

    const remainingHours = diffHours % 24;
    const remainingMins = diffMins % 60;

    let result = '';
    if (diffDays > 0) result += `${diffDays}d `;
    if (remainingHours > 0) result += `${remainingHours}h `;
    if (remainingMins > 0) result += `${remainingMins}m`;
    
    return result ? `${result} ago` : 'just now';
};

export const getDayOfYear = (date) => {
    const start = new Date(date.getFullYear(), 0, 0);
    const diff = date - start;
    const oneDay = 1000 * 60 * 60 * 24;
    const dayOfYear = Math.floor(diff / oneDay);
    return dayOfYear.toString().padStart(3, '0');
}; 