import React, { useContext, memo } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Button from "react-bootstrap/Button";
import {AppState} from "../lib/context/AppProvider";
import { useNavigate } from 'react-router-dom';
import { CiLogout } from "react-icons/ci";
import { GiRadarDish } from "react-icons/gi";
import { TbApi } from "react-icons/tb";
import { FaUser, FaChartBar, FaCommentDots, FaFolderOpen, FaCog, FaHome, FaPuzzlePiece, FaGlobeAmericas   } from "react-icons/fa";
import "../styles/SideBar.css";
import { logoutUser } from "../pages/Account";

const openLink = (path) => {
    // Validate or sanitize the path to prevent XSS
    const sanitizedPath = encodeURI(path); // Example of simple sanitization

    // Ensure the path is a relative path to prevent open redirects
    if (!sanitizedPath.startsWith('/')) {
        console.error('Invalid path');
        return;
    }

    const url = new URL(window.location.origin + sanitizedPath);
    window.open(url.toString(), '_blank', 'noopener,noreferrer')?.focus();
};

// Memoize the buttons to prevent unnecessary re-renders
const NavigationButton = memo(({ icon: Icon, text, onClick, onContextMenu }) => (
    <Button
        variant="link"
        className="sidebar-link w-100 mb-2"
        onClick={onClick}
        onContextMenu={onContextMenu}
    >
        <span className="icon"><Icon /></span> {text}
    </Button>
));

const SideBar = ({ visible, handleClose, spaceCockpitLogo }) => {
    const {user, setUser, getBaseUrl } = useContext(AppState);
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
        handleClose();
    };

    const handleBackdropClick = (e) => {
        // Only close if clicking the backdrop itself, not its children
        if (e.target === e.currentTarget) {
            handleClose();
        }
    };

    // Define navigation items outside of render to prevent recreation
    const navigationItems = [
        { icon: FaHome, text: 'Home', path: '/home' },
        { icon: FaGlobeAmericas, text: '3D Space Cockpit', path: '/spacecockpit' },
        { icon: FaChartBar, text: '2D Analysis & Reports', path: '/dashboards/geo-summary' },
        { icon: FaPuzzlePiece, text: 'Space App Marketplace', path: '/sam' },
        { icon: GiRadarDish, text: 'Command & Control', path: '/c2' },
        { icon: FaFolderOpen, text: 'PROBE Intel Dossiers', path: '/pair' },
        { icon: FaCommentDots, text: 'Chat Ops', path: '/chat' },
        { icon: TbApi, text: 'API', path: '/api' }
    ];

    return (
        <Offcanvas 
            className={`white sidebar bg-dark white w-800 ${!visible ? 'hiding' : ''}`}
            show={visible} 
            onHide={handleClose} 
            placement="end"
            data-testid="offcanvas-backdrop"
            style={{ 
                willChange: 'transform',
                transform: 'translateZ(0)'
            }}
            onBackdropClick={handleBackdropClick}
            backdropClassName="sidebar-backdrop"
            backdrop={true}
            scroll={false}
            enforceFocus={false}
        >
            <Offcanvas.Body onClick={handleBackdropClick}>
                <section className="mt-3 text-center">
                    <img 
                        className="login-logo" 
                        src={spaceCockpitLogo} 
                        alt="logo" 
                        height={100}
                        loading="eager"
                        style={{ 
                            aspectRatio: 'auto',
                            willChange: 'transform'
                        }}
                        decoding="async"
                    />
                    <h4 className="text-white">Space Cockpit <br/>Battle Management System</h4>
                </section>
                
                <div className="settings-subsection mt-4">
                    {navigationItems.map(({ icon, text, path }) => (
                        <NavigationButton
                            key={path}
                            icon={icon}
                            text={text}
                            onClick={() => handleNavigation(path)}
                            onContextMenu={() => openLink(path)}
                        />
                    ))}
                    
                    {!user.isGuest && (<>
                        <NavigationButton
                            icon={FaCog}
                            text="Settings"
                            onClick={() => handleNavigation('/settings')}
                            onContextMenu={() => openLink('/settings')}
                        />
                        <NavigationButton
                            icon={FaUser}
                            text="Account"
                            onClick={() => handleNavigation('/account')}
                            onContextMenu={() => openLink('/account')}
                        />
                    </>)}
                </div>

                <div className="settings-subsection mt-auto">
                    <Button
                        variant="link"
                        key="logout"
                        className="sidebar-link w-100 mb-4"
                        onClick={() => logoutUser(user, getBaseUrl, navigate, setUser)} 
                    >
                        <span className="icon"><CiLogout /></span> Logout
                    </Button>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default memo(SideBar);