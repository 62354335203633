import React from 'react';

const AccordionTitle = ({ children, alerts, showZero = true, color = null }) => {
    // Get the background color - red for alerts, green for checkmark
    const getBackgroundColor = () => {
        return alerts > 0 ? "rgb(220, 53, 69)" : "rgb(40, 164, 53)"; // Red for alerts, green for checkmark
    };

    return (
        <h5 className="text-xl font-bold mb-0" style={{
            display: "flex",
            alignItems: "center"
        }}>
            {children}

            {(alerts > 0 || showZero) ? (
                <div style={{
                    color: "white",
                    backgroundColor: getBackgroundColor(),
                    borderRadius: "30%",
                    padding: "2px",
                    fontSize: "16px",
                    minWidth: "23px",
                    textAlign: "center",
                    marginLeft: "10px",
                    paddingLeft: "5px",
                    paddingRight: "5px"
                }}>{alerts > 0 ? alerts : "✓"}</div>
            ) : null}
        </h5>
    );
};

export default AccordionTitle; 