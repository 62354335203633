import React, { useContext, useEffect, useState, useCallback, useRef, useMemo } from "react";
import { AppState, getType, getCardColor } from "../lib/context/AppProvider";
import { Table, Button, Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import "../styles/page.css";
import SpaceCockpitDockContainer from "../components/SpaceCockpitDockContainer";
import { toast } from "react-toastify";
import Select from 'react-select';
import { getDomainUrl } from "../util/Utils";

// Import new components
import AlertsTable from "../components/home/AlertsTable";
import NotsoTable from "../components/home/NotsoTable";
import SpaceWeather from "../components/home/SpaceWeather";
import AccordionTitle from "../components/home/AccordionTitle";

// Import utilities
import { getAlertTimeRange, hasSatNo } from "../utils/alerts";
import { groupNotsos, getCategoryType } from "../utils/notsos";
import { getMaxHeight, calcActive } from "../utils/calculations";

const lookbackDays = 2;

// Common styles
const tableContainerStyle = {
    overflowY: 'auto',
};

const zoom = 0.5;

const buildWeatherContainerStyle = (maxHeight) => ({
    gridColumn: '1 / -1',
    backgroundColor: '#1a1d20',
    borderRadius: '8px',
    maxHeight: `calc(${maxHeight})`,
    transition: "max-height 0.25s linear"
});

const buildIFrameStyle = (maxHeight) => ({
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#212529',
    width: `${100/zoom}%`,
    height: `calc(${maxHeight}/${zoom})`,
    transform: `scale(${zoom})`,
    transformOrigin: '0 0',
    padding: '1rem',
});

// Event type options for filtering
const getEventTypes = () => [
    { value: "photometry" , label: "Photometry" },
    { value: "rpo" , label: "RPO" },
    { value: "maneuver", label: "Maneuver"  },
];

export const getRelativeTime = (dateString) => {
    const date = new Date(dateString.endsWith("Z") ? dateString : (dateString+"Z"));
    const now = new Date();
    const diffMs = now - date;
    const diffSecs = Math.floor(diffMs / 1000);
    const diffMins = Math.floor(diffSecs / 60);
    const diffHours = Math.floor(diffMins / 60);
    const diffDays = Math.floor(diffHours / 24);

    const remainingHours = diffHours % 24;
    const remainingMins = diffMins % 60;

    let result = '';
    if (diffDays > 0) result += `${diffDays}d `;
    if (remainingHours > 0) result += `${remainingHours}h `;
    if (remainingMins > 0) result += `${remainingMins}m`;
    
    return result ? `${result} ago` : 'just now';
};

const NotsoTableRow = ({ notso, setSCScene, navigate }) => {
    const type = getType(notso.EventClass);
    const color = getCardColor(type);
    const hasSatNos = notso.SatNos && notso.SatNos.length > 0;
    
    const rowStyle = {
        borderLeft: `4px solid var(--bs-${color})`,
        backgroundColor: 'transparent',
        cursor: hasSatNos ? "pointer" : undefined
    };

    const handleRowClick = () => {
        if (hasSatNos) {
            setSCScene({
                sats: notso.SatNos,
                focus: notso.Satnos?.[0] ?? null,
                time: notso.CreatedAt
            });
        }
    };

    const tooltipOverlay = !hasSatNos ? (
        <Tooltip>
            SatNos have not been provided for this alert
        </Tooltip>
    ) : <></>;

    return (
        <tr key={notso.Id} style={rowStyle} onClick={handleRowClick}>
            <td>{getRelativeTime(notso.CreatedAt)}</td>
            <td>{notso.EventClass}</td>
            <td>
                <OverlayTrigger placement="left" overlay={tooltipOverlay}>
                    <span>
                        <Button 
                            variant="primary" 
                            size="sm"
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate(`/event/${notso.IdUdl}`);
                            }}
                            disabled={!hasSatNos}
                        >
                            View
                        </Button>
                    </span>
                </OverlayTrigger>
            </td>
        </tr>
    );
};

const NotsoTableContent = ({ recentNotsos, loadingNotso, setSCScene, navigate }) => {
    if (recentNotsos.length === 0) {
        return (
            <tr>
                <td colSpan="3" className="text-center">
                    {loadingNotso ? "Loading NOTSOs..." : "No recent NOTSOs"}
                </td>
            </tr>
        );
    }

    return recentNotsos.map((notso) => (
        <NotsoTableRow 
            key={notso.Id}
            notso={notso}
            setSCScene={setSCScene}
            navigate={navigate}
        />
    ));
};

export const HomeNotsoTable = ({
    recentNotsos, loadingNotso, tableStyle, setSCScene
}) => {
    const navigate = useNavigate();
    
    return (
        <div style={tableStyle}>
            <div style={tableContainerStyle}>
                <Table variant="dark" hover>
                    <thead>
                        <tr>
                            <th>Created</th>
                            <th>Event Class</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <NotsoTableContent 
                            recentNotsos={recentNotsos}
                            loadingNotso={loadingNotso}
                            setSCScene={setSCScene}
                            navigate={navigate}
                        />
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

const LaunchTableRow = ({ launch, setSCScene, navigate }) => {
    const type = getType(launch.EventClass);
    const color = getCardColor(type);
    const canClick = hasSatNo(launch.SatNos);
    
    const rowStyle = {
        borderLeft: `4px solid var(--bs-${color})`,
        backgroundColor: 'transparent',
        cursor: canClick ? "pointer" : undefined
    };

    const handleRowClick = () => {
        if (canClick) {
            setSCScene({
                sats: launch.SatNos,
                focus: launch.Satnos?.[0] ?? null,
                time: launch.CreatedAt
            });
        }
    };

    return (
        <tr key={launch.Id} style={rowStyle} onClick={handleRowClick}>
            <td>{getRelativeTime(launch.CreatedAt)}</td>
            <td>{launch.EventClass}</td>
            <td>
                <Button 
                    variant="primary" 
                    size="sm"
                    onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/event/${launch.IdUdl}`);
                    }}
                >
                    View
                </Button>
            </td>
        </tr>
    );
};

const LaunchTableContent = ({ recentLaunches, loadingNotso, setSCScene, navigate }) => {
    if (recentLaunches.length === 0) {
        return (
            <tr>
                <td colSpan="3" className="text-center">
                    {loadingNotso ? "Loading launches..." : "No recent launches"}
                </td>
            </tr>
        );
    }

    return recentLaunches.map((launch) => (
        <LaunchTableRow 
            key={launch.Id}
            launch={launch}
            setSCScene={setSCScene}
            navigate={navigate}
        />
    ));
};

export const HomeLaunchesTable = ({
    recentLaunches, loadingNotso, tableStyle, setSCScene
}) => {
    const navigate = useNavigate();
    
    return (
        <div style={tableStyle}>
            <div style={tableContainerStyle}>
                <Table variant="dark" hover>
                    <thead>
                        <tr>
                            <th>Created</th>
                            <th>Event Class</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <LaunchTableContent 
                            recentLaunches={recentLaunches}
                            loadingNotso={loadingNotso}
                            setSCScene={setSCScene}
                            navigate={navigate}
                        />
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export const HomeSpaceWeather = ({user, userDetails, maxHeight}) => {
    const weatherContainerStyle = useMemo(() => buildWeatherContainerStyle(maxHeight), [maxHeight]);
    const iframeStyle = useMemo(() => buildIFrameStyle(maxHeight), [maxHeight]);
    
    return <>
        <div style={weatherContainerStyle} data-testid="space-weather-container">
            {user.isGuest ? (
                <div className="d-flex align-items-center justify-content-center h-100">
                    <div className="text-center">
                        <h5 className="text-white-50 mb-3">Space Weather Preview</h5>
                        <p className="text-white-50">Login to view real-time space weather data</p>
                    </div>
                </div>
            ) : (
                <iframe
                    src={`${getDomainUrl('spacefana')}/d/rC4UUfW4d/space-weather?var-accesstoken=${userDetails?.AccessToken}&kiosk`}
                    style={iframeStyle}
                    title="Space Weather"
                />
            )}
        </div>
    </>;
}

// --- Helper Functions for Alert Fetching ---

// Extracts a flat list of satellite IDs from a constellations object.
const getSatelliteIdsFromConstellations = (constellations) => {
    // Ensure constellations is an object before processing
    if (typeof constellations !== 'object' || constellations === null) {
        return [];
    }
    return Object.values(constellations)
        // Ensure each constellation 'c' has a 'satellites' array, default to empty array if not
        .map((c) => c?.satellites ?? [])
        .flat(); // Flatten the array of arrays into a single array
};

// Filters ranked alerts based on whether any event's primary country matches the provided set.
const filterRankedAlertsByCountry = (rankedAlerts, countrySet) => {
    // Early return if no alerts or no countries to filter by
    if (!rankedAlerts || rankedAlerts.length === 0 || !countrySet || countrySet.size === 0) {
        return [];
    }
    return rankedAlerts.filter(alert =>
        // Check if any event in the alert matches the country set
        alert.Data?.events?.some(event =>
            countrySet.has(event?.primary?.country) // Use 'country' as per previous filter logic
        )
    );
};

// --- End Helper Functions ---

function Home() {
    const { postData, getData, user, userDetails, patchData } = useContext(AppState);
    const [recentNotsos, setRecentNotsos] = useState([]);
    const [recentLaunches, setRecentLaunches] = useState([]);
    const [blueConstellations, setBlueConstellations] = useState({});
    const [redConstellations, setRedConstellations] = useState({});
    const [blueAlerts, setBlueAlerts] = useState([]);
    const [redAlerts, setRedAlerts] = useState([]);
    const [showAcknowledged, setShowAcknowledged] = useState(false);
    const [loadingNotso, setLoadingNotso] = useState(false);
    const [loadingAlerts, setLoadingAlerts] = useState(false);
    const [selectedAlertId, setSelectedAlertId] = useState(null);
    const [selectedEventTypes, setSelectedEventTypes] = useState([]);
    const [activeKeys, setActiveKeys] = useState([]);

    const location = useLocation();
    const [query, setQuery] = useState(location.search?.length > 0 ? location.search : null);
    const {selectedProfile} = useContext(AppState);

    // Filter NOTSOs and alerts based on selected event types
    const filteredNotsos = React.useMemo(() => {
        if (selectedEventTypes.length === 0) return recentNotsos;
        return recentNotsos.filter(notso => 
            selectedEventTypes.some(type => getCategoryType(notso.EventClass) === type.value)
        );
    }, [recentNotsos, selectedEventTypes]);

    const filteredBlueAlerts = React.useMemo(() => {
        if (selectedEventTypes.length === 0) return blueAlerts;
        return blueAlerts.filter(alert => 
            alert.Data?.events?.some(event => 
                selectedEventTypes.some(type => getCategoryType(event.type) === type.value)
            )
        );
    }, [blueAlerts, selectedEventTypes]);

    const filteredRedAlerts = React.useMemo(() => {
        if (selectedEventTypes.length === 0) return redAlerts;
        return redAlerts.filter(alert => 
            alert.Data?.events?.some(event => 
                selectedEventTypes.some(type => getCategoryType(event.type) === type.value)
            )
        );
    }, [redAlerts, selectedEventTypes]);

    const initialOpen = useRef([]);

    const initialOpenKey = (key) => {
        if(initialOpen.current.includes(key)) return;
        initialOpen.current.push(key);
        setActiveKeys((active) => active.includes(key) ? active : [...active, key]);
    };

    // Fetch team alerts
    const fetchTeamAlerts = useCallback(async (satellites, timeRange) => {
        if (!satellites.length) return [];
        
        const params = {
            satnos: satellites.join(','),
            start_timestamp: timeRange.start_timestamp.toString(),
            end_timestamp: timeRange.end_timestamp.toString(),
            show_acknowledged: showAcknowledged ? 'true' : 'false'
        };

        return getData('alerts', params);
    }, [getData, showAcknowledged]);

    const fetchRankedAlerts = useCallback(async (timeRange) => {
        const blues = Object.values(userDetails.Constellations.blues);
        const reds = Object.values(userDetails.Constellations.reds);
        if(
            user.isGuest
            || (blues.length === 0 && reds.length === 0)
            || blues.every(c => !c.useJCO)
            || reds.every(c => !c.useJCO)
        ) return [];

        const params = {
            start_timestamp: timeRange.start_timestamp.toString(),
            end_timestamp: timeRange.end_timestamp.toString(),
            show_acknowledged: showAcknowledged ? 'true' : 'false'
        };
        const alerts = await getData('alerts', params);
        const jcoRankedAlerts = alerts.filter(a=>a.Data.events.some(e=>e.primary.rank));

        return jcoRankedAlerts;
    }, [getData, user.isGuest, showAcknowledged, userDetails]);

    // Helper functions for fetchRecentNotsos
    const handleGuestUser = () => {
        setRecentLaunches([]);
        setRecentNotsos([]);
        setLoadingNotso(false);
    };

    const getFormattedDate = () => {
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - lookbackDays);
        return sevenDaysAgo.toISOString().split('T')[0] + ' 00:00:00';
    };

    const buildNotsoPayload = (formattedDate) => ({
        table: "Notsos",
        columns: ["*"],
        wheres: [{
            column: "CreatedAt",
            operator: ">",
            value: formattedDate
        }],
        orderBy: [{
            column: "CreatedAt",
            direction: "DESC"
        }]
    });

    const readResponseStream = async (reader) => {
        const textDecoder = new TextDecoder();
        let result = '';
        
        while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            result += textDecoder.decode(value);
        }
        
        return JSON.parse(result);
    };

    const processNotsoData = (data) => {
        const launches = data.filter(notso => 
            notso.EventClass.toLowerCase().includes('launch')
        );
        const otherNotsos = data.filter(notso => 
            !notso.EventClass.toLowerCase().includes('launch')
        );
        
        return {
            launches,
            notsos: groupNotsos(otherNotsos)
        };
    };

    // Fetch NOTSOs
    useEffect(() => {
        const fetchRecentNotsos = async () => {
            if (user.isGuest) {
                handleGuestUser();
                return;
            }

            setLoadingNotso(true);

            try {
                const formattedDate = getFormattedDate();
                const payload = buildNotsoPayload(formattedDate);
                const response = await postData("read", payload);
                
                if (response?.status === 200) {
                    const data = await readResponseStream(response.body.getReader());
                    const { launches, notsos } = processNotsoData(data);
                    
                    setRecentLaunches(launches);
                    setRecentNotsos(notsos);
                }
            } catch (error) {
                console.error('Error fetching NOTSOs:', error);
                toast.error("Failed to fetch NOTSOs");
                setRecentLaunches([]);
                setRecentNotsos([]);
            } finally {
                setLoadingNotso(false);
            }
        };

        fetchRecentNotsos();
    }, [user.isGuest, postData]);

    // Fetch alerts
    useEffect(() => {
        const fetchAllAlerts = async () => {
            if (user.isGuest || !userDetails || !userDetails.Constellations) return;

            setLoadingAlerts(true);

            try {
                // Use helper function to get satellite IDs
                const blueSats = getSatelliteIdsFromConstellations(blueConstellations);
                const redSats = getSatelliteIdsFromConstellations(redConstellations);
                const timeRange = getAlertTimeRange(lookbackDays);

                // Prepare country sets
                const blueCountries = userDetails.Constellations.blueCountries || [];
                const redCountries = userDetails.Constellations.redCountries || [];
                const blueCountrySet = new Set(blueCountries);
                const redCountrySet = new Set(redCountries);
                const hasCountriesToFilter = blueCountries.length > 0 || redCountries.length > 0;

                // Fetch base alerts and ranked alerts conditionally
                let [baseBlueAlerts, baseRedAlerts, jcoRankedAlerts] = await Promise.all([
                    fetchTeamAlerts(blueSats, timeRange),
                    fetchTeamAlerts(redSats, timeRange),
                    hasCountriesToFilter ? fetchRankedAlerts(timeRange) : Promise.resolve([])
                ]);

                // Filter ranked alerts using the helper function
                const jcoBlueCountryAlerts = filterRankedAlertsByCountry(jcoRankedAlerts, blueCountrySet);
                const jcoRedCountryAlerts = filterRankedAlertsByCountry(jcoRankedAlerts, redCountrySet);

                // Combine and deduplicate alerts using the helper function
                baseBlueAlerts = baseBlueAlerts.concat(jcoBlueCountryAlerts);
                baseRedAlerts =baseRedAlerts.concat(jcoRedCountryAlerts);

                // Update state
                setBlueAlerts(baseBlueAlerts);
                setRedAlerts(baseRedAlerts);

                // Trigger initial accordion open based on final alert lists
                if (baseBlueAlerts.length > 0) initialOpenKey("blue-alerts");
                if (baseRedAlerts.length > 0) initialOpenKey("red-alerts");

            } catch (error) {
                console.error('Error fetching alerts:', error);
                toast.error("Failed to fetch alerts");
                // Reset state on error
                setBlueAlerts([]);
                setRedAlerts([]);
            } finally {
                setLoadingAlerts(false);
            }
        };

        fetchAllAlerts();
    }, [
        blueConstellations,
        redConstellations,
        userDetails,
        getData,
        showAcknowledged,
        fetchTeamAlerts,
        fetchRankedAlerts,
        user.isGuest
    ]);

    // Handle scene state
    const [scScene, setSCSceneRaw] = useState({time: new Date().toISOString()});
    const setSCScene = useCallback((scene) => {
        setQuery(null);
        setSCSceneRaw(scene);
    }, [setQuery, setSCSceneRaw]);

    // Handle profile changes
    const lastProfile = useRef(selectedProfile);
    useEffect(() => {
        if(selectedProfile !== lastProfile.current) {
            setQuery(null);
            lastProfile.current = selectedProfile;

            setSCScene({
                sats: selectedProfile?.satellites,
                focus: selectedProfile?.satellites?.[0] ?? null,
            });
        }
    }, [selectedProfile, setSCScene]);
    
    // Space Cockpit position state
    const [currentSpaceCockpitPosition, setSpaceCockpitPositionRaw] = useState({
        position: "right", 
        size: 0.75
    });

    const setSpaceCockpitPosition = (pos) => setSpaceCockpitPositionRaw((c) => ({
        position: pos, 
        size: c.size
    }));

    // Get user details and constellations
    useEffect(() => {
        if (!user.isGuest && userDetails?.Constellations) {
            setBlueConstellations(userDetails.Constellations.blues || {});
            setRedConstellations(userDetails.Constellations.reds || {});
        }
    }, [userDetails, user.isGuest]);

    // Calculate max height for components
    const maxHeight = React.useMemo(() => {
        const {total, full} = calcActive(
            activeKeys, 
            recentNotsos.length, 
            redAlerts.length, 
            blueAlerts.length, 
            recentLaunches.length
        );
        return getMaxHeight(total, full);
    }, [activeKeys, blueAlerts.length, recentLaunches.length, recentNotsos.length, redAlerts.length]);

    // Calculate table styles
    const tableStyle = useCallback((key) => {
        const isNotsoWithContent = key === "notsos" && recentNotsos.length > 0;
        const heightMultiplier = isNotsoWithContent ? '2' : '1';
        const calculatedHeight = 'calc(' + maxHeight + '*' + heightMultiplier + ')';
        
        return {
            backgroundColor: '#1a1d20',
            padding: '0.5rem',
            minHeight: '50px',
            display: "flex",
            flexDirection: "column",
            maxHeight: calculatedHeight,
            transition: "max-height 0.25s linear"
        };
    }, [maxHeight, recentNotsos.length]);

    // Accordion styles
    const accordionItemStyle = {
        backgroundColor: '#1a1d20',
        border: '1px solid #2a2d30',
        borderRadius: '8px',
        marginBottom: '1rem',
        overflow: 'hidden'
    };

    const accordionButtonStyle = {
        backgroundColor: '#1a1d20',
        borderBottom: '1px solid #2a2d30',
        width: '100%',
        textAlign: 'left'
    };

    // Helper function for screen reader status
    const getScreenReaderStatus = (count) => {
        if (count === 0) return "No events selected";
        const suffix = count === 1 ? '' : 's';
        return count + ' event' + suffix + ' selected';
    };

    return (
        <>
            <Header
                showSideBarMenu={true}
                showDashboardMenu={false}
                height={56} 
                className="pb-5"
                showProfiles={true}
                isSpaceCockpit={true}
            />
            <div id="full-page-background" className="min-h-screen" style={{height: "calc(100vh - 56px - 12px)"}}>
                <SpaceCockpitDockContainer 
                    id="home"
                    currentSpaceCockpitPosition={currentSpaceCockpitPosition}
                    setSpaceCockpitPosition={setSpaceCockpitPosition}
                    time={query ? null : scScene?.time}
                    sats={query ? null : scScene?.sats}
                    focus={query ? null : scScene?.focus}
                    initialQueryParams={query}
                    showDockVertical={false}
                    topPadding={false}
                    showFullscreenContent={false}
                    isHome={true}
                    rightPanel={userDetails?.Settings?.homeViz ?? "Space Cockpit"}
                >
                    <div id="home-container" style={{ height: '100%', width: "100%" }}>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <div className="form-check form-switch d-flex align-items-center">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="showAcknowledgedSwitch"
                                    checked={showAcknowledged}
                                    onChange={(e) => setShowAcknowledged(e.target.checked)}
                                    disabled={loadingAlerts}
                                />
                                <label className="form-check-label text-white ms-2 d-flex align-items-center gap-1" htmlFor="showAcknowledgedSwitch">
                                    Show Acknowledged
                                    {loadingAlerts && (
                                        <div className="spinner-border spinner-border-sm" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    )}
                                </label>
                            </div>
                            <div style={{ width: '300px' }}>
                                <Select
                                    isMulti
                                    options={getEventTypes()}
                                    value={selectedEventTypes}
                                    onChange={setSelectedEventTypes}
                                    placeholder="Filter by event type..."
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    aria-label="Filter events"
                                    noOptionsMessage={() => "No event types available"}
                                    screenReaderStatus={({ count }) => getScreenReaderStatus(count)}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#2a2d30',
                                            primary25: '#1a1d20',
                                            neutral0: '#2a2d30',
                                            neutral80: 'white',
                                        },
                                    })}
                                    styles={{
                                        control: (base) => ({
                                            ...base,
                                            backgroundColor: '#2a2d30',
                                            borderColor: '#495057',
                                        }),
                                        menu: (base) => ({
                                            ...base,
                                            backgroundColor: '#2a2d30',
                                        }),
                                        option: (base, state) => ({
                                            ...base,
                                            backgroundColor: state.isFocused ? '#1a1d20' : '#2a2d30',
                                            color: 'white',
                                        }),
                                        multiValue: (base) => ({
                                            ...base,
                                            backgroundColor: '#1a1d20',
                                        }),
                                        multiValueLabel: (base) => ({
                                            ...base,
                                            color: 'white',
                                        }),
                                        multiValueRemove: (base) => ({
                                            ...base,
                                            color: 'white',
                                            ':hover': {
                                                backgroundColor: '#495057',
                                                color: 'white',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                        </div>

                        <Accordion 
                            activeKey={activeKeys}
                            onSelect={(key) => setActiveKeys(!activeKeys?.includes(key) ? [...activeKeys, key] : activeKeys.filter((k) => k !== key))}
                            className="mb-3 settings-accordion h-100"
                        >
                            <Accordion.Item eventKey="notsos" style={accordionItemStyle}>
                                <Accordion.Header style={accordionButtonStyle}>
                                    <AccordionTitle alerts={filteredNotsos.length}>
                                        NOTSOs &nbsp;
                                        <small style={{fontSize: '0.7rem'}}>(Prev {lookbackDays} days)</small> 
                                    </AccordionTitle>
                                </Accordion.Header>
                                <Accordion.Body className="p-0 mt-0 ps-0 pe-0 text-white">
                                    <NotsoTable
                                        recentNotsos={filteredNotsos}
                                        loadingNotso={loadingNotso}
                                        tableStyle={tableStyle("notsos")}
                                        setSCScene={setSCScene}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="red-alerts" style={accordionItemStyle}>
                                <Accordion.Header style={accordionButtonStyle}>
                                    <AccordionTitle alerts={filteredRedAlerts.length} color="Red">
                                        <span>Red Team Alerts</span>
                                    </AccordionTitle>
                                </Accordion.Header>
                                <Accordion.Body className="p-0 mt-0 ps-0 pe-0 text-white">
                                    <AlertsTable
                                        color="Red"
                                        constellations={redConstellations}
                                        alerts={filteredRedAlerts}
                                        loading={loadingAlerts}
                                        tableStyle={tableStyle("red-alerts")}
                                        setSCScene={setSCScene}
                                        selectedAlertId={selectedAlertId}
                                        setSelectedAlertId={setSelectedAlertId}
                                        blueAlerts={filteredBlueAlerts}
                                        patchData={patchData}
                                        showAcknowledged={showAcknowledged}
                                        setShowAcknowledged={setShowAcknowledged}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="blue-alerts" style={accordionItemStyle}>
                                <Accordion.Header style={accordionButtonStyle}>
                                    <AccordionTitle alerts={filteredBlueAlerts.length} color="Blue">
                                        <span>Blue Team Alerts</span>
                                    </AccordionTitle>
                                </Accordion.Header>
                                <Accordion.Body className="p-0 mt-0 ps-0 pe-0 text-white" data-testid="blue-alerts-section">
                                    <AlertsTable
                                        color="Blue"
                                        constellations={blueConstellations}
                                        alerts={filteredBlueAlerts}
                                        loading={loadingAlerts}
                                        tableStyle={tableStyle("blue-alerts")}
                                        setSCScene={setSCScene}
                                        selectedAlertId={selectedAlertId}
                                        setSelectedAlertId={setSelectedAlertId}
                                        blueAlerts={filteredBlueAlerts}
                                        patchData={patchData}
                                        showAcknowledged={showAcknowledged}
                                        setShowAcknowledged={setShowAcknowledged}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="space-weather" style={accordionItemStyle}>
                                <Accordion.Header style={accordionButtonStyle}>
                                    <AccordionTitle alerts={0} showZero={false}>
                                        Space Weather
                                    </AccordionTitle>
                                </Accordion.Header>
                                <Accordion.Body className="p-0 mt-0 ps-0 pe-0 text-white">
                                    <SpaceWeather 
                                        user={user}
                                        userDetails={userDetails}
                                        maxHeight={maxHeight}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </SpaceCockpitDockContainer>
            </div>
        </>
    );
}

export default Home;
