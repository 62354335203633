import { useContext, useEffect, useRef } from "react";
import { getDomainUrl } from "../util/Utils";
import { toast } from "react-toastify";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FiShare } from "react-icons/fi";
import { DashboardState, encodeDashboardName } from "../lib/context/DashboardProvider";

const SpacefanaShareButton = () => {

    const status = useRef({waiting: false, timeout: null});
    const spacefanaURL = getDomainUrl('spacefana');

    const {
        dashboardName
    } = useContext(DashboardState);

    const resetStatus = () => {
        status.current = {
            waiting: false,
            timeout: null
        };
    }

    useEffect(() => {
        const handleMessage = (e) => {
            let msg = e.data;
            if(msg?.type === "spacefana-params-response") {
                if(e.origin !== spacefanaURL) {
                    console.warn("Got spacefana params response but from incorrect origin", e.origin, "vs", spacefanaURL);
                    return;
                }

                if(status.current.waiting) {
                    const processedLink = new URL(window.location.origin + "/dashboards/" + encodeDashboardName(dashboardName));
                    processedLink.search = msg.params;
                    navigator.clipboard.writeText(processedLink.toString());
                    toast.success('Link copied to clipboard.', {
                        position: "top-center",
                        autoClose: 1000,
                    });
                    resetStatus();
                }
            }
        };

        // on mount
        window.addEventListener('message', handleMessage);

        return () => {
            // on unmount
            window.removeEventListener('message', handleMessage); 
        }
    }, [dashboardName, spacefanaURL]);

    const handleShare = () => {
        const frame = document.getElementById("spacefana-frame");

        if(status.current.timeout) {
            clearTimeout(status.current.timeout);
            resetStatus();
        }

        status.current = {
            waiting: true,
            timeout: setTimeout(() => resetStatus(), 1000)
        };

        frame.contentWindow.postMessage({
            type: "spacefana-params-request"
        }, spacefanaURL);
    };
    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip>
                    Copy shareable link
                </Tooltip>
            }
        >
            <Button
                variant="dark"
                onClick={handleShare}
                className="center-contents header-button"
                style={{
                    marginRight: "16px"
                }}
            >
                <FiShare />
            </Button>
        </OverlayTrigger>
    );
};

export default SpacefanaShareButton;