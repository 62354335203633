import React, { useContext } from 'react';
import Header from "../components/Header";
import { AppState } from '../lib/context/AppProvider';

function Api() {
    const { isLocalDev } = useContext(AppState);
    const url = isLocalDev ? "https://nps.saberdevusa.xyz" : window.location.origin.replace("sbms","nps");
    
    const iframeStyle = {
        width: '100%',
        height: 'calc(100vh - 56px - 12px)',
        border: 'none'
    };

    return (  
        <div data-testid="home-container" id="home-container">
            <Header
                showSideBarMenu={true}
                showDashboardMenu={false}
                height={56} 
                className="pb-5"
            />
            <iframe 
                title="NPS API Docs"
                src={url+"/api-docs"}
                style={iframeStyle}
                allow="geolocation"
            ></iframe>
        </div>
    );
};

export default Api;
