import React from 'react';
import { Button, Badge } from 'react-bootstrap';
import { getAlertSats, hasSatNo } from '../../utils/alerts';
import { getRelativeTime } from '../../utils/notsos';
import PropTypes from 'prop-types';

const AlertRow = ({ 
    alert,
    isSelected,
    setSelectedAlertId,
    setSCScene,
    allBlueAlertSats,
    onView,
    onAcknowledge 
}) => {
    const sats = getAlertSats(alert);
    
    // Validate and sanitize data before use
    const safeData = React.useMemo(() => {
        if (!alert || typeof alert !== 'object') {
            return null;
        }

        return {
            uniqueId: typeof alert.UniqueId === 'string' ? alert.UniqueId : '',
            primarySatNo: typeof alert.PrimarySatNo === 'string' ? alert.PrimarySatNo : '',
            createdAt: typeof alert.CreatedAt === 'string' ? alert.CreatedAt : new Date().toISOString(),
            satellites: Array.isArray(sats) ? sats : []
        };
    }, [alert, sats]);

    // Validate allBlueAlertSats
    const safeBlueAlertSats = React.useMemo(() => 
        Array.isArray(allBlueAlertSats) ? allBlueAlertSats : []
    , [allBlueAlertSats]);

    const handleClick = React.useCallback((event) => {
        event.preventDefault();

        // If data validation failed, don't proceed
        if (!safeData) {
            console.error('Invalid alert data');
            return;
        }

        try {
            if (isSelected) {
                setSelectedAlertId(null);
                setSCScene({
                    sats: safeBlueAlertSats,
                    focus: null,
                    time: new Date().toISOString()
                });
            } else {
                setSelectedAlertId(safeData.uniqueId);
                setSCScene({
                    sats: safeData.satellites,
                    focus: safeData.primarySatNo,
                    time: safeData.createdAt
                });
            }
        } catch (error) {
            console.error('Error in click handler:', error);
        }
    }, [isSelected, safeData, safeBlueAlertSats, setSelectedAlertId, setSCScene]);

    // If data validation failed, don't render
    if (!safeData) {
        return null;
    }

    const rowStyle = {
        borderLeft: isSelected ? '4px solid var(--bs-warning)' : '4px solid transparent',
        backgroundColor: isSelected ? '#2c3338' : 'transparent',
        cursor: hasSatNo(sats) ? "pointer" : undefined,
    };

    return (
        <tr 
            style={rowStyle}
            onClick={handleClick}
            role="row"
            aria-selected={isSelected}
        >
            <td>{getRelativeTime(safeData.createdAt)}</td>
            <td>
                <div className="d-flex align-items-center justify-content-between">
                    <span className="me-2" style={{ flex: 1, minWidth: 0 }}>
                        {alert.Description}
                        <Badge 
                            bg={alert.Level?.toLowerCase() || 'info'}
                            className={alert.Level?.toLowerCase() === 'warning' ? 'text-dark ms-2' : 'ms-2'}
                        >
                            {(alert.Level || 'INFO').toUpperCase()}
                        </Badge>
                    </span>
                </div>
            </td>
            <td style={{ width: '110px' }}>
                <div className="d-flex gap-2">
                    <Button 
                        variant="primary" 
                        size="sm"
                        onClick={(e) => {
                            e.stopPropagation();
                            if (typeof onView === 'function') {
                                try {
                                    onView();
                                } catch (error) {
                                    console.error('Error in onView handler:', error);
                                }
                            }
                        }}
                    >
                        View
                    </Button>
                    <Button 
                        variant="success" 
                        size="sm"
                        onClick={(e) => {
                            e.stopPropagation();
                            if (typeof onAcknowledge === 'function') {
                                try {
                                    onAcknowledge();
                                } catch (error) {
                                    console.error('Error in onAcknowledge handler:', error);
                                }
                            }
                        }}
                    >
                        Ack
                    </Button>
                </div>
            </td>
        </tr>
    );
};

AlertRow.propTypes = {
    alert: PropTypes.shape({
        UniqueId: PropTypes.string.isRequired,
        Description: PropTypes.string.isRequired,
        Level: PropTypes.string.isRequired,
        CreatedAt: PropTypes.string.isRequired,
        PrimarySatNo: PropTypes.string
    }).isRequired,
    isSelected: PropTypes.bool,
    setSelectedAlertId: PropTypes.func.isRequired,
    setSCScene: PropTypes.func.isRequired,
    allBlueAlertSats: PropTypes.arrayOf(PropTypes.string).isRequired,
    onView: PropTypes.func.isRequired,
    onAcknowledge: PropTypes.func.isRequired,
};

AlertRow.defaultProps = {
    isSelected: false
};

export default AlertRow; 