import { useEffect } from "react";
import { checkMessageOrigin, getDomainUrl } from "../../util/Utils";

const sendParams = (params) => {
    document.getElementById("spacefana-frame")?.contentWindow?.postMessage(
        {
            type: "spacefana-params-set",
            params: params
        } ,
        getDomainUrl("spacefana")
    );
};

const useSpacefanaParamsSetter = (params) => {
    useEffect(() => {
        if(params) {
            sendParams(params);
        }
    }, [params]);

    useEffect(() => {
        const handleMessage = (e) => {
            let msg = e.data;
            if(msg?.type === "spacefana-params-listener-ready") {
                if(!checkMessageOrigin(e)) return;
                sendParams(params);
            }
        };

        // on mount
        window.addEventListener('message', handleMessage);

        return () => {
            // on unmount
            window.removeEventListener('message', handleMessage); 
        }
    }, [params]);
};

export default useSpacefanaParamsSetter;