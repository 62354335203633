import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getDayOfYear } from '../utils/time';

const Clock = ({ className }) => {
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(new Date());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const formatTime = () => {
        const dayName = time.toLocaleString('en-US', { weekday: 'short' });
        const day = time.getUTCDate().toString().padStart(2, '0');
        const month = time.toLocaleString('en-US', { month: 'short' });
        const year = time.getUTCFullYear().toString().slice(-2);
        const julianDay = getDayOfYear(time);
        const hours = time.getUTCHours().toString().padStart(2, '0');
        const minutes = time.getUTCMinutes().toString().padStart(2, '0');
        const seconds = time.getUTCSeconds().toString().padStart(2, '0');

        return `${dayName}, ${day} ${month} ${year} (${julianDay}) | ${hours}:${minutes}:${seconds} UTC`;
    };

    return (
        <span className={`text-white ${className}`} style={{ marginLeft: '10px' }}>
            {formatTime()}
        </span>
    );
};

Clock.propTypes = {
    className: PropTypes.string
};

export default Clock; 