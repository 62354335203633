import React, { useState, useContext, useEffect, useRef, useCallback, useMemo } from "react";
import { Button, Modal, Form, Accordion } from "react-bootstrap";
import ModalSearchSection from "../settings/ModalSearchSection";
import { satFilterFunc } from "../settings/SatGroupModal";
import { AppState } from "../../lib/context/AppProvider";
import TlePicker, { emptyTLE, findSelectedRowIndex, getDefaultTleRow } from "./TlePicker";
import useSpacefanaParamsSetter from "./SpacefanaParamsSetter";
import useSatConfigureMessageHandler from "./SatConfigureMessageHandler";
import { SatConfigureState } from "../../lib/context/SatConfigureProvider";
import SatChangeModal from "./SatChangeModal";

const getPromises = (sat, dataType, getData, source, range) => {
    const start = range?.[0] ?? new Date().getTime() - 2 * 24 * 60 * 60 * 1000;
    const end = range?.[1] ?? new Date().getTime();
    
    const promises = [];
    if(dataType === "All" || dataType === "TLE") {
        promises.push(getData("elset-history", {
            satnos: sat,
            start_timestamp: start,
            end_timestamp: end,
            source: source ?? "All"
        }));
    }
    if(dataType === "All" || dataType === "SV") {
        promises.push(getData("state-history", {
            satnos: sat,
            start_timestamp: start,
            end_timestamp: end,
            source: source ?? "All"
        }));
    }

    return promises;
};

const fetchSatData = async (sat, dataType, getData, source, range) => {
    try {
        const results = await Promise.all(getPromises(sat, dataType, getData, source, range));

        let newData = [];
        for(const data of results) {
            if(data) {
                if(data.message) {
                    console.error("Error fetching data:", data.message);
                }
                else {
                    newData = [...newData, ...data];
                }
            }
        }

        return newData.map((d) => ({
            ...d,
            Epoch: d.Epoch.replaceAll(".000000Z", "Z")
        }));
    } catch (error) {
        console.error("Error fetching data:", error);
    }

    return null;
};

const accordionBodyClass = "p-0 mt-3 ps-4 pe-4 text-white";

const SatItem = ({index, id, name, satData, setSatTle, unsavedSatConfig}) => {
    // have event key be by index instead of key, so when we swap out satellites
    // the accordion item stays open
    return (
        <Accordion.Item 
            eventKey={index} 
            className="bg-dark border-0 mt-3"
            data-testid={`accordion-item-${id}`}
        >
            <Accordion.Header className="settings-accordion-header">
                <h4 className="settings-section-title m-0">
                    {id} - {name ?? "Custom"}
                </h4>
            </Accordion.Header>
            <Accordion.Body className={accordionBodyClass} style={{
                maxHeight: "50vh",
                overflow: "hidden"
            }}>
                <TlePicker 
                    sat={id} 
                    index={index}
                    data={satData[stripDupeTag(id)]}
                    setSatTle={setSatTle}
                    selected={unsavedSatConfig?.options?.[id]}
                />
            </Accordion.Body>
        </Accordion.Item>
    )
};

const configSatsToSelected = (config) => {
    return config?.satellites?.map((s) => s.id) ?? [];
}

const selectedSatsToConfig = (selected, getSatName) => {
    return selected?.map((id) => ({
        id: id,
        name: getSatName(id) ?? "Custom"
    })) ?? [];
}

const satPrettyName = (sat) => {
  return `${sat.id} - ${sat.name}`;
}

const getSpacefanaParams = (currentSatConfig) => {
    if(!currentSatConfig) return null;

    const params = {};
    if(currentSatConfig.numSats < 0) {
        // satnos list
        params["var-satnos"] = currentSatConfig.satellites.map(satPrettyName).join(",");
    }
    else {
        currentSatConfig.satellites.forEach((satObj, i) => {
          const sat = satPrettyName(satObj);
          const satnoKey = i === 0 ? "satno" : `satno${i+1}`;
          params[satnoKey] = sat;
    
          const options = currentSatConfig.options?.[satObj.id];
          if(options?.tle) {
            params[`s${i+1}_line1`] = options.tle.line1;
            params[`s${i+1}_line2`] = options.tle.line2;
            params[`s${i+1}_sv`] = "null";
          }
          else if(options?.sv) {
            params[`s${i+1}_line1`] = "null";
            params[`s${i+1}_line2`] = "null";
            params[`s${i+1}_sv`] = options.sv;
          }

          if(options?.metadata) {
            params[`s${i+1}_metadata`] = JSON.stringify(options.metadata);
          }
        });
    }
    return params;
};

const stripDupeTag = (satId) => {
    return satId.split("-")[0];
}

const isDupe = (selectedSatellites, newSat, index) => {
    const count = selectedSatellites.filter((s) => s === newSat).length;
    if(count === 1 && selectedSatellites.findIndex((s) => s === newSat) === index) {
        // it's our original, not a duplicate
        return false;
    }
    return count > 0;
};

const replaceWithCustomSatellite = (satno, index, selectedSatellites, setSelectedSatellites) => {
    let newSat = satno+"";
    let deduper = 2;
    while(isDupe(selectedSatellites, newSat, index)) {
        newSat = satno+"-" +deduper;
        deduper += 1;
    }
    const satToSet = newSat;

    setSelectedSatellites((current) => {
        current[index] = newSat;
        return [...current];
    });
    
    const newSats = [...selectedSatellites];
    newSats[index] = newSat;

    return {satToSet, newSats};
}

const makeNewConfig = (current, {satToSet, tle, sv, metadata, isCustom, save, newSats, getSatName}) => {
    if(!current) return;
    const options = {...current.options};
    options[satToSet] = {tle, sv, metadata, custom: isCustom};

    const newConfig = {
        ...current,
        options: options,
    };

    if(save && newSats) {
        newConfig.satellites = selectedSatsToConfig(newSats, getSatName);
    }
    
    return newConfig;
};

const SatConfigureModal = () => {
    const {
        showConfigureModal, setShowConfigureModal,
        currentSatConfig, setCurrentSatConfig,
        keyToOpen, setKeyToOpen
    } = useContext(SatConfigureState);
    const {satellites, getSatName, getData} = useContext(AppState);

    const [selectedSatellites, setSelectedSatellites] = useState([]);
    const [unsavedSatConfig, setUnsavedSatConfig] = useState(currentSatConfig);

    const [satData, setSatData] = useState({});

    const [activeKeys, setActiveKeys] = useState([]);

    useEffect(() => {
        if(keyToOpen) {
            const ind = selectedSatellites.indexOf(keyToOpen);
            if(ind >= 0) {
                setActiveKeys([ind]);
            }
            setKeyToOpen(null);
        }
    }, [keyToOpen, setKeyToOpen, setActiveKeys, selectedSatellites]);

    useEffect(() => {
        setSelectedSatellites(configSatsToSelected(currentSatConfig));
        setUnsavedSatConfig(currentSatConfig);
    }, [currentSatConfig]);

    const [pendingSat, setPendingSat] = useState(null);

    const acceptPendingSat = useCallback((pending) => {
        const currentSat = pending.current.id;
        const {satToSet, newSats} = replaceWithCustomSatellite(pending.pending.id, pending.index, selectedSatellites, setSelectedSatellites);

        setCurrentSatConfig((current) => {
            if(!current) return;
            const options = {...current.options};
            options[satToSet] = options[currentSat];

            return {
                ...current,
                options: options,
                satellites: selectedSatsToConfig(newSats, getSatName)
            };
        });
    }, [selectedSatellites, setSelectedSatellites, getSatName, setCurrentSatConfig]);

    const setSatTle = useCallback((sat, tle, sv, metadata, 
        {isCustom=false, index=-1, save=false, changeSat=true}={}
    ) => {
        let satToSet = sat;
        let newSats = null;

        if(!satToSet) satToSet = selectedSatellites[index];

        if(isCustom) {
            const satno = parseInt(tle.line2.split(" ")[1]);
            const needsChange = !isNaN(satno) && (satno+"") !== satToSet;
            if(needsChange) {
                if(changeSat) {
                    ({satToSet, newSats} = replaceWithCustomSatellite(satno, index, selectedSatellites, setSelectedSatellites));
                }
                else {
                    setPendingSat({
                        index: index,
                        current: {
                            id: satToSet,
                            name: getSatName(satToSet)
                        },
                        pending: {
                            id: satno,
                            name: getSatName(satno+"")
                        },
                    });
                }
            }
        }

        if(save)
            setCurrentSatConfig((current) => makeNewConfig(current, {satToSet, tle, sv, metadata, isCustom, save, newSats, getSatName}));
        else 
            setUnsavedSatConfig((current) => makeNewConfig(current, {satToSet, tle, sv, metadata, isCustom, save, newSats, getSatName}));
    }, [setUnsavedSatConfig, setCurrentSatConfig, selectedSatellites, getSatName]);

    const lastSats = useRef(JSON.stringify([]));
    const lastSource = useRef(currentSatConfig?.source);
    const lastRange = useRef(currentSatConfig?.range);
    const lastDataType = useRef(currentSatConfig?.dataType);
    const initialTlesDirty = useRef(false);
    useEffect(() => {
        const updateSatData = async (dirty) => {
            const newData = satData;
            // remove data for removed satellites
            Object.keys(newData)
                .filter((k) => !selectedSatellites.includes(k))
                .forEach((k) => delete newData[k]);

            // only load sats that we don't have data for, or reload all if source changed
            const stripped = selectedSatellites.map(stripDupeTag);
            const toLoad = Array.from(new Set(dirty ? stripped : stripped.filter((sat) => !newData[sat])));
            const results = await Promise.all(toLoad.map((sat) => fetchSatData(sat, currentSatConfig.dataType, getData, currentSatConfig.source, currentSatConfig.range)));
            toLoad.forEach((sat, i) => newData[sat] = results[i] ?? []);
            
            if(dirty) initialTlesDirty.current = true;

            setSatData({...newData});
        };

        const sourceChanged = lastSource.current !== currentSatConfig?.source;
        const rangeChanged = lastRange.current !== currentSatConfig?.range;
        const dataTypeChanged = lastDataType.current !== currentSatConfig?.dataType;
        const changed = sourceChanged || rangeChanged || dataTypeChanged;
        if(lastSats.current !== JSON.stringify(selectedSatellites) || changed) {
            updateSatData(changed);
            lastSats.current = JSON.stringify(selectedSatellites);
            lastSource.current = currentSatConfig.source;
            lastRange.current = currentSatConfig.range;
            lastDataType.current = currentSatConfig.dataType;
        }
    }, [selectedSatellites, satData, getData, currentSatConfig]);

    // Set initial TLE/SVs 
    useEffect(() => {
        currentSatConfig?.satellites?.forEach((satObj) => {
            const sat = satObj.id;
            const data = satData[stripDupeTag(sat)];
            if(!data) return; // no data

            const selected = currentSatConfig.options?.[sat];
            if(selected) {
                 // we already have a TLE for this
                 // we only need to reset the selected TLE if some query param in spacefana changed (initialTlesDirty)
                 // but not if it's a custom one, otherwise we overwrite the selection on param change
                if(!initialTlesDirty.current || selected.custom) return;
            }

            const newIndex = findSelectedRowIndex(data, selected);
            if(newIndex >= 0) return; // selected satisfies new data constraints too

            const tleSv = getDefaultTleRow(data);
            
            setCurrentSatConfig((current) => {
                const options = {...current.options};
                options[sat] = tleSv ? 
                    {tle: tleSv.tle, sv: tleSv.sv, metadata: tleSv.metadata} : 
                    {tle: emptyTLE, sv: null, metadata: null};
                return {
                    ...current,
                    options: options,
                }
            });
        });

        initialTlesDirty.current = false;
    }, [currentSatConfig, satData, setCurrentSatConfig]);

    const onHide = () => {
        setShowConfigureModal(false);
        setUnsavedSatConfig(currentSatConfig);
        setSelectedSatellites(configSatsToSelected(currentSatConfig));
    };
    
    const onSave = () => {
        setShowConfigureModal(false);
        setCurrentSatConfig((c) => ({
            ...unsavedSatConfig,
            satellites: selectedSatsToConfig(selectedSatellites, getSatName)
        }));
    };

    const params = useMemo(() => getSpacefanaParams(currentSatConfig), [currentSatConfig]);
    useSpacefanaParamsSetter(params);
    
    useSatConfigureMessageHandler(currentSatConfig, setShowConfigureModal, setCurrentSatConfig, setKeyToOpen, setSatTle);

    return (<>
            <Modal show={showConfigureModal && unsavedSatConfig} onHide={onHide} size="lg"
                className="customscrollbar"
                style={{
                    maxHeight: "calc(100vh - 28px - 28px)",
                    overflowY: "scroll"
                }}
            >
                <Modal.Header closeButton className="bg-dark text-white">
                    <Modal.Title>
                        Configure Dashboard Satellites
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-dark text-white">
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        <ModalSearchSection 
                            itemOptions={satellites} 
                            selectedItems={selectedSatellites} 
                            setSelectedItems={setSelectedSatellites} 
                            filterFunction={satFilterFunc}
                        />
                    </Form>

                    {selectedSatellites?.length > 0 && (
                        <>
                            Select or override TLEs:
                            <Accordion 
                                className="mb-3 settings-accordion"
                                activeKey={activeKeys}
                                onSelect={(key) => setActiveKeys(!activeKeys?.includes(key) ? [key] : [])}
                            >
                                {selectedSatellites.map((id, index) => {
                                    const name = getSatName(id);
                                    return <SatItem 
                                        index={index}
                                        key={id}
                                        name={name} 
                                        id={id}
                                        satData={satData}
                                        setSatTle={setSatTle}
                                        unsavedSatConfig={unsavedSatConfig}
                                    />
                                })}
                            </Accordion>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer className="bg-dark text-white">
                    <Button variant="secondary" onClick={onHide} data-testid="sat-configure-close">
                        Close
                    </Button>
                    <Button 
                        data-testid="sat-configure-save"
                        variant="primary"
                        onClick={onSave}
                        disabled={selectedSatellites.some((s) => !unsavedSatConfig?.options?.[s])}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            <SatChangeModal
                pendingSat={pendingSat}
                setPendingSat={setPendingSat}
                acceptPendingSat={acceptPendingSat}
            />
        </>
    );
};

export default SatConfigureModal;