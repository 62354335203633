import { useContext, useEffect } from "react";
import { checkMessageOrigin } from "../../util/Utils";
import { AppState } from "../../lib/context/AppProvider";

const handleParamsMessage = (e, currentSatConfig, setCurrentSatConfig, getSatName) => {
    if(!checkMessageOrigin(e)) return;
    const msg = e.data;

    const sats = msg.params.satellites.map((s) => {
        const split = s.split(" - ");
        const satno = split[0].trim();
        let name = split[1]?.trim();
        if(!name || name.length === 0)
            name = getSatName(satno) ?? "Unknown";
        return {
            id: satno,
            name: name
        };
    });
    if(currentSatConfig.satellites?.length !== sats.length || !currentSatConfig.satellites.every((sat, i) => sat.id === sats[i].id)) {
        setCurrentSatConfig((current) => ({
            ...current,
            satellites: sats
        }));
    }
    if(msg.params.source !== currentSatConfig.source) {
        setCurrentSatConfig((current) => ({
            ...current,
            source: msg.params.source
        }));
    }
    if(msg.params.from !== currentSatConfig.range?.[0] || msg.params.to !== currentSatConfig.range?.[1]) {
        setCurrentSatConfig((current) => ({
            ...current,
            range: [msg.params.from, msg.params.to]
        }));
    }
    if(msg.params.datatype && msg.params.datatype !== currentSatConfig.dataType) {
        setCurrentSatConfig((current) => ({
            ...current,
            dataType: msg.params.datatype
        }));
    }
}

const handleCustomTleMessage = (e, setSatTle) => {
    if(!checkMessageOrigin(e)) return;
    const msg = e.data;

    const index = msg.index;
    const line1 = msg.line1;
    const line2 = msg.line2;
    
    const metadata = {
        source: "Custom"
    };
    setSatTle(null, {line1, line2}, null, metadata, {isCustom: true, index, save: true, changeSat: msg.changeSat});
};

const handleOpenMessage = (e, setShowConfigureModal, setKeyToOpen) => {
    if(!checkMessageOrigin(e)) return;
    const msg = e.data;

    setShowConfigureModal(true);
    if(msg.sat) {
        setKeyToOpen(msg.sat.split(" - ")[0].trim());
    }
};

const useSatConfigureMessageHandler = (currentSatConfig, setShowConfigureModal, setCurrentSatConfig, setKeyToOpen, setSatTle) => {

    const {getSatName} = useContext(AppState);
    
    useEffect(() => {
        const handleMessage = (e) => {
            let msg = e.data;
            if(!msg) return;
            switch(msg.type) {
                case "sbms-sat-configure":
                    handleOpenMessage(e, setShowConfigureModal, setKeyToOpen);
                    break;
                case "spacefana-sat-params":
                    handleParamsMessage(e, currentSatConfig, setCurrentSatConfig, getSatName);
                    break;
                case "spacefana-sat-custom-tle":
                    handleCustomTleMessage(e, setSatTle);
                    break;
                default: 
                    break;
            }
        };

        // on mount
        window.addEventListener('message', handleMessage);

        return () => {
            // on unmount
            window.removeEventListener('message', handleMessage); 
        }
    }, [currentSatConfig, setShowConfigureModal, setCurrentSatConfig, setKeyToOpen, setSatTle, getSatName]);
};

export default useSatConfigureMessageHandler;