import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AppState } from './AppProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSCPositionSettings } from './LocalSettings';
import SatConfigureProvider from './SatConfigureProvider';

export const DashboardState = createContext();

export const getDashboards = (access_token) => {
    const isLocalDev = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
    const baseUrl = isLocalDev 
        ? "http://localhost:3000/" 
        : (window.location.origin.replace("sbms","spacefana")+"/");

    const accessTokenParam = access_token?.length > 0 ? `var-accesstoken=${access_token}&` : "";
    const createLink = (path, extraParams = '') => `${baseUrl}d/${path}?${extraParams}${accessTokenParam}&kiosk`;

    return [
        {
            name: "GEO Summary",
            link: createLink('5oK_KFK4z/geo-summary', 'refresh=5m&'),
        },
        {
            name: "RPO",
            link: createLink('be791bd0-9a37-45d7-b956-029966d4b570/rpo'),
        },
        {
            name: "Overview",
            link: createLink('eegfx7b7aeps0c/overview'),
        },
        {
            name: "GWAS",
            link: createLink('KPR_hDkVz/geo-waterfall', 'var-showSun=no&'),
        },
        {
            name: "Photometry",
            link: createLink('RCHUUtW4z/photometry'),
        },
        {
            name: "Photo Multiplot",
            link: createLink('ce5xt8v39mcxsd/photometry-multiplot'),
        },
        {
            name: "Elset History",
            link: createLink('PO81TfmVk/elset-history'),
        },
        {
            name: "Monet",
            link: createLink('ae9pzazusvkzke/monetplot'),
        },
        {
            name: "Satellite Threats",
            link: createLink('fdwfc04e39on4f/satellite-threats'),
        },
        {
            name: "Launch Threats",
            link: createLink('cdwujo30o8wsgd/asat-threats'),
        },
        {
            name: "Longitude Neighborhood",
            link: createLink('ce58nh4hgpypse/longitudeneighborhood'),
        },
        {
            name: "Residuals",
            link: createLink('FOBAMEzVz/residuals'),
        },
        {
            name: "TRIC",
            link: createLink('ZXiz9jkVk/tric'),
        },
        {
            name: "LEO Waterfall",
            link: createLink('aea48nghbugw0b/leo-waterfall'),
        },
        {
            name: "AltvInc",
            link: createLink('JcXBmud4z/altitudeinclination'),
        },
        {
            name: "GEO Map",
            link: createLink('WL1YXSK4k/geo-map'),
        },
        {
            name: "Data Status",
            link: createLink('ff16adf2-0eb2-4fb1-a602-380f95b82303/data-status'),
        },
        {
            name: "Sensor Map",
            link: createLink('D8fDCYO4k/sensor-map'),
        },
        {
            name: "Orbit Determination",
            link: createLink('f3a6255f-40d8-4b9e-8f6a-0c5bfbdc198d/orbit-determination'),
        },
        {
            name: "Space Weather",
            link: createLink('rC4UUfW4d/space-weather'),
        },
        {
            name: "Launch Profiles",
            link: createLink('aacbb349-7831-4ace-9e1b-91212b360232/launch-profiles'),
        },
        {
            name: "Constellation Monitor",
            link: createLink('fdm1ev7wgn9j4b/constellation-monitor'),
        },
        {
            name: "Maneuver History",
            link: createLink('feapme7gzwwlcd/maneuver-history'),
        },
        {
            name: "TRIC Manual",
            link: createLink('fa1eefd6-9ca5-438e-bd92-ec04db2365b1/tric-manual'),
        }
    ];
}

export const DASHBOARDS = getDashboards("").map(({name}) => name);

export const SpaceCockpitPositions = {
  "Maneuver History": {position: "bottom", size: 0.5},
  "RPO": {position: "right", size: 0.33},
  "Alerts": {position: "right", size: 0.5},
  "LEO Waterfall": {position: "right", size: 0.33},
}

const SatnoLimits = {
  "RPO": 1,
  "Photometry": 1,
  "Monet": 1, 
  "Residuals": 1, 
  "Orbit Determination": 1, 
  "Maneuver History": 1, 
  "TRIC": 2,
  "TRIC Manual": 2,
}
const SourceLimits = {
  "LEO Waterfall": 1,
}

export const encodeDashboardName = (name) => {
  return name.toLowerCase().replace(" ", "-").replace("/", "");
}

export const getDashboardIndex = (dashboards, name) => {
  const encoded = encodeDashboardName(name);
  return dashboards.findIndex(x => encodeDashboardName(x.name) === encoded);
}

const setProfileLinkParams = (url, selectedProfile, dashName) => {
  let sats = selectedProfile.satellites;
  const limit = SatnoLimits[dashName] ?? -1;
  if(limit > 0) {
    sats = sats.slice(0, limit);
  }
  
  if(sats?.length > 0) {
    if(limit > 0) {
      sats.forEach((sat, i) => {
        url.searchParams.set("var-satno" + (i === 0 ? "" : (i+1)), sat);
      });
    }
    else {
      url.searchParams.delete("var-satnos");
      sats.forEach((sat, i) => {
        url.searchParams.append("var-satnos", sat);
      });
    }
  }

  let sources = selectedProfile.sources;
  if(dashName in SourceLimits) {
    sources = sources.slice(0, SourceLimits[dashName]);
  }
  if(sources?.length > 0) {
    url.searchParams.set("var-source", sources.join(","));
  }
};

const getLink = (url, selectedProfile, dashName, currentQueryParams) => {
  if(currentQueryParams?.length > 0) {
    const params = new URLSearchParams(currentQueryParams);
    params.forEach((v, k) => {
      if(k !== "kiosk") {
        url.searchParams.append(k, v);
      }
    });
  }
  else if(selectedProfile && 
    (!selectedProfile.dashboards?.length || selectedProfile.dashboards?.includes(dashName))) {
  
    setProfileLinkParams(url, selectedProfile, dashName);
  }
  const urlStr = url.toString();
  return urlStr.replace(/(?<=[&?])kiosk=(?=(&|$))/g, "kiosk"); // url ends up like ?kiosk=&foo=bar when we need ?kiosk&foo=bar;
}

function DashboardProvider({ children }) {

  const { user, selectedProfile } = useContext(AppState);
  const dashboards = useMemo(() => getDashboards(user?.access_token), [user?.access_token]);
  
  // Find the index of GEO Summary for initial state
  const defaultDashIndex = dashboards.findIndex(d => d.name === "GEO Summary");
  const [dashIndex, setDashIndex] = useState(defaultDashIndex);

  const location = useLocation();
  const dash = location.pathname.split("/dashboards/")[1];

  if(dash) {
    const index = getDashboardIndex(dashboards, dash);
    if(index >= 0 && dashIndex !== index) {
      setDashIndex(index);
    }
  }

  const [currentQueryParams, setCurrentQueryParams] = useState(null);

  useEffect(() => {
    if(location.search.length > 0) {
      setCurrentQueryParams(location.search);
      window.history.replaceState(null, "Dashboard", location.pathname);
    }
  }, [location]);

  const lastProfile = useRef(selectedProfile);
  useEffect(() => {
      if(selectedProfile !== lastProfile.current) {
          setCurrentQueryParams(null); // clear query params if we change profiles
          lastProfile.current = selectedProfile;
      }
  }, [selectedProfile]);

  const dashboardName = useMemo(() => {
    return dashboards[dashIndex].name;
  }, [dashboards, dashIndex]);

  useEffect(() => {
    document.title = `SBMS - ${dashboardName} Dashboard`;
    return () => document.title = `SBMS - Space Cockpit Battle Management System`;
 }, [dashboardName]);

  const currentDashboardLink = useMemo(() => {
    const url = new URL(dashboards[dashIndex].link);
    return getLink(url, 
        selectedProfile, dashboards[dashIndex].name, 
        currentQueryParams);
  }, [dashboards, dashIndex, currentQueryParams, selectedProfile]);

  const {
    getSCPosition, setSCPosition
  } = useSCPositionSettings();

  const currentSpaceCockpitPosition = useMemo(() => getSCPosition(dashboardName), 
    [dashboardName, getSCPosition]);

  const setSpaceCockpitPosition = useCallback((position) => setSCPosition(dashboardName, position), 
    [dashboardName, setSCPosition]);

  const navigate = useNavigate();
  const goToDashboard = useCallback((index) => {
    setCurrentQueryParams(null);
    navigate("/dashboards/" + encodeDashboardName(dashboards[index].name));
  }, [dashboards, navigate]);

  const store = useMemo(() => ({
    dashIndex,
    goToDashboard,
    currentDashboardLink,
    dashboards,
    dashboardName,
    currentSpaceCockpitPosition, setSpaceCockpitPosition
  }), [dashIndex, dashboards, currentDashboardLink, goToDashboard, 
        dashboardName, currentSpaceCockpitPosition, setSpaceCockpitPosition]);
  
  return (  
    <DashboardState.Provider value={store}>
      <SatConfigureProvider 
        dashboardName={dashboardName}
        currentDashboardLink={currentDashboardLink}
      >
        { children }
      </SatConfigureProvider>
    </DashboardState.Provider>
  );
};

export default DashboardProvider;
