import React, { useState, useEffect, useContext } from "react";
import { AppState } from "../../lib/context/AppProvider";
import "../../styles/page.css";
import SettingsGroup, { renderSatelliteGroup } from "./SettingsGroup";
import SatGroupModal from "./SatGroupModal";
import { Accordion } from "react-bootstrap";


const constsToGroups = (constellations) => {
    if(!constellations) return {};
    const groups = {};
    // maintain backwards compatibility for when it was {name: satellites}
    Object.entries(constellations).forEach(([name, group]) => groups[name] = group.satellites ? group : {satellites: group});
    return groups;
}

function ConstellationSettingsGroup({satellites, userData}) {
    const { user, postData, userDetails, setUserDetailsDirty } = useContext(AppState);
    const [blueConstellations, setBlueConstellations] = useState({});
    const [redConstellations, setRedConstellations] = useState({});
    
    useEffect(() => {
        // Set constellations from user details
        if(!user.isGuest && userDetails?.Constellations) {
            setBlueConstellations(constsToGroups(userDetails.Constellations.blues));
            setRedConstellations(constsToGroups(userDetails.Constellations.reds));
        }
    }, [user, userDetails]);

    const saveConstellations = async (newBlues, newReds) => {
        try {
            const payload = {
                blues: newBlues,
                reds: newReds
            };

            await postData("user-constellations", payload, "PATCH");
            setUserDetailsDirty(true);
        } catch (error) {
            console.error("Failed to update constellations:", error);
            throw error;
        }
    };

    const updateConstellations = async (updatedConstellations, type) => {
        let newBlues = blueConstellations;
        let newReds = redConstellations;

        if (type === "blue") {
            setBlueConstellations(updatedConstellations);
            newBlues = updatedConstellations;
        } else {
            setRedConstellations(updatedConstellations);
            newReds = updatedConstellations;
        }

        await saveConstellations(newBlues, newReds);
    }

    const renderConstellationGroup = (editButtonVariant) => 
        (name, group, handlers) => 
            renderSatelliteGroup(name, group, handlers, satellites, editButtonVariant);

    const accordionBodyClass = "p-0 mt-3 ps-4 pe-4 text-white";

    return <>
        <Accordion 
            className="mb-3 settings-accordion"
        >
            <Accordion.Item eventKey="blue-team" className="bg-dark border-0">
                <Accordion.Header className="settings-accordion-header">
                    <h4 className="settings-section-title m-0">
                        <span className="text-primary">Blue</span>
                    </h4>
                </Accordion.Header>
                <Accordion.Body className={accordionBodyClass} style={{maxHeight: "50vh"}}>
                    <SettingsGroup 
                        groupType="constellation"
                        title={null}
                        description={"Satellites on the blue team will be monitored by Windu for threats against them."}
                        testid="blue-constellations"
                        typeText={"Blue Constellation"}
                        buttonVariant="primary"
                        enabled={!user.isGuest}
                        satellites={satellites}
                        groups={blueConstellations}
                        setGroups={(newGroups) => setBlueConstellations(newGroups)}
                        saveGroups={(newGroups) => updateConstellations(newGroups, "blue")}
                        modalType={SatGroupModal}
                        modalProps={{satellites: satellites}}
                        renderGroup={renderConstellationGroup("outline-primary")}
                    />
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="red-team" className="bg-dark border-0 mt-3">
                <Accordion.Header className="settings-accordion-header">
                    <h4 className="settings-section-title m-0">
                        <span className="text-danger">Red</span>
                    </h4>
                </Accordion.Header>
                <Accordion.Body className={accordionBodyClass} style={{maxHeight: "50vh"}}>
                    <SettingsGroup 
                        groupType="constellation"
                        title={null}
                        description={"Satellites on the red team will be monitored by Windu for maneuvers, photometry, and other pattern of life changes."}
                        testid="red-constellations"
                        typeText={"Red Constellation"}
                        buttonVariant="danger"
                        enabled={!user.isGuest}
                        satellites={satellites}
                        groups={redConstellations}
                        setGroups={(newGroups) => setRedConstellations(newGroups)}
                        saveGroups={(newGroups) => updateConstellations(newGroups, "red")}
                        modalType={SatGroupModal}
                        modalProps={{satellites: satellites}}
                        renderGroup={renderConstellationGroup("outline-danger")}
                    />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </>;
}

export default ConstellationSettingsGroup;
