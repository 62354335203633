// src/components/DashboardMenu.js
import React, { useContext, useEffect, useState } from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import PropTypes from "prop-types";
import { DashboardState, encodeDashboardName, getDashboardIndex } from "../lib/context/DashboardProvider";
import { AppState } from "../lib/context/AppProvider";

// Define dashboard groups
const DASHBOARD_GROUPS = {
    Summaries: ['GEO Summary', 'Constellation Monitor',],
    RPO: ['RPO', 'TRIC', 'TRIC Manual'],
    Analysis: [
        'Overview',
        'GWAS',
        'Photometry',
        'Photo Multiplot',
        'Elset History',
        'LEO Waterfall',
        'Monet',
        'Residuals',
        'Longitude Neighborhood',
        'Orbit Determination',
        'Maneuver History'
    ],
    Launches: ['Launch Threats', 'Launch Profiles', 'Satellite Threats'],
    Data: [
        'Sensor Map', 
        'Data Status',
    ],
    Maps: [
        'GEO Map',
        'AltvInc'
    ],
    Environment: [
        'Space Weather',
    ]
};

// Define which dashboards require Sam app installation
const DASHBOARD_TO_APP_MAP = {
    'Maneuver History': 'Maneuver History',
    // Add more mappings as needed
};

const getDashboardGroup = (dashboardName) => {
    for (const [group, items] of Object.entries(DASHBOARD_GROUPS)) {
        if (items.includes(dashboardName)) {
            return group;
        }
    }
    return 'Environment';
};

const DashboardMenu = ({ maxButtons }) => {
    const { dashIndex, goToDashboard, dashboards } = useContext(DashboardState);
    const { getData } = useContext(AppState);
    const [openDropdown, setOpenDropdown] = useState(null);
    const [installedApps, setInstalledApps] = useState([]);

    // Fetch installed apps on component mount
    useEffect(() => {
        const fetchInstalledApps = async () => {
            try {
                const [apps, userApps] = await Promise.all([
                    getData('apps'),
                    getData('user-apps')
                ]);

                if (!apps || !userApps) {
                    console.log('No apps or userApps data received');
                    return;
                }

                const installed = apps
                    .filter(app => {
                        const userApp = userApps.find(ua => ua.AppId === app.Id);
                        return userApp && userApp.Installed === 1;  // Explicitly check for 1
                    })
                    .map(app => app.Title);

                console.log('Installed apps:', installed);
                setInstalledApps(installed);
            } catch (error) {
                console.error('Error fetching installed apps:', error);
            }
        };

        fetchInstalledApps();
    }, [getData]);

    useEffect(() => {
        const handleGlobalClick = () => {
            if (openDropdown) {
                setOpenDropdown(null);
            }
        };

        window.addEventListener('click', handleGlobalClick);
        window.addEventListener('blur', handleGlobalClick);

        return () => {
            window.removeEventListener('click', handleGlobalClick);
            window.removeEventListener('blur', handleGlobalClick);
        };
    }, [openDropdown]);

    const isDashboardAvailable = (dashboard) => {
        const requiredApp = DASHBOARD_TO_APP_MAP[dashboard.name];
        if (!requiredApp) return true; // If no app is required, dashboard is always available
        return installedApps.includes(requiredApp);
    };

    // Filter dashboards based on installation status
    const availableDashboards = dashboards.filter(isDashboardAvailable);

    // Group available dashboards (use availableDashboards instead of dashboards)
    const groupedDashboards = availableDashboards.reduce((acc, dashboard) => {
        const group = getDashboardGroup(dashboard.name);
        if (!acc[group]) {
            acc[group] = [];
        }
        acc[group].push(dashboard);
        return acc;
    }, {});

    // Remove empty groups
    Object.keys(groupedDashboards).forEach(key => {
        if (groupedDashboards[key].length === 0) {
            delete groupedDashboards[key];
        }
    });

    const openLink = (e) => {
        const dash = dashboards[getDashboardIndex(dashboards, e.target.textContent)];
        if (!dash || !dash.name) return;

        const url = new URL(window.location.origin + "/dashboards/" + encodeDashboardName(dash.name));
        window.open(url.toString(), '_blank', 'noopener,noreferrer')?.focus();
    };

    const setDbId = (e) => {
        // use all dashboards, not availableDashboards, since index is based on all dashboards
        const newId = getDashboardIndex(dashboards, e.target.textContent);
        goToDashboard(newId);
    };

    const handleDropdownClick = (e, group) => {
        e.stopPropagation();
        setOpenDropdown(openDropdown === group ? null : group);
    };

    // Sort groups to ensure consistent order
    const sortedGroups = Object.keys(groupedDashboards).sort((a, b) => {
        const order = Object.keys(DASHBOARD_GROUPS);
        return order.indexOf(a) - order.indexOf(b);
    });

    return (
        <ButtonGroup onClick={e => e.stopPropagation()}>
            {sortedGroups.map((group) => (
                <DropdownButton
                    key={`group-${group}`}
                    data-bs-theme="dark"
                    as={ButtonGroup}
                    title={group}
                    id={`dropdown-${group.toLowerCase()}`}
                    variant={groupedDashboards[group].some(d => 
                        availableDashboards.findIndex(dash => dash.name === d.name) === dashIndex
                    ) ? "light" : "dark"}
                    show={openDropdown === group}
                    onClick={(e) => handleDropdownClick(e, group)}
                >
                    {groupedDashboards[group].map((dashboard) => (
                        <Dropdown.Item
                            key={`dropdown-${dashboard.name.toLowerCase().replace(/\s+/g, '-')}`}
                            eventKey={dashboard.name}
                            onClick={(e) => {
                                e.stopPropagation();
                                setDbId(e);
                                setOpenDropdown(null);
                            }}
                            onContextMenu={(e) => {
                                e.stopPropagation();
                                openLink(e);
                            }}
                            active={availableDashboards.findIndex(d => d.name === dashboard.name) === dashIndex}
                            style={{
                                backgroundColor: availableDashboards.findIndex(d => d.name === dashboard.name) === dashIndex ? '#ffc107' : undefined,
                                color: availableDashboards.findIndex(d => d.name === dashboard.name) === dashIndex ? 'black' : undefined
                            }}
                            className={`${availableDashboards.findIndex(d => d.name === dashboard.name) === dashIndex ? 'active-yellow' : ''}`}
                            role="button"
                            as="button"
                            tabIndex={0}
                        >
                            {dashboard.name}
                        </Dropdown.Item>
                    ))}
                </DropdownButton>
            ))}
            <style>
                {`
                .dropdown-item.active-yellow:hover,
                .dropdown-item.active-yellow:focus {
                    background-color: #fec60a !important;
                    color: black !important;
                }
                `}
            </style>
        </ButtonGroup>
    );
};

DashboardMenu.propTypes = {
    maxButtons: PropTypes.number.isRequired,
};

export default DashboardMenu;