import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Header from "../components/Header";
import SpaceCockpitFrame from '../components/SpaceCockpitFrame';
import { useLocation } from 'react-router-dom';
import { AppState } from '../lib/context/AppProvider';

function SpaceCockpit() {
    const location = useLocation();
    const [query, setQuery] = useState(location.search?.length > 0 ? location.search : null);
    const {selectedProfile} = useContext(AppState);

    const [scScene, setSCSceneRaw] = useState({time: new Date().toISOString()});
    const setSCScene = useCallback((scene) => {
        setQuery(null);
        setSCSceneRaw(scene);
    }, [setQuery, setSCSceneRaw]);
    
    const lastProfile = useRef(selectedProfile);
    useEffect(() => {
        if(selectedProfile !== lastProfile.current) {
            setQuery(null);
            lastProfile.current = selectedProfile;

            setSCScene({
                sats: selectedProfile?.satellites,
                focus: selectedProfile?.satellites?.[0] ?? null,
            });
        }
    }, [selectedProfile, setSCScene]);

    return (  
        <div data-testid="home-container" id="home-container">
            <Header
                showSideBarMenu={true}
                showDashboardMenu={false}
                showProfiles={true}
                height={56} 
                className="pb-5"
                isSpaceCockpit={true}
            />
            <SpaceCockpitFrame 
            containerStyle={{
                width: '100%',
                height: 'calc(100vh - 56px - 16px)',
            }} 
            time={query ? null : scScene?.time}
            sats={query ? null : scScene?.sats}
            focus={query ? null : scScene?.focus}
            initialQueryParams={query}
            />
        </div>
    );
};

export default SpaceCockpit;
