import { Button, Modal } from "react-bootstrap";

const getName = (sat) => {
    return sat.name ? `${sat.id} - ${sat.name}` : sat.id;
};

const SatChangeModal = ({pendingSat, setPendingSat, acceptPendingSat}) => {
    const onHide = () => setPendingSat(null);

    const onSave = () => {
        acceptPendingSat(pendingSat);
        setPendingSat(null);
    };

    return (
        <Modal show={pendingSat} onHide={onHide} size="md">
            <Modal.Header closeButton className="bg-dark text-white">
                <Modal.Title>
                    Update Dashboard Satellite?
                </Modal.Title>
            </Modal.Header>
            {pendingSat && <Modal.Body className="bg-dark text-white" style={{textAlign: "center"}}>
                The pasted TLE references a different satellite
                <br/><b><center>{getName(pendingSat.pending)}</center></b>
                than the currently selected dashboard satellite
                <br/><b><center>{getName(pendingSat.current)}</center></b>
                <br />
                Would you like to update the dashboard to match the TLE?
            </Modal.Body>}
            <Modal.Footer className="bg-dark text-white">
                <Button variant="secondary" onClick={onHide} data-testid="sat-change-close">
                    No
                </Button>
                <Button 
                    data-testid="sat-change-accept"
                    variant="primary"
                    onClick={onSave}
                >
                    Change
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SatChangeModal;