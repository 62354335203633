import React, { useMemo } from 'react';
import { getDomainUrl } from '../../util/Utils';

const zoom = 0.5;

const buildWeatherContainerStyle = (maxHeight) => ({
    gridColumn: '1 / -1',
    backgroundColor: '#1a1d20',
    borderRadius: '8px',
    maxHeight: `calc(${maxHeight})`,
    transition: "max-height 0.25s linear"
});

const buildIFrameStyle = (maxHeight) => {
    const scaleHeight = maxHeight + '/' + zoom;
    return {
        border: 'none',
        borderRadius: '4px',
        backgroundColor: '#212529',
        width: (100/zoom) + '%',
        height: 'calc(' + scaleHeight + ')',
        transform: 'scale(' + zoom + ')',
        transformOrigin: '0 0',
        padding: '1rem',
    };
};

const SpaceWeather = ({ user, userDetails, maxHeight }) => {
    const weatherContainerStyle = useMemo(() => buildWeatherContainerStyle(maxHeight), [maxHeight]);
    const iframeStyle = useMemo(() => buildIFrameStyle(maxHeight), [maxHeight]);
    
    const getSpaceWeatherUrl = () => {
        const baseUrl = getDomainUrl('spacefana');
        const accessToken = userDetails?.AccessToken || '';
        return baseUrl + '/d/rC4UUfW4d/space-weather?var-accesstoken=' + accessToken + '&kiosk';
    };
    
    return (
        <div style={weatherContainerStyle} data-testid="space-weather-container">
            {user.isGuest ? (
                <div className="d-flex align-items-center justify-content-center h-100">
                    <div className="text-center">
                        <h5 className="text-white-50 mb-3">Space Weather Preview</h5>
                        <p className="text-white-50">Login to view real-time space weather data</p>
                    </div>
                </div>
            ) : (
                <iframe
                    src={getSpaceWeatherUrl()}
                    style={iframeStyle}
                    title="Space Weather"
                />
            )}
        </div>
    );
};

export default SpaceWeather; 