import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const RefreshNotificationModal = ({ show, onHide }) => (
    <Modal
        show={show}
        onHide={onHide}
        centered
        backdrop="static"
        style={{ color: 'white' }}
    >
        <Modal.Header closeButton style={{ backgroundColor: '#2a2a2a', borderBottom: '1px solid #3a3a3a' }}>
            <Modal.Title>Refresh Required</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: '#2a2a2a' }}>
            Please refresh any open instances of Space Cockpit to see the latest changes.
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: '#2a2a2a', borderTop: '1px solid #3a3a3a' }}>
            <Button variant="warning" onClick={onHide}>
                Got it
            </Button>
        </Modal.Footer>
    </Modal>
);

export default RefreshNotificationModal; 