import { createContext, useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useLocalSettings } from './LocalSettings';

export const getType = (eventClass) => {
    if (!eventClass) return "unknown";
    
    const eventClassLower = eventClass.toLowerCase();
    
    // Handle alert types from UniqueId
    if (eventClassLower.includes("period")) {
        return "maneuver";
    }
    if (eventClassLower.includes("rpo")) {
        return "rpo";
    }
    if (eventClassLower.includes("maneuver") || eventClassLower.includes("manoeuvre")) {
        return "maneuver";
    }
    if (eventClassLower.includes("launch")) {
        return "launch";
    }
    if (eventClassLower.includes("photometry") || eventClassLower.includes("photometric")) {
        return "photometric";
    }
    if (eventClassLower.includes("breakup")) {
        return "breakup";
    }
    
    return "unknown";
};

export const getCardColor = (type) => {
    if (!type) return 'secondary';
    
    const typeLower = type.toLowerCase();
    if (typeLower.includes('rpo')) return 'danger';
    if (typeLower.includes('maneuver') || typeLower.includes('manoeuvre')) return 'warning';
    if (typeLower.includes('photometry') || typeLower.includes('photometric')) return 'success';
    return 'secondary';
};

export const AppState = createContext({});

const getBaseUrl = (isCAC = false) => {
    // Get the current domain and determine the base URL
    const currentDomain = window.location.hostname;
    
    // Use environment variable if available
    if (process.env.REACT_APP_API_URL) {
        return process.env.REACT_APP_API_URL;
    }
    
    // Fallback to default logic
    if(currentDomain === 'localhost') return 'http://localhost:9001';
    else if (false === currentDomain.includes("sbms.")) // high side deployments use paths not subdomains
        return `https://${currentDomain}/nps`;
    else if(isCAC && currentDomain.includes("saberastro.com"))
        return `https://${currentDomain.replace('sbms.', 'nps.')}:444`; // CAC logins on saberastro.com only
    else
        return `https://${currentDomain.replace('sbms.', 'nps.')}`;
};

const getData = async (path, customHeaders = {}) => {
    try {
        const headers = {
            "Content-Type": "application/json",
            // Access token is automatically added from httpOnly cookie
            ...customHeaders
        };
    
        if (process.env.REACT_APP_API_KEY) {
            headers['api_key'] = process.env.REACT_APP_API_KEY;
        }

        const response = await fetch(`${getBaseUrl()}/${path}`, {
            method: 'GET',
            credentials: 'include',
            headers: headers,
        });

        if (!response.ok) {
            // For guest users, return empty data for certain endpoints
            if (response.status === 401 && path === 'apps') {
                return [];
            }
            throw new Error(`Failed to fetch ${path}: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('API Error:', error);
        let errorMessage = 'An error occurred while fetching data. ';
        
        if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
            errorMessage += 'Unable to connect to the server. Please check your internet connection and try again.';
        } else {
            errorMessage += error.message;
        }

        // Don't show error toast for guest users accessing apps endpoint
        if (!(error.message.includes('401') && path === 'apps')) {
            toast.error(errorMessage, {
                autoClose: 5000
            });
        }
        return null;
    }
};

const postLogin = async (username, password) => {
    if(!username || !password) return;
    return await fetch(`${getBaseUrl()}/login`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            "Content-Type": "application/json",
            "username": username,
            "password": password,
          },
    });
}

const postData = async (endpoint, data, method = "POST") => {
    try {
        const response = await fetch(`${getBaseUrl()}/${endpoint}`, {
            method: method,
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });

        if (!response.ok && response.status !== 200) {
            throw new Error(`Failed to ${method.toLowerCase()} ${endpoint}: ${response.statusText}`);
        }

        return response;
    } catch (error) {
        console.error('API Error:', error);
        let errorMessage = 'An error occurred while processing your request. ';
        
        if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
            errorMessage += 'Unable to connect to the server. Please check your internet connection and try again.';
        } else {
            errorMessage += error.message;
        }

        toast.error(errorMessage, {
            autoClose: 5000
        });
        return null;
    }
};

const fetchUserDetails = async (user, getData, setUserDetails) => {
    if (!user.loggedIn || user.isGuest) return;
    
    const details = await getData("user-details");
    setUserDetails(details);
};

const fetchOtherData = async (user, getData, setSatellites) => {
    if (!user.loggedIn) return;
    const satData = await getData("satellites");

    if (!satData || !Array.isArray(satData)) {
        console.error("Invalid satellite data received:", satData);
    }
    else {
        const formattedSats = satData.filter((s) => s).map(sat => {
            // If the data is already in the correct format, return it as is
            if (typeof sat === 'object' && sat.id && sat.name) {
                return sat;
            }
            // Otherwise, parse the string format
            const [id, name] = sat.split(" - ");
            return { id: id.trim(), name: name.trim() };
        });

        setSatellites(formattedSats);
    }
}

const patchData = async (endpoint, customHeaders = {}) => {
    try {
        const headers = {
            "Content-Type": "application/json",
            // Access token is automatically added from httpOnly cookie
            ...customHeaders
        };

        if (process.env.REACT_APP_API_KEY) {
            headers['api_key'] = process.env.REACT_APP_API_KEY;
        }

        const response = await fetch(`${getBaseUrl()}/${endpoint}`, {
            method: 'PATCH',
            credentials: 'include',
            headers: headers
        });

        if (!response.ok) {
            throw new Error(`Failed to patch ${endpoint}: ${response.statusText}`);
        }

        return response;
    } catch (error) {
        console.error('API Error:', error);
        let errorMessage = 'An error occurred while processing your request. ';
        
        if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
            errorMessage += 'Unable to connect to the server. Please check your internet connection and try again.';
        } else {
            errorMessage += error.message;
        }

        toast.error(errorMessage, {
            autoClose: 5000
        });
        throw error;
    }
};

export const AppProvider = ({ children }) => {

    const [user, setUser] = useState(() => {
        return {
            loggedIn: false,
            uuid: null,
            access_token: null,
            providers: [],
            isGuest: false,
            lastLoggedIn: -1
        };
    });

    const [scQueryParams, setSCQueryParams] = useState(null);
    
    const pendingRedirect = useRef(null);
    
    const isLocalDev = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
   
    const {
        profiles, setProfiles, selectedProfile, setSelectedProfile
    } = useLocalSettings();

    const [userDetails, setUserDetails] = useState(null);
    const [userDetailsDirty, setUserDetailsDirty] = useState(false);

    const [satellites, setSatellites] = useState(null);
    const getSatName = useCallback((id) => {
        const lower = (id+"").toLowerCase();
        return satellites?.find((s) => (s.id+"").toLowerCase() === lower)?.name;
    }, [satellites]);

    useEffect(() => {
        fetchUserDetails(user, getData, setUserDetails);
        fetchOtherData(user, getData, setSatellites);
    }, [user]);

    useEffect(() => {
        if(userDetailsDirty) {
            fetchUserDetails(user, getData, setUserDetails);
            setUserDetailsDirty(false);
        }
    }, [userDetailsDirty, user]);

    const store = useMemo(() => ({
        user,
        setUser,
        pendingRedirect,
        isLocalDev,
        getBaseUrl,
        getData,
        postLogin,
        postData,
        patchData,
        scQueryParams, setSCQueryParams,
        profiles, setProfiles, selectedProfile, setSelectedProfile,
        userDetails, setUserDetails,
        userDetailsDirty, setUserDetailsDirty,
        satellites, getSatName
    }), [
        user, isLocalDev, pendingRedirect, 
        scQueryParams, setSCQueryParams, 
        profiles, setProfiles, selectedProfile, setSelectedProfile,
        userDetails, setUserDetails,
        userDetailsDirty, setUserDetailsDirty,
        satellites, getSatName
    ]);

    return (
        <AppState.Provider value={store}>      
            {children}
        </AppState.Provider>
    );
};