import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Container, Row, Col, Badge, Table, Button, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { toast } from "react-toastify";

import { AppState } from '../lib/context/AppProvider';
import Header from '../components/Header';
import { getDomainUrl } from "../util/Utils";
import SpaceCockpitDockContainer from '../components/SpaceCockpitDockContainer';
import winduImage from '../lib/assets/img/windu.png';
import SatConfigureProvider, { SatConfigureDefaults } from '../lib/context/SatConfigureProvider';
import SatConfigureModal from '../components/configure/SatConfigureModal';

const TabsComponent = ({ tabs, hasDetails }) => {
    const [activeTab, setActiveTab] = useState(tabs[0].id);

    const tabStyle = {
        padding: '0.5rem 1rem',
        backgroundColor: '#1a1d20',
        border: 'none',
        color: 'white',
        cursor: 'pointer',
        borderRadius: '4px 4px 0 0',
        marginRight: '0.5rem',
        opacity: 0.7,
        transition: 'opacity 0.2s'
    };

    const activeTabStyle = {
        ...tabStyle,
        opacity: 1,
        backgroundColor: '#2a2d30'
    };

    const tabWrapperStyle = {
        flex: 1,
        backgroundColor: '#2a2d30',
        borderRadius: '0 4px 4px 4px',
        padding: '0.5rem',
        overflow: 'hidden',
        position: 'relative',
        minHeight: '400px'
    };

    const tabContentStyle = {
        top: '0.5rem',
        left: '0.5rem',
        right: '0.5rem',
        bottom: '0.5rem',
        height: '100%',
        visibility: 'visible',
        opacity: 1,
        transition: 'opacity 0.2s'
    };

    return (
        <div style={{ 
            height: "100%",
            display: 'flex', 
            flexDirection: 'column',
        }}>
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex" role="tablist">
                    {tabs.map(tab => (
                        <button 
                            key={tab.id}
                            style={activeTab === tab.id ? activeTabStyle : tabStyle}
                            onClick={() => setActiveTab(tab.id)}
                            role="tab"
                            aria-selected={activeTab === tab.id}
                            aria-controls={`panel-${tab.id}`}
                            id={`tab-${tab.id}`}
                        >
                            {tab.label}
                        </button>
                    ))}
                </div>
            </div>
            <div style={tabWrapperStyle}>
                {tabs.map(tab => (
                    activeTab === tab.id && (
                        <div 
                            key={tab.id} 
                            style={tabContentStyle}
                            role="tabpanel"
                            aria-labelledby={`tab-${tab.id}`}
                            id={`panel-${tab.id}`}
                        >
                            {tab.element}
                        </div>
                    )
                ))}
            </div>
        </div>
    );
};

TabsComponent.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        element: PropTypes.node.isRequired
    })).isRequired,
    hasDetails: PropTypes.bool
};

const zoom = 0.6;

const iframeStyle = {
    width: `${100/zoom}%`,
    height: `${100/zoom}%`,
    transform: `scale(${zoom})`,
    transformOrigin: '0 0',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: '#1a1d20'
};

// Move DashboardIframe component outside
const DashboardIframe = ({ src, title, ariaLabel }) => (
    <div style={{ height: '100%' }}>
        <iframe
            src={src}
            style={iframeStyle}
            title={title}
            aria-label={ariaLabel}
        />
    </div>
);

// Move NotsoContent component outside
const NotsoContent = ({ notso, onCopy }) => (
    <div style={{ height: '100%', padding: '1rem' }}>
        <div className="d-flex justify-content-end mb-2">
            <Button
                variant="outline-light"
                size="sm"
                onClick={onCopy}
                className="d-flex align-items-center gap-2"
            >
                <i className="bi bi-clipboard"></i>
                Copy NOTSO
            </Button>
        </div>
        <pre style={{ 
            whiteSpace: 'pre-wrap', 
            wordWrap: 'break-word',
            color: 'white',
            backgroundColor: '#1a1d20',
            padding: '1rem',
            borderRadius: '4px',
            height: 'calc(100% - 40px)',
            overflowY: 'auto',
            margin: 0
        }}>
            {notso}
        </pre>
    </div>
);

const RpoFrame = ({satno, timerange, userDetails}) => {
    const {getSatName} = useContext(AppState);
    return (<>
        <SatConfigureProvider satConfigDefault={{
            ...SatConfigureDefaults["RPO"],
            satellites: [
                {id: satno, name: getSatName(satno)}
            ]
        }}>
            <SatConfigureModal />
        </SatConfigureProvider>
        <div style={{ height: '100%' }}>
            <iframe
                id="spacefana-frame"
                src={`${getDomainUrl('spacefana')}/d/be791bd0-9a37-45d7-b956-029966d4b570/rpo?var-satno=${satno}&var-accesstoken=${userDetails.AccessToken}&from=${timerange.from}&to=${timerange.to}&kiosk`}
                style={iframeStyle}
                title="RPO Dashboard"
                aria-label="RPO Dashboard"
            />
        </div>
    </>
    );
};

function Alert() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { getData, patchData } = useContext(AppState);
    const [alert, setAlert] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const [showAcknowledgeModal, setShowAcknowledgeModal] = useState(false);
    const [acknowledging, setAcknowledging] = useState(false);

    const [currentSpaceCockpitPosition, setSpaceCockpitPositionRaw] = useState({
        position: "right", 
        size: 0.33
    });

    const setSpaceCockpitPosition = (pos) => setSpaceCockpitPositionRaw((c) => ({
        position: pos, 
        size: c.size
    }));

    const [selectedEventIndex, setSelectedEventIndex] = useState(0);

    useEffect(() => {
        const fetchUserDetails = async () => {
            const details = await getData("user-details");
            setUserDetails(details);
        };
        fetchUserDetails();
    }, [getData]);

    useEffect(() => {
        const fetchAlert = async () => {
            try {
                setLoading(true);
                const now = new Date();
                const sevenDaysAgo = new Date(now);
                sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

                const alertData = await getData('alerts', {
                    unique_id: id,
                    start_timestamp: sevenDaysAgo.getTime(),
                    end_timestamp: now.getTime()
                });
                setAlert(Array.isArray(alertData) ? alertData[0] : alertData);
            } catch (err) {
                console.error('Error fetching alert:', err);
                setError('Failed to fetch alert details');
            } finally {
                setLoading(false);
            }
        };

        fetchAlert();
    }, [getData, id]);

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleString('en-GB', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        });
    };

    // Extract common timerange calculation
    const getTimerange = (eventTime) => {
        const time = new Date(eventTime).getTime();
        const dayInMs = 24 * 60 * 60 * 1000;
        return {
            from: time - (7 * dayInMs),
            to: time + (7 * dayInMs)
        };
    };

    // Extract common dashboard render function
    const renderDashboard = (dashboardId, params, title) => {
        if (!userDetails?.AccessToken || !alert?.Data) return null;

        const timerange = getTimerange(alert.Data.eventTime);
        const baseUrl = `${getDomainUrl('spacefana')}/d/${dashboardId}`;
        const queryParams = new URLSearchParams({
            'var-accesstoken': userDetails.AccessToken,
            from: timerange.from,
            to: timerange.to,
            kiosk: '',
            ...params
        });

        return (
            <DashboardIframe
                src={`${baseUrl}?${queryParams.toString()}`}
                title={title}
                ariaLabel={title}
            />
        );
    };

    const renderElsetHistory = () => {
        if (!userDetails?.AccessToken || !alert?.Data) return null;

        const timerange = getTimerange(alert.Data.eventTime);
        const sources = alert.Data.sources.join(',');
        
        return (
            <DashboardIframe
                src={`${getDomainUrl('spacefana')}/d/PO81TfmVk/elsethistory?var-satnos=${alert.Data.satnos[0]}&var-stdDev=0&var-showSourceColor=No&var-source=${sources}&var-accesstoken=${userDetails.AccessToken}&from=${timerange.from}&to=${timerange.to}&kiosk`}
                title="ELSET History Dashboard"
                ariaLabel="ELSET History Dashboard"
            />
        );
    };

    const renderRPO = () => {
        if (!userDetails?.AccessToken || !alert?.Data) return null;

        const timerange = getTimerange(alert.Data.eventTime);
        return (
            <RpoFrame 
                satno={alert.Data.satnos[0]+""}
                timerange={timerange}
                userDetails={userDetails}
            />
        );
    };

    const renderPair = () => {
        if (!alert?.Data || !userDetails?.AccessToken) return null;

        return (
            <DashboardIframe
                src={`${getDomainUrl('probe')}/dossier/${alert.Data.satnos[0]}?accesstoken=${userDetails.AccessToken}`}
                title="PAIR Dossier"
                ariaLabel="PAIR Dossier"
            />
        );
    };

    const renderPhotometry = () => renderDashboard(
        'RCHUUtW4z/photometry',
        { 'var-satno': alert.Data.satnos[0] },
        'Photometry Dashboard'
    );

    const renderOverview = () => renderDashboard(
        'eegfx7b7aeps0c/overview',
        { 'var-satnos': alert.Data.satnos.join(',') },
        'Overview Dashboard'
    );

    const renderGeoWaterfall = () => renderDashboard(
        'KPR_hDkVz/geo-waterfall',
        { 'var-satnos': alert.Data.satnos.join(',') },
        'GEO Waterfall Dashboard'
    );

    const renderTabs = () => {
        if (!alert || !userDetails) return null;

        // Get all unique satellite numbers (primary and secondary)
        const allSatNos = new Set([
            ...alert.Data.satnos,
            ...alert.Data.events.flatMap(event => 
                event.secondaries?.map(secondary => secondary.satNo) || []
            ),
            // Add primary satellite number from the first event
            ...(alert.Data.events[0]?.primary?.satNo ? [alert.Data.events[0].primary.satNo] : [])
        ]);

        const handleCopyNotso = () => {
            const toastId = toast.loading('Copying NOTSO text...');
            navigator.clipboard.writeText(alert.Data.notso)
                .then(() => {
                    toast.update(toastId, { 
                        render: 'NOTSO text copied to clipboard!', 
                        type: 'success',
                        isLoading: false,
                        autoClose: 3000
                    });
                })
                .catch((error) => {
                    toast.update(toastId, { 
                        render: 'Failed to copy NOTSO text', 
                        type: 'error',
                        isLoading: false,
                        autoClose: 3000
                    });
                    console.error('Copy failed:', error);
                });
        };

        // Define the order of products and their configurations
        const productConfig = [
            { id: 'overview', label: 'Overview', product: 'Overview', element: renderOverview() },
            { id: 'photometry', label: 'Photometry', product: 'Photometry', element: renderPhotometry() },
            { id: 'elset', label: 'ELSET History', product: 'Elset History', element: renderElsetHistory() },
            { id: 'geowaterfall', label: 'GEO Waterfall', product: 'GEO Waterfall', element: renderGeoWaterfall() },
            { 
                id: 'notso', 
                label: 'NOTSO', 
                product: 'NOTSO', 
                element: <NotsoContent notso={alert.Data.notso} onCopy={handleCopyNotso} />
            },
            { id: 'rpo', label: 'RPO', product: 'RPO', element: renderRPO() },
            { id: 'pair', label: 'PAIR', product: 'PAIR', element: renderPair() }
        ];

        // Create tabs in the specified order
        const availableTabs = productConfig
            .filter(config => alert.Data.products.includes(config.product))
            .map(({ id, label, element }) => ({ id, label, element }));

        if (availableTabs.length === 0) return null;

        return (
            <div style={{minHeight: "0px", flex:"1"}}>
                <SpaceCockpitDockContainer 
                    id="alerts"
                    currentSpaceCockpitPosition={currentSpaceCockpitPosition}
                    setSpaceCockpitPosition={setSpaceCockpitPosition}
                    sats={Array.from(allSatNos)}
                    pois={[alert.Data.events.map((event) => ({
                        satno: event.primary.satNo,
                        Name: `Maneuver Detected - ${event.primary.source} - ${formatDate(alert.Data.events[selectedEventIndex].detectedAt)}`,
                        Description: event.description,
                        Time: alert.Data.events[selectedEventIndex].detectedAt,
                        Color: "#FFC107",
                        HighlightRangeBeforeSecs: 0,
                        HighlightRangeAfterSecs: 264
                    }))[0]]}
                    focus={alert.Data.satnos[0]}
                >
                    <TabsComponent tabs={availableTabs} hasDetails={true} />
                </SpaceCockpitDockContainer>
            </div>
        );
    };

    const renderProviderConsensus = () => {
        if (!alert.Data.events || alert.Data.events.length === 0) return null;

        return (
            <Row className="mb-4">
                <Col>
                    <div className="d-flex align-items-center gap-3 mb-3">
                        <h5 className="mb-0">Provider Consensus ({alert.Data.events.length})</h5>
                        <select 
                            className="form-select bg-dark text-white" 
                            style={{ 
                                maxWidth: '200px',
                                border: '1px solid #2a2d30'
                            }}
                            value={selectedEventIndex}
                            onChange={(e) => setSelectedEventIndex(Number(e.target.value))}
                        >
                            {alert.Data.events.map((event, index) => (
                                <option key={`${event.id}-${event.primary.source}`} value={index}>
                                    {event.primary.source}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <Table variant="dark" hover responsive>
                            <tbody>
                                <tr>
                                    <td><strong>Event Time</strong></td>
                                    <td>{formatDate(alert.Data.events[selectedEventIndex].detectedAt)}</td>
                                </tr>
                                <tr>
                                    <td><strong>Description</strong></td>
                                    <td>
                                        <OverlayTrigger
                                            placement="left"
                                            overlay={
                                                <Tooltip>
                                                    {alert.Data.events[selectedEventIndex].description}
                                                </Tooltip>
                                            }
                                        >
                                            <div style={{ cursor: 'help' }}>
                                                {alert.Data.events[selectedEventIndex].description}
                                            </div>
                                        </OverlayTrigger>
                                    </td>
                                </tr>
                                <tr>
                                    <td><strong>Trigger Threshold</strong></td>
                                    <td>
                                        {alert.Data.events[selectedEventIndex].details.overview}: {parseFloat(alert.Data.events[selectedEventIndex].details.deviation).toFixed(4)} &gt; {alert.Data.events[selectedEventIndex].details.threshold}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        );
    };

    const handleAcknowledge = async () => {
        try {
            setAcknowledging(true);
            await patchData('alerts',{
                "unique_id": alert.UniqueId
            });
            toast.success('Alert acknowledged successfully');
            navigate('/home');
        } catch (error) {
            console.error('Error acknowledging alert:', error);
            toast.error('Failed to acknowledge alert');
        } finally {
            setAcknowledging(false);
            setShowAcknowledgeModal(false);
        }
    };

    if (loading) {
        return (
            <>
                <Header showSideBarMenu={true} showDashboardMenu={false} height={56} />
                <Container className="mt-4">
                    <div className="text-center text-white">Loading alert details...</div>
                </Container>
            </>
        );
    }

    if (error) {
        return (
            <>
                <Header showSideBarMenu={true} showDashboardMenu={false} height={56} />
                <Container className="mt-4">
                    <div className="text-center text-danger">{error}</div>
                </Container>
            </>
        );
    }

    if (!alert) {
        return (
            <>
                <Header showSideBarMenu={true} showDashboardMenu={false} height={56} />
                <Container className="mt-4">
                    <div className="text-center text-white">Alert not found</div>
                </Container>
            </>
        );
    }

    return (
        <>
            <Header showSideBarMenu={true} showDashboardMenu={false} height={56} />
            <Container fluid className="mt-4" style={{ maxWidth: '95%' }}>
                <Card bg="dark" text="white">
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        <div className="d-flex gap-2">
                            <Button 
                                variant="warning"
                                onClick={() => navigate("/home")}
                                className="d-flex align-items-center gap-2"
                            >
                                <span style={{ fontSize: '1.2rem', lineHeight: 1 }}>←</span>
                                <span>Home</span>
                            </Button>
                            <Button
                                variant="success"
                                onClick={() => setShowAcknowledgeModal(true)}
                                disabled={acknowledging}
                            >
                                {acknowledging ? 'Acknowledging...' : 'Acknowledge'}
                            </Button>
                        </div>
                        <h4 className="mb-0 flex-grow-1 text-center"><span className="text-warning">Alert:</span> {alert.Data.description}</h4>
                        <div className="d-flex flex-column align-items-end">
                            <OverlayTrigger
                                placement="left"
                                overlay={
                                    <Tooltip>
                                        Windu: "May the force be with you"
                                    </Tooltip>
                                }
                            >
                                <img 
                                    src={winduImage} 
                                    alt="Windu" 
                                    className="d-none d-md-block"
                                    style={{ 
                                        width: '80px', 
                                        marginTop: '1rem',
                                        position: "absolute",
                                        cursor: 'help'
                                    }} 
                                />
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="left"
                                overlay={
                                    <Tooltip>
                                        <strong>Alert Levels:</strong><br/>
                                        <strong style={{ color: '#0dcaf0' }}>Info:</strong> RSO is <u>not</u> ranked<br/>
                                        <strong style={{ color: '#ffc107' }}>Warning:</strong> RSO is ranked<br/>
                                        <strong style={{ color: '#dc3545' }}>Danger:</strong> RSO is ranked, and has dangerous RPO's
                                    </Tooltip>
                                }
                            >
                                <Badge bg={alert.Level.toLowerCase()} 
                                    className={alert.Level.toLowerCase() === 'warning' ? 'text-dark' : ''}
                                    style={{ cursor: 'help' }}>
                                    {alert.Level.toUpperCase()}
                                </Badge>
                            </OverlayTrigger>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "calc(100vh - 56px - 12px - 150px)"
                        }}>
                            {renderProviderConsensus()}
                            {renderTabs()}
                        </div>
                    </Card.Body>
                </Card>
            </Container>

            <Modal
                show={showAcknowledgeModal}
                onHide={() => setShowAcknowledgeModal(false)}
                centered
                backdrop="static"
                data-bs-theme="dark"
                className="text-white"
            >
                <Modal.Header closeButton className="bg-dark">
                    <Modal.Title>Confirm Acknowledgment</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-dark">
                    Acknowledging this alert will clear it from your list of alerts, are you sure?
                </Modal.Body>
                <Modal.Footer className="bg-dark">
                    <Button variant="secondary" onClick={() => setShowAcknowledgeModal(false)}>
                        Cancel
                    </Button>
                    <Button 
                        variant="success" 
                        onClick={handleAcknowledge}
                        disabled={acknowledging}
                    >
                        {acknowledging ? 'Acknowledging...' : 'Yes, Acknowledge'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Alert; 