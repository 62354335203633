import React, { useEffect, useState, useContext } from 'react';
import Header from "../components/Header";
import { useNavigate } from 'react-router-dom';
import { AppState } from "../lib/context/AppProvider";
import { Container, Row, Col, Card, Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import LicenseAgreementModal from "../components/LicenseAgreementModal";
import PurchaseConfirmationModal from "../components/PurchaseConfirmationModal";
import RefreshNotificationModal from "../components/RefreshNotificationModal";
import { MdPushPin } from "react-icons/md";

// Extract card image component
const AppPreviewImage = ({ previewImage, title }) => (
    <div className="bg-dark position-relative" style={{ paddingTop: '56.25%' }}>
        {previewImage ? (
            <img 
                src={`data:image/png;base64,${previewImage}`}
                alt={title}
                style={{ 
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    padding: '0'
                }}
            />
        ) : (
            <div 
                className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
                style={{ backgroundColor: '#2a2a2a' }}
            >
                <span className="text-white-50">Preview</span>
            </div>
        )}
    </div>
);

// Extract high-side compatibility badge
const HighSideCompatibilityBadge = () => (
    <div className="d-flex align-items-center mb-2">
        <svg className="me-1" width="16" height="16" fill="none" stroke="white" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
        </svg>
        <small className="text-white">High-Side Compatible</small>
    </div>
);

// Update Pin Icon component
const PinIcon = () => (
    <OverlayTrigger
        placement="left"
        overlay={
            <Tooltip id="pin-icon-tooltip">
                Pin applications to the right sidebar in Space Cockpit for easy access.
            </Tooltip>
        }
    >
        <div 
            className="position-absolute"
            style={{ 
                top: '8px',
                right: '8px',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                borderRadius: '50%',
                padding: '4px',
                zIndex: 1
            }}
            data-testid="pin-icon-tooltip-trigger"
        >
            <MdPushPin 
                size={16}
                color="#f5bc59"
            />
        </div>
    </OverlayTrigger>
);

// Extract button text and style logic
export const getButtonText = (app, user) => {
    if (!app.IsReleased) return 'Coming Soon';
    if (user.isGuest) return 'Must be logged in';
    if (app.isPurchased) return 'Purchased';
    return app.Price === 0 ? 'Get' : `${app.Price} credits`;
};

export const getButtonStyle = (app, user) => {
    const styles = {
        backgroundColor: '#f5bc59',
        borderColor: '#f5bc59',
        flex: 1,
        cursor: 'pointer',
        opacity: 1
    };

    if (!app.IsReleased) {
        styles.backgroundColor = '#666666';
        styles.borderColor = '#666666';
        styles.cursor = 'not-allowed';
    } else if (app.isPurchased) {
        styles.backgroundColor = '#8B7355';
        styles.borderColor = '#8B7355';
        styles.cursor = 'not-allowed';
    } else if (user.isGuest) {
        styles.backgroundColor = '#666666';
        styles.borderColor = '#666666';
        styles.cursor = 'not-allowed';
        styles.opacity = 0.8;
    }

    return styles;
};

// Extract dropdown item components
const DropdownItem = ({ onClick, disabled, color, hoverColor, children }) => (
    <button 
        className="dropdown-item"
        onClick={onClick}
        style={{ 
            backgroundColor: 'transparent',
            border: 'none',
            cursor: disabled ? 'not-allowed' : 'pointer',
            width: '100%',
            textAlign: 'left',
            padding: '8px 16px',
            color: color || '#f5bc59',
            opacity: disabled ? 0.5 : 1
        }}
        onMouseEnter={(e) => {
            if (!disabled) {
                e.currentTarget.style.backgroundColor = '#3a3a3a';
                if (hoverColor) e.currentTarget.style.color = hoverColor;
            }
        }}
        onMouseLeave={(e) => {
            if (!disabled) {
                e.currentTarget.style.backgroundColor = 'transparent';
                if (hoverColor) e.currentTarget.style.color = color;
            }
        }}
        disabled={disabled}
    >
        {children}
    </button>
);

// Extract install button text logic
const getInstallButtonText = (isInstalling, isInstalled) => {
    if (isInstalling) return 'Processing...';
    if (isInstalled) return 'Uninstall';
    return 'Install';
};

// Extract install action logic
const InstallAction = ({ 
    app, 
    isInstalling, 
    handleInstallClick 
}) => (
    <li>
        <DropdownItem 
            onClick={handleInstallClick}
            disabled={isInstalling}
        >
            {getInstallButtonText(isInstalling, app.isInstalled)}
        </DropdownItem>
    </li>
);

// Extract pin text and state logic
const getPinButtonText = (isPinning, isPinned, isInstalled, totalPinnedApps) => {
    if (isPinning) return 'Processing...';
    if (isPinned) return 'Unpin';
    if (!isInstalled) return 'Pin (Install first)';
    if (totalPinnedApps >= 5) return 'Pin (Max 5 pinned)';
    return 'Pin';
};

const getPinButtonDisabledState = (isPinning, isInstalled, isPinned, totalPinnedApps) => {
    return isPinning || !isInstalled || (!isPinned && totalPinnedApps >= 5);
};

const getTooltipPlacement = (dropdownPosition) => {
    return dropdownPosition.horizontal === 'left' ? 'right' : 'left';
};

// Extract pin action logic
const PinAction = ({ app, isPinning, totalPinnedApps, handlePinClick, dropdownPosition }) => {
    const isDisabled = getPinButtonDisabledState(isPinning, app.isInstalled, app.isPinned, totalPinnedApps);
    const buttonText = getPinButtonText(isPinning, app.isPinned, app.isInstalled, totalPinnedApps);
    const tooltipPlacement = getTooltipPlacement(dropdownPosition);

    return (
        <li>
            <OverlayTrigger
                placement={tooltipPlacement}
                overlay={
                    <Tooltip id={`pin-tooltip-${app.Id}`}>
                        Pin applications to the right sidebar in Space Cockpit for easy access.
                    </Tooltip>
                }
            >
                <div data-testid="pin-dropdown-tooltip-trigger">
                    <DropdownItem 
                        onClick={handlePinClick}
                        disabled={isDisabled}
                        color={isDisabled ? '#666666' : '#f5bc59'}
                    >
                        {buttonText}
                    </DropdownItem>
                </div>
            </OverlayTrigger>
        </li>
    );
};

// Extract delete action logic
const DeleteAction = ({ handleDeleteClick }) => (
    <li>
        <DropdownItem 
            onClick={handleDeleteClick}
            color="#ff4444"
            hoverColor="#ff6666"
        >
            Remove application from account 
        </DropdownItem>
    </li>
);

// Extract dropdown positioning hook
const useDropdownPosition = (openDropdownId, appId) => {
    const [dropdownPosition, setDropdownPosition] = useState({
        vertical: 'bottom',
        horizontal: 'right',
        leftOffset: 0
    });

    useEffect(() => {
        if (openDropdownId === appId) {
            const dropdownButton = document.querySelector(`[data-app-id="${appId}"]`);
            const purchaseButton = document.querySelector(`[data-purchase-id="${appId}"]`);
            
            if (dropdownButton && purchaseButton) {
                const buttonRect = dropdownButton.getBoundingClientRect();
                const purchaseRect = purchaseButton.getBoundingClientRect();
                const viewportHeight = window.innerHeight;
                const dropdownHeight = 150;
                const dropdownWidth = 200;
                
                // Check vertical space
                const spaceBelow = viewportHeight - buttonRect.bottom;
                const vertical = spaceBelow < dropdownHeight ? 'top' : 'bottom';
                
                // Check horizontal space and calculate offset
                const spaceLeft = buttonRect.left;
                const horizontal = spaceLeft < (dropdownWidth + 50) ? 'left' : 'right';
                const leftOffset = horizontal === 'left' ? purchaseRect.left - buttonRect.left : 0;
                
                setDropdownPosition({ vertical, horizontal, leftOffset });
            }
        }
    }, [openDropdownId, appId]);

    return dropdownPosition;
};

// Extract purchase button component
const PurchaseButton = ({ app, user, onShowLicense }) => (
    <Button
        data-purchase-id={app.Id}
        variant="warning"
        onClick={(e) => {
            if (!app.isPurchased && app.IsReleased) {
                e.preventDefault();
                e.stopPropagation();
                onShowLicense(app);
            }
        }}
        style={getButtonStyle(app, user)}
        disabled={user.isGuest || app.isPurchased || !app.IsReleased}
    >
        {getButtonText(app, user)}
    </Button>
);

// Extract dropdown menu component
const DropdownMenu = ({ 
    app, 
    isOpen, 
    dropdownPosition, 
    isInstalling, 
    isPinning, 
    totalPinnedApps, 
    handleInstallClick, 
    handlePinClick, 
    handleDeleteClick 
}) => (
    <ul 
        className={`dropdown-menu ${isOpen ? 'show' : ''} ${dropdownPosition.vertical === 'top' ? 'dropdown-menu-top' : ''}`}
        style={{ 
            backgroundColor: '#2a2a2a',
            pointerEvents: app.isPurchased ? 'auto' : 'none',
            position: 'absolute',
            ...(dropdownPosition.vertical === 'top' ? {
                bottom: '100%',
                marginBottom: '2px',
            } : {
                top: '100%',
                marginTop: '2px',
            }),
            ...(dropdownPosition.horizontal === 'left' ? {
                left: dropdownPosition.leftOffset
            } : {
                right: 0
            }),
            maxHeight: '300px',
            overflowY: 'auto',
            minWidth: '200px'
        }}
    >
        <InstallAction 
            app={app}
            isInstalling={isInstalling}
            handleInstallClick={handleInstallClick}
        />
        <PinAction 
            app={app}
            isPinning={isPinning}
            totalPinnedApps={totalPinnedApps}
            handlePinClick={handlePinClick}
            dropdownPosition={dropdownPosition}
        />
        <DeleteAction handleDeleteClick={handleDeleteClick} />
    </ul>
);

// Extract update app installation logic
const updateAppInstallation = async (app, postData) => {
    const appId = parseInt(app.Id);
    const willUninstall = app.isInstalled;
    const newPinnedState = willUninstall ? false : app.isPinned;
    
    await postData(`user-apps/${appId}`, {
        Installed: app.isInstalled ? 0 : 1,
        Pinned: newPinnedState ? 1 : 0
    }, 'PATCH');
};

// Extract app state update logic
const getUpdatedAppState = (app, willUninstall) => ({
    ...app,
    isInstalled: !app.isInstalled,
    isPinned: willUninstall ? false : app.isPinned
});

// Extract apps list update logic
const updateAppsList = (prevApps, targetApp) => {
    const willUninstall = targetApp.isInstalled;
    const newApps = prevApps.map(app => 
        app.Id === targetApp.Id ? getUpdatedAppState(app, willUninstall) : app
    );
    
    return {
        apps: newApps,
        pinnedCount: newApps.filter(app => app.isPinned).length
    };
};

// Simplified AppActions component
const AppActions = ({ 
    app, 
    user, 
    onShowLicense, 
    openDropdownId, 
    onDropdownToggle, 
    onDeleteClick, 
    totalPinnedApps,
    setApps,
    setTotalPinnedApps,
    onStatusChange
}) => {
    const [isInstalling, setIsInstalling] = useState(false);
    const [isPinning, setIsPinning] = useState(false);
    const { postData } = useContext(AppState);

    const dropdownPosition = useDropdownPosition(openDropdownId, app.Id);

    // Moved handleInstallClick back inside the component
    const handleInstallClick = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (!app.isPurchased) return;
        
        setIsInstalling(true);
        
        try {
            await updateAppInstallation(app, postData);
            
            setApps(prevApps => {
                const { apps, pinnedCount } = updateAppsList(prevApps, app);
                setTotalPinnedApps(pinnedCount);
                return apps;
            });
            
            onStatusChange();
        } catch (error) {
            console.error('Error installing app:', error);
        } finally {
            setIsInstalling(false);
        }
    };

    const handlePinClick = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsPinning(true);
        
        try {
            if (app.isPurchased && app.isInstalled && (app.isPinned || totalPinnedApps < 5)) {
                const appId = parseInt(app.Id);
                
                await postData(`user-apps/${appId}`, {
                    Installed: app.isInstalled ? 1 : 0,
                    Pinned: app.isPinned ? 0 : 1
                }, 'PATCH');
                
                setApps(prevApps => {
                    const newApps = prevApps.map(a => 
                        a.Id === app.Id ? { ...a, isPinned: !a.isPinned } : a
                    );
                    const newPinnedCount = newApps.filter(a => a.isPinned).length;
                    setTotalPinnedApps(newPinnedCount);
                    return newApps;
                });
                
                onStatusChange();
            }
        } catch (error) {
            // Error handled silently
        } finally {
            setIsPinning(false);
        }
    };

    const handleDeleteClick = (e) => {
        e?.preventDefault();
        e?.stopPropagation();
        onDeleteClick(app);
    };

    return (
        <div className="d-flex gap-1">
            <PurchaseButton 
                app={app} 
                user={user} 
                onShowLicense={onShowLicense} 
            />
            {!user.isGuest && (
                <div className="dropdown" style={{ position: 'relative' }}>
                    <Button
                        data-app-id={app.Id}
                        variant="warning"
                        className="dropdown-toggle dropdown-toggle-split"
                        onClick={(e) => onDropdownToggle(e, app.Id)}
                        style={{ 
                            backgroundColor: app.isPurchased ? '#f5bc59' : '#666666',
                            borderColor: app.isPurchased ? '#f5bc59' : '#666666',
                            padding: '0 8px',
                            height: '38px',
                            display: 'flex',
                            alignItems: 'center',
                            cursor: app.isPurchased ? 'pointer' : 'not-allowed',
                            opacity: app.isPurchased ? 1 : 0.65
                        }}
                        disabled={!app.isPurchased}
                    >
                        <span className="visually-hidden">Toggle Dropdown</span>
                    </Button>
                    <DropdownMenu 
                        app={app}
                        isOpen={openDropdownId === app.Id}
                        dropdownPosition={dropdownPosition}
                        isInstalling={isInstalling}
                        isPinning={isPinning}
                        totalPinnedApps={totalPinnedApps}
                        handleInstallClick={handleInstallClick}
                        handlePinClick={handlePinClick}
                        handleDeleteClick={handleDeleteClick}
                    />
                </div>
            )}
        </div>
    );
};

// Extract app card component
const AppCard = ({ 
    app, 
    user, 
    onLaunch, 
    onShowLicense, 
    openDropdownId, 
    onDropdownToggle, 
    onDeleteClick, 
    totalPinnedApps, 
    setApps, 
    setTotalPinnedApps, 
    setShowRefreshModal 
}) => {
    const handleKeyDown = (e) => {
        if (!user.isGuest && (e.key === 'Enter' || e.key === ' ')) {
            onLaunch(app.Id);
        }
    };

    return (
        <Card 
            className="h-100" 
            style={{ 
                backgroundColor: '#474747',
                cursor: user.isGuest ? 'not-allowed' : 'pointer',
                transition: 'transform 0.2s',
                opacity: user.isGuest ? 0.8 : 1,
                position: 'relative'
            }}
            onClick={() => !user.isGuest && onLaunch(app.Id)}
            role="button"
            data-testid={`app-card-${app.Id}`}
            tabIndex={user.isGuest ? -1 : 0}
            onKeyDown={handleKeyDown}
        >
            {app.isPinned && <PinIcon />}
            <AppPreviewImage previewImage={app.PreviewImage} title={app.Title} />
            <Card.Body className="p-3">
                <Card.Title className="text-white text-truncate mb-1" style={{ fontSize: '1.1rem' }}>
                    {app.Title}
                </Card.Title>
                {app.Company && (
                    <div className="text-truncate mb-1" style={{ fontSize: '0.8rem', color: '#f5bc59' }}>
                        {app.Company}
                    </div>
                )}
                
                <div style={{ height: '80px' }}>
                    {Boolean(app.HighSideCompatible) && <HighSideCompatibilityBadge />}
                    <Card.Text className="text-white" style={{ 
                        fontSize: '0.8rem',
                        display: '-webkit-box',
                        WebkitLineClamp: app.HighSideCompatible ? 2 : 3,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        margin: 0
                    }}>
                        {app.Description}
                    </Card.Text>
                </div>
                <AppActions 
                    app={app}
                    user={user}
                    onShowLicense={onShowLicense}
                    openDropdownId={openDropdownId}
                    onDropdownToggle={onDropdownToggle}
                    onDeleteClick={onDeleteClick}
                    totalPinnedApps={totalPinnedApps}
                    setApps={setApps}
                    setTotalPinnedApps={setTotalPinnedApps}
                    onStatusChange={() => setShowRefreshModal(true)}
                />
            </Card.Body>
        </Card>
    );
};

function Sam() {
    const [apps, setApps] = useState([]);
    const [selectedApp, setSelectedApp] = useState(null);
    const [showLicenseModal, setShowLicenseModal] = useState(false);
    const [showPurchaseModal, setShowPurchaseModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showRefreshModal, setShowRefreshModal] = useState(false);
    const [appToDelete, setAppToDelete] = useState(null);
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const [totalPinnedApps, setTotalPinnedApps] = useState(0);
    const navigate = useNavigate();
    const { getData, postData, user } = useContext(AppState);

    useEffect(() => {
        const fetchAppsAndStatus = async () => {
            if (user.isGuest) {
                // For guest users, show 9 placeholder app cards
                const placeholderApps = Array(9).fill(null).map((_, index) => ({
                    Id: `placeholder-${index}`,
                    Title: "App Preview",
                    Company: "Login to View",
                    Description: "Login to view app details and purchase.",
                    Price: 0,
                    PreviewImage: "",
                    HighSideCompatible: false,
                    isPurchased: false,
                    isInstalled: false
                }));
                setApps(placeholderApps);
                return;
            }

            try {
                // For logged in users, fetch both apps and user-apps
                const [appsData, userApps] = await Promise.all([
                    getData('apps'),
                    getData('user-apps')
                ]);

                if (!appsData) {
                    setApps([]);
                    return;
                }

                // Set apps with purchase and installation status
                const appsWithStatus = appsData.map(app => {
                    const userApp = userApps?.find(userApp => userApp.AppId === app.Id);
                    return {
                        ...app,
                        isPurchased: Boolean(userApp),
                        isInstalled: Boolean(userApp?.Installed),
                        isPinned: Boolean(userApp?.Pinned)
                    };
                });
                
                setApps(appsWithStatus);
                // Calculate total pinned apps
                const pinnedCount = appsWithStatus.filter(app => app.isPinned).length;
                setTotalPinnedApps(pinnedCount);
            } catch (error) {
                console.error('Error fetching apps:', error);
                setApps([]);
            }
        };
        
        fetchAppsAndStatus();
    }, [getData, user.isGuest]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Only proceed if there's an open dropdown
            if (openDropdownId) {
                // Get all dropdown elements
                const dropdowns = document.querySelectorAll('.dropdown');
                let clickedInside = false;

                // Check if the click was inside any dropdown
                dropdowns.forEach(dropdown => {
                    if (dropdown.contains(event.target)) {
                        clickedInside = true;
                    }
                });

                // If click was outside, close the dropdown
                if (!clickedInside) {
                    setOpenDropdownId(null);
                }
            }
        };

        // Use both mousedown and click events for better coverage
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('click', handleClickOutside);
        };
    }, [openDropdownId]);

    const handleLaunch = (appId) => {
        if (user.isGuest) {
            return; // Prevent launching for guest users
        }
        navigate(`/samapp/${appId}`);
    };

    const handleLicenseAccept = () => {
        setShowLicenseModal(false);
        setShowPurchaseModal(true);
    };

    const handlePurchaseHide = () => {
        setShowPurchaseModal(false);
        setSelectedApp(null);
    };

    const handlePurchaseSuccess = async () => {
        // First deduct credits if it's a paid app
        if (selectedApp?.Price > 0) {
            const updatedCredits = await postData('user-credits', { credits: selectedApp.Price });
            if (!updatedCredits) {
                throw new Error('Failed to update credits');
            }
        }
        
        // Then make the purchase
        const purchaseResult = await postData(`apps/${selectedApp.Id}/purchase`);
        if (purchaseResult?.ok) {
            setApps(prevApps => 
                prevApps.map(app => 
                    app.Id === selectedApp.Id 
                        ? { ...app, isPurchased: true }
                        : app
                )
            );
            setShowPurchaseModal(false);
        } else {
            throw new Error('Purchase failed');
        }
    };

    const handleDropdownToggle = (e, appId) => {
        e.preventDefault();
        e.stopPropagation();
        setOpenDropdownId(openDropdownId === null ? appId : null);
    };

    const handleDeleteClick = (app) => {
        setAppToDelete(app);
        setShowDeleteModal(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            await postData(`user-apps/${appToDelete.Id}`, {}, 'DELETE');
            
            // Update the apps list to reflect the deletion
            setApps(prevApps => 
                prevApps.map(app => 
                    app.Id === appToDelete.Id 
                        ? { ...app, isPurchased: false }
                        : app
                )
            );
        } catch (error) {
            // Error handled silently
        } finally {
            setShowDeleteModal(false);
            setAppToDelete(null);
        }
    };

    // Add effect to keep totalPinnedApps in sync
    useEffect(() => {
        if (apps.length > 0) {
            const pinnedCount = apps.filter(app => app.isPinned).length;
            setTotalPinnedApps(pinnedCount);
        }
    }, [apps]);

    return (  
        <div className="min-vh-100 bg-dark d-flex flex-column">
            <Header
                showSideBarMenu={true}
                showDashboardMenu={false}
                height={56} 
                className="pb-5"
                showCredits={true}
            />
            <Container fluid className="py-4 flex-grow-1 overflow-auto" style={{ maxWidth: "1800px", height: "calc(100vh - 56px)" }}>
                <Row xs={2} md={3} lg={4} xl={6} className="g-3">
                    {apps?.map((app) => (
                        <Col key={`${app.Id}-${app.Title}`}>
                            <AppCard
                                app={app}
                                user={user}
                                onLaunch={handleLaunch}
                                onShowLicense={(app) => {
                                    setSelectedApp(app);
                                    setShowLicenseModal(true);
                                }}
                                openDropdownId={openDropdownId}
                                onDropdownToggle={handleDropdownToggle}
                                onDeleteClick={handleDeleteClick}
                                totalPinnedApps={totalPinnedApps}
                                setApps={setApps}
                                setTotalPinnedApps={setTotalPinnedApps}
                                setShowRefreshModal={setShowRefreshModal}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>

            <LicenseAgreementModal
                show={showLicenseModal}
                onHide={() => {
                    setShowLicenseModal(false);
                    setSelectedApp(null);
                }}
                onAccept={handleLicenseAccept}
                appTitle={selectedApp?.Title}
            />

            <PurchaseConfirmationModal
                show={showPurchaseModal}
                onHide={handlePurchaseHide}
                onConfirm={handlePurchaseSuccess}
                appTitle={selectedApp?.Title}
                appId={selectedApp?.Id}
                appPrice={selectedApp?.Price}
            />

            <Modal
                show={showDeleteModal}
                onHide={() => {
                    setShowDeleteModal(false);
                    setAppToDelete(null);
                }}
                centered
                backdrop="static"
                style={{ color: 'white' }}
            >
                <Modal.Header closeButton style={{ backgroundColor: '#2a2a2a', borderBottom: '1px solid #3a3a3a' }}>
                    <Modal.Title style={{ color: 'white' }}>Remove application</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: '#2a2a2a' }}>
                    Are you sure you want to remove {appToDelete?.Title} from your account? You may lose access to previous in-app purchases.
                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: '#2a2a2a', borderTop: '1px solid #3a3a3a' }}>
                    <Button 
                        variant="secondary" 
                        onClick={() => {
                            setShowDeleteModal(false);
                            setAppToDelete(null);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button 
                        variant="danger" 
                        onClick={handleDeleteConfirm}
                    >
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            <RefreshNotificationModal
                show={showRefreshModal}
                onHide={() => setShowRefreshModal(false)}
            />
        </div>
    );
}

export default Sam;
