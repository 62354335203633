import React from 'react';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import NotsoRow from './NotsoRow';

const tableContainerStyle = {
    overflowY: 'auto',
};

const NotsoTable = ({
    recentNotsos,
    loadingNotso,
    tableStyle,
    setSCScene
}) => {
    const navigate = useNavigate();

    if (recentNotsos.length === 0) {
        return (
            <div style={tableStyle} data-testid="notso-table-container">
                <div style={tableContainerStyle}>
                    <Table variant="dark" hover>
                        <thead>
                            <tr>
                                <th>Created</th>
                                <th>Event Class</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan="3" className="text-center">
                                    {loadingNotso ? "Loading NOTSOs..." : "No recent NOTSOs"}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    }

    return (
        <div style={tableStyle} data-testid="notso-table-container">
            <div style={tableContainerStyle}>
                <Table variant="dark" hover>
                    <thead>
                        <tr>
                            <th>Created</th>
                            <th>Event Class</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {recentNotsos.map((notso) => (
                            <NotsoRow
                                key={notso.Id}
                                notso={notso}
                                setSCScene={setSCScene}
                                navigate={navigate}
                            />
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default NotsoTable; 