import React, { useState, useContext, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import Header from '../components/Header';
import { AppState } from '../lib/context/AppProvider';
import { toast } from 'react-toastify';
import { isValidEmail } from '../util/Utils';

const Credits = () => {
    const { user, getData, postData } = useContext(AppState);
    const [formData, setFormData] = useState({
        udlUsername: '',
        email: '',
        requestedCredits: '',
        company: ''  // Added company field for the notification email
    });
    const [currentBalance, setCurrentBalance] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        const fetchCredits = async () => {
            try {
                const credits = await getData('user-credits');
                setCurrentBalance(credits?.balance || 0);
            } catch (error) {
                setCurrentBalance(0);
            }
        };

        if (!user.isGuest) {
            fetchCredits();
        }
    }, [getData, user.isGuest]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const validateForm = () => {
        if (!formData.udlUsername.trim()) {
            toast.error('Please enter your UDL username');
            return false;
        }
        if (!formData.email.trim() || !isValidEmail(formData.email)) {
            toast.error('Please enter a valid email address');
            return false;
        }
        if (!formData.requestedCredits || parseInt(formData.requestedCredits) <= 0) {
            toast.error('Please enter a valid credit amount');
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!validateForm()) return;
        
        setIsSubmitting(true);
        try {
            // Send purchase request to backend
            await postData('user-credits/purchase', {
                udlAccount: formData.udlUsername,
                email: formData.email,
                credits: parseInt(formData.requestedCredits),
                company: formData.company || 'Not specified'
            });

            toast.success('Credit purchase request submitted successfully!');
            
            // Reset form
            setFormData({
                udlUsername: '',
                email: '',
                requestedCredits: '',
                company: ''
            });

        } catch (error) {
            toast.error('Failed to submit credit request. Please try again or contact support.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const newBalance = currentBalance + parseInt(formData.requestedCredits || 0);
    const totalUSD = 'US' + (parseInt(formData.requestedCredits || 0)).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
    }).replace('$', '$');

    return (
        <div className="min-vh-100 bg-dark">
            <Header 
                showSideBarMenu={true} 
                showDashboardMenu={false} 
                height={56} 
                showCredits={true}
            />
            <Container fluid className="py-4">
                <div className="text-white">
                    <h1 className="mb-3">Purchase Credits</h1>
                    <hr style={{ borderColor: '#f5bc59' }} />

                    <div className="mb-4">
                        <p>
                            Credits are used to purchase applications and microtransactions in the Space Application Marketplace and associated applications. 
                            To buy credits, please contact Saber Astronautics using the form below or email{' '}
                            <a href="mailto:spacecockpit@saberastro.com" style={{ color: '#f5bc59' }}>
                                spacecockpit@saberastro.com
                            </a>
                            {' '}with the number of credits you wish to purchase. Each credit costs US$1.00.
                        </p>
                        <p>
                            <span className="text-warning"><strong>Do not enter your payment information in the form or email.</strong></span>
                            {' '}Saber Astronautics will send an invoice to the provided email to complete the transaction. 
                            The requested credits will be loaded onto your account once payment is received. 
                            Please allow for 1-2 business days to receive the invoice and another 1-2 business days for the credits to be loaded onto your account after the transaction is complete.
                        </p>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <Form onSubmit={handleSubmit} noValidate>
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        type="text"
                                        placeholder="UDL username"
                                        name="udlUsername"
                                        value={formData.udlUsername}
                                        onChange={handleInputChange}
                                        style={{ backgroundColor: '#1a1d20', color: 'white', border: '1px solid #495057' }}
                                        disabled={isSubmitting}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Control
                                        type="email"
                                        placeholder="Email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        style={{ backgroundColor: '#1a1d20', color: 'white', border: '1px solid #495057' }}
                                        disabled={isSubmitting}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Control
                                        type="text"
                                        placeholder="Company (Optional)"
                                        name="company"
                                        value={formData.company}
                                        onChange={handleInputChange}
                                        style={{ backgroundColor: '#1a1d20', color: 'white', border: '1px solid #495057' }}
                                        disabled={isSubmitting}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter Requested Credit Amount"
                                        name="requestedCredits"
                                        value={formData.requestedCredits}
                                        onChange={handleInputChange}
                                        style={{ backgroundColor: '#1a1d20', color: 'white', border: '1px solid #495057' }}
                                        min="1"
                                        step="100"
                                        disabled={isSubmitting}
                                    />
                                </Form.Group>

                                <div className="text-end">
                                    <Button 
                                        type="submit" 
                                        style={{ 
                                            backgroundColor: '#f5bc59', 
                                            border: 'none',
                                            color: 'black',
                                            padding: '8px 24px'
                                        }}
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? 'Submitting...' : 'Submit'}
                                    </Button>
                                </div>
                            </Form>
                        </div>

                        <div className="col-md-5 offset-md-1">
                            <div style={{ backgroundColor: '#1a1d20', padding: '20px', borderRadius: '8px' }}>
                                <div className="d-flex justify-content-between mb-3">
                                    <span className="text-white">Current Credit Balance</span>
                                    <span className="text-white" data-testid="current-balance">
                                        {currentBalance} credits
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between mb-3">
                                    <span className="text-white">Requested Credit Amount</span>
                                    <span className="text-white" data-testid="requested-credits">
                                        {formData.requestedCredits || 0} credits
                                    </span>
                                </div>
                                <hr style={{ borderColor: '#f5bc59' }} />
                                <div className="d-flex justify-content-between mb-3">
                                    <span className="text-white">New Credit Balance</span>
                                    <span className="text-white" data-testid="new-balance">
                                        {newBalance} credits
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <strong className="text-white">Total</strong>
                                    <strong style={{ color: '#f5bc59' }} data-testid="total-price">
                                        {totalUSD}
                                    </strong>
                                </div>
                                <div className="mt-3">
                                    <small className="text-white-50" style={{ fontSize: '0.8rem' }}>
                                        Please allow for 1-2 business days to receive the invoice
                                        and another 1-2 business days for the credits to be loaded
                                        onto your account after the transaction is complete.
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Credits; 