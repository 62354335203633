export const groupNotsos = (notsos) => {
    if (!notsos || !Array.isArray(notsos)) return [];

    const grouped = new Map();

    // First pass: group by satellite numbers
    notsos.forEach(notso => {
        if (notso.SatNos && notso.SatNos.length > 0) {
            notso.SatNos.forEach(satNo => {
                if (!grouped.has(satNo) || new Date(notso.CreatedAt) > new Date(grouped.get(satNo).CreatedAt)) {
                    grouped.set(satNo, notso);
                }
            });
        } else {
            // For NOTSOs without satellites, use EventClass as key
            if (!grouped.has(notso.EventClass) || new Date(notso.CreatedAt) > new Date(grouped.get(notso.EventClass).CreatedAt)) {
                grouped.set(notso.EventClass, notso);
            }
        }
    });

    // Convert map values to array and remove duplicates
    return Array.from(new Set(grouped.values()));
};

export const hasSatNo = (array) => {
    return parseInt(array?.[0]) >= 0;
};

export const getRelativeTime = (dateString) => {
    const date = new Date(dateString.endsWith("Z") ? dateString : (dateString+"Z"));
    const now = new Date();
    const diffMs = now - date;
    const diffSecs = Math.floor(diffMs / 1000);
    const diffMins = Math.floor(diffSecs / 60);
    const diffHours = Math.floor(diffMins / 60);
    const diffDays = Math.floor(diffHours / 24);

    const remainingHours = diffHours % 24;
    const remainingMins = diffMins % 60;

    let result = '';
    if (diffDays > 0) result += `${diffDays}d `;
    if (remainingHours > 0) result += `${remainingHours}h `;
    if (remainingMins > 0) result += `${remainingMins}m`;
    
    return result ? `${result} ago` : 'just now';
};

// Get category type for filtering
export const getCategoryType = (str) => {
    if (!str) return null;
    const lowerStr = str.toLowerCase();
    if (lowerStr.includes('photometry')) return 'photometry';
    if (lowerStr.includes('rpo')) return 'rpo';
    if (lowerStr.includes('maneuver')) return 'maneuver';
    if (lowerStr.includes('launch')) return 'launch';
    return null;
}; 