import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import ModalSearchSection from "./ModalSearchSection";
import GroupModal from "./GroupModal";
import MultiValueInput from "./MultiValueInput";
import { isValidEmail } from "../../util/Utils";

export const satFilterFunc = (satellites, selectedSatellites, searchTerm, allowCustom) => {
    if(!satellites) return [];

    if (!searchTerm || searchTerm.length < 2) {
        return selectedSatellites ? selectedSatellites.map(id => {
            const sat = satellites.find(s => s.id === id);
            return sat || { id, name: "Custom" };
        }) : [];
    }

    // Check if the search term is a CSV list of NoradIDs
    if (searchTerm.includes(',')) {
        const noradIds = searchTerm.split(',').map(id => id.trim());
        return satellites.filter(sat => noradIds.includes(sat.id));
    }

    // Regular search by name or ID
    const searchTermLower = searchTerm.toLowerCase();
    return satellites.filter(sat => 
        sat.id.toLowerCase().includes(searchTermLower) || 
        sat.name.toLowerCase().includes(searchTermLower)
    );
};

const JCOCheckbox = ({useJCO, setUseJCO}) => {
    return (
        <div className="mb-3 form-check form-switch d-flex align-items-center">
            <input
                className="form-check-input"
                type="checkbox"
                id="useJCOSwitch"
                data-testid="useJCOSwitch"
                checked={useJCO}
                onChange={(e) => setUseJCO(e.target.checked)}
            />
            <label className="form-check-label text-white ms-2 d-flex align-items-center gap-1" htmlFor="showAcknowledgedSwitch">
                Use JCO HRR List
            </label>
        </div>
    );
};

const SatGroupModal = ({ 
    show, 
    onHide, 
    typeText, 
    buttonVariant = "primary",
    satellites,
    onSave,
    editMode = false,
    existingGroup = { name: "", satellites: [] },
}) => {
    const [selectedSatellites, setSelectedSatellites] = useState(existingGroup?.satellites ?? []);
    const [selectedEmails, setSelectedEmails] = useState(existingGroup?.emails ?? []);
    const [selectedWebhooks, setSelectedWebhooks] = useState(existingGroup?.webhooks ?? []);

    const [useJCO, setUseJCO] = useState(existingGroup?.useJCO);

    const makeGroup = useMemo(() => (groupName) => {
        if(selectedSatellites?.length > 0 || useJCO) {
            return { 
                name: groupName, 
                satellites: useJCO ? [] : selectedSatellites, 
                emails: selectedEmails,
                webhooks: selectedWebhooks,
                useJCO: useJCO,
            };
        }
        return null;
    }, [selectedSatellites, selectedEmails, selectedWebhooks, useJCO]);

    // Reset state when modal opens/closes or when editMode changes
    useEffect(() => {
        if (show) {
            if (editMode) {
                setSelectedSatellites(existingGroup.satellites);
            } else {
                setSelectedSatellites([]);
            }
        }
    }, [show, editMode, existingGroup]);

    return (
        <GroupModal 
            show={show}
            onHide={onHide}
            typeText={typeText}
            buttonVariant={buttonVariant}
            onSave={onSave}
            editMode={editMode}
            existingGroup={existingGroup}
            makeGroup={makeGroup}
            modalBody={
                <>
                    <JCOCheckbox
                        useJCO={useJCO}
                        setUseJCO={setUseJCO}
                    />

                    {!useJCO && 
                        <ModalSearchSection 
                            itemOptions={satellites} 
                            selectedItems={selectedSatellites} 
                            setSelectedItems={setSelectedSatellites} 
                            filterFunction={satFilterFunc}
                        />
                    }

                    <MultiValueInput
                        typeText={"Choose email addresses to send alerts to for this constellation"} 
                        selectedItems={selectedEmails} 
                        setSelectedItems={setSelectedEmails}
                        placeholder="Enter email address..."
                        validator={isValidEmail}
                    />

                    <MultiValueInput
                        typeText={"Set webhooks to post results to"} 
                        selectedItems={selectedWebhooks} 
                        setSelectedItems={setSelectedWebhooks}
                        placeholder="Enter webhook URL..."
                        validator={() => true}
                    />
                </>
            }
        />
    );
};

SatGroupModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    typeText: PropTypes.string.isRequired,
    buttonVariant: PropTypes.string,
    satellites: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    })).isRequired,
    onSave: PropTypes.func.isRequired,
    editMode: PropTypes.bool,
    existingGroup: PropTypes.shape({
        name: PropTypes.string,
        satellites: PropTypes.arrayOf(PropTypes.string)
    })
};

export default SatGroupModal;