import React, { useEffect, useState, useContext, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from "../components/Header";
import { AppState } from "../lib/context/AppProvider";
import { Container, Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import LicenseAgreementModal from "../components/LicenseAgreementModal";
import PurchaseConfirmationModal from "../components/PurchaseConfirmationModal";
import RefreshNotificationModal from "../components/RefreshNotificationModal";

// Helper function to merge app data
const mergeAppData = (appDetails, catalogApp, isPurchased, isInstalled, isPinned) => {
    const companyData = {
        Company: catalogApp?.Company || appDetails?.Company,
        CompanyDescription: appDetails?.CompanyDescription || catalogApp?.CompanyDescription,
        CompanyLogo: appDetails?.CompanyLogo || catalogApp?.CompanyLogo,
        CompanyLink: appDetails?.CompanyLink || catalogApp?.CompanyLink
    };

    return {
        ...appDetails,
        ...companyData,
        Title: catalogApp?.Title,
        HighSideCompatible: catalogApp?.HighSideCompatible,
        Price: catalogApp?.Price,
        IsReleased: catalogApp?.IsReleased,
        isPurchased,
        isInstalled,
        isPinned,
        images: [
            { id: 0, data: appDetails.Image1 },
            { id: 1, data: appDetails.Image2 },
            { id: 2, data: appDetails.Image3 }
        ].filter(img => img.data)
    };
};

// Component for the company logo
const CompanyLogo = ({ logo, company }) => (
    logo ? (
        <img 
            src={`data:image/png;base64,${logo}`}
            alt={`${company} logo`}
            style={{ 
                width: '64px', 
                height: '64px',
                minWidth: '64px',
                objectFit: 'contain'
            }}
        />
    ) : (
        <div 
            data-testid="company-logo-placeholder"
            className="bg-secondary"
            style={{ 
                width: '64px', 
                height: '64px',
                minWidth: '64px'
            }}
        />
    )
);

// Component for the high-side compatibility badge
const HighSideCompatibilityBadge = () => (
    <div className="d-flex align-items-center"
        title="This application does not need to access outside services to work."
    >
        <svg className="me-1" width="16" height="16" fill="none" stroke="white" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
        </svg>
        <small className="text-white">High-Side Compatible</small>
    </div>
);

// Component for the app actions (purchase/delete buttons)
const AppActions = ({ 
    app, 
    user, 
    onShowLicense, 
    openDropdownId, 
    onDropdownToggle, 
    onDeleteClick, 
    onInstallToggle, 
    onPinToggle,
    setApp,
    appId,
    onStatusChange 
}) => {
    const [isInstalling, setIsInstalling] = useState(false);
    const [isPinning, setIsPinning] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState('bottom');
    const [totalPinnedApps, setTotalPinnedApps] = useState(0);
    const dropdownButtonRef = useRef(null);
    const dropdownRef = useRef(null);
    const { postData, getData } = useContext(AppState);

    useEffect(() => {
        const fetchPinnedAppsCount = async () => {
            try {
                const userApps = await getData('user-apps');
                if (userApps) {
                    const pinnedCount = userApps.filter(ua => ua.Pinned).length;
                    setTotalPinnedApps(pinnedCount);
                }
            } catch (error) {
                console.error('Error fetching pinned apps count:', error);
            }
        };

        if (!user.isGuest) {
            fetchPinnedAppsCount();
        }
    }, [getData, user.isGuest]);

    useEffect(() => {
        if (openDropdownId === app.Id && dropdownButtonRef.current) {
            const buttonRect = dropdownButtonRef.current.getBoundingClientRect();
            const viewportHeight = window.innerHeight;
            const dropdownHeight = 150; // Approximate height of dropdown
            const spaceBelow = viewportHeight - buttonRect.bottom;
            
            if (spaceBelow < dropdownHeight) {
                setDropdownPosition('top');
            } else {
                setDropdownPosition('bottom');
            }
        }
    }, [openDropdownId, app.Id]);

    // Add click outside handler
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (openDropdownId === app.Id) {
                // Check if click is outside both the dropdown and the toggle button
                if (
                    dropdownRef.current && 
                    !dropdownRef.current.contains(event.target) &&
                    dropdownButtonRef.current &&
                    !dropdownButtonRef.current.contains(event.target)
                ) {
                    onDropdownToggle(event, null);
                }
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, [openDropdownId, app.Id, onDropdownToggle]);

    const handleInstallClick = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsInstalling(true);
        try {
            if (app.isPurchased) {
                const willUninstall = app.isInstalled;
                const newPinnedState = willUninstall ? false : app.isPinned;
                const appIdInt = parseInt(appId);
                
                if (isNaN(appIdInt)) {
                    throw new Error('Invalid app ID');
                }
                
                await postData(`user-apps/${appIdInt}`, {
                    Installed: app.isInstalled ? 0 : 1,
                    Pinned: newPinnedState ? 1 : 0
                }, 'PATCH');
                
                setApp(prevApp => ({
                    ...prevApp,
                    isInstalled: !prevApp.isInstalled,
                    isPinned: willUninstall ? false : prevApp.isPinned
                }));
                onStatusChange();
            }
        } catch (error) {
            // Error handled silently
        } finally {
            setIsInstalling(false);
        }
    };

    const handlePinClick = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsPinning(true);
        try {
            if (app.isPurchased && app.isInstalled && (app.isPinned || totalPinnedApps < 5)) {
                const appIdInt = parseInt(appId);
                
                if (isNaN(appIdInt)) {
                    throw new Error('Invalid app ID');
                }
                
                await postData(`user-apps/${appIdInt}`, {
                    Installed: app.isInstalled ? 1 : 0,
                    Pinned: app.isPinned ? 0 : 1
                }, 'PATCH');
                
                setApp(prevApp => ({
                    ...prevApp,
                    isPinned: !prevApp.isPinned
                }));

                // Update total pinned count
                setTotalPinnedApps(prev => app.isPinned ? prev - 1 : prev + 1);
                onStatusChange();
            }
        } catch (error) {
            // Error handled silently
        } finally {
            setIsPinning(false);
        }
    };

    const getButtonText = (app, user) => {
        if (!app.IsReleased) {
            return 'Coming Soon';
        }
        if (user.isGuest) {
            return 'Must be logged in';
        }
        if (app.isPurchased) {
            return 'Purchased';
        }
        return app.Price === 0 ? 'Get' : `${app.Price} credits`;
    };

    const getButtonStyle = (isPurchased, isReleased) => {
        let backgroundColor;
        let borderColor;
        let cursor;
        let opacity = 1;

        if (!isReleased) {
            backgroundColor = '#666666';
            borderColor = '#666666';
            cursor = 'not-allowed';
        } else if (isPurchased) {
            backgroundColor = '#8B7355';
            borderColor = '#8B7355';
            cursor = 'not-allowed';
        } else if (user.isGuest) {
            backgroundColor = '#666666';
            borderColor = '#666666';
            cursor = 'not-allowed';
            opacity = 0.8;
        } else {
            backgroundColor = '#f5bc59';
            borderColor = '#f5bc59';
            cursor = 'pointer';
        }

        return {
            backgroundColor,
            borderColor,
            flex: 1,
            cursor,
            opacity,
            height: '38px'
        };
    };

    const getDropdownButtonStyle = (isPurchased) => ({
        backgroundColor: isPurchased ? '#f5bc59' : '#666666',
        borderColor: isPurchased ? '#f5bc59' : '#666666',
        padding: '0 8px',
        height: '38px',
        display: 'flex',
        alignItems: 'center',
        cursor: isPurchased ? 'pointer' : 'not-allowed',
        opacity: isPurchased ? 1 : 0.65
    });

    return (
        <div className="d-flex gap-1">
            <Button
                variant="warning"
                onClick={(e) => {
                    if (!app.isPurchased && app.IsReleased) {
                        e.preventDefault();
                        e.stopPropagation();
                        onShowLicense();
                    }
                }}
                style={getButtonStyle(app.isPurchased, app.IsReleased)}
                disabled={user.isGuest || app.isPurchased || !app.IsReleased}
            >
                {getButtonText(app, user)}
            </Button>
            {!user.isGuest && (
                <div className="dropdown" style={{ position: 'relative' }}>
                    <Button
                        ref={dropdownButtonRef}
                        variant="warning"
                        className="dropdown-toggle dropdown-toggle-split"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (app.isPurchased) {
                                onDropdownToggle(e, openDropdownId === app.Id ? null : app.Id);
                            }
                        }}
                        style={getDropdownButtonStyle(app.isPurchased)}
                        disabled={!app.isPurchased}
                    >
                        <span className="visually-hidden">Toggle Dropdown</span>
                    </Button>
                    <ul
                        ref={dropdownRef}
                        className={`dropdown-menu ${openDropdownId === app.Id ? 'show' : ''} ${dropdownPosition === 'top' ? 'dropdown-menu-top' : 'dropdown-menu-end'}`}
                        style={{ 
                            backgroundColor: '#2a2a2a',
                            pointerEvents: app.isPurchased ? 'auto' : 'none',
                            position: 'absolute',
                            ...(dropdownPosition === 'top' ? {
                                bottom: '100%',
                                marginBottom: '2px',
                                right: 0
                            } : {
                                top: '100%',
                                marginTop: '2px',
                                right: 0
                            }),
                            maxHeight: '300px',
                            overflowY: 'auto',
                            minWidth: '200px'
                        }}
                    >
                        <li>
                            <button 
                                className="dropdown-item"
                                onClick={handleInstallClick}
                                style={{ 
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    cursor: 'pointer',
                                    width: '100%',
                                    textAlign: 'left',
                                    padding: '8px 16px',
                                    color: '#f5bc59'
                                }}
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.backgroundColor = '#3a3a3a';
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.backgroundColor = 'transparent';
                                }}
                                disabled={isInstalling}
                            >
                                {(() => {
                                    if (isInstalling) {
                                        return 'Processing...';
                                    }
                                    return app.isInstalled ? 'Uninstall' : 'Install';
                                })()}
                            </button>
                        </li>
                        <li>
                            <OverlayTrigger
                                placement="left"
                                overlay={
                                    <Tooltip id={`pin-tooltip-${app.Id}`}>
                                        Pin applications to the right sidebar in Space Cockpit for easy access.
                                    </Tooltip>
                                }
                            >
                                <div data-testid="pin-tooltip-trigger">
                                    <button 
                                        className="dropdown-item"
                                        onClick={handlePinClick}
                                        style={{ 
                                            backgroundColor: 'transparent',
                                            border: 'none',
                                            cursor: (!app.isInstalled || (!app.isPinned && totalPinnedApps >= 5)) ? 'not-allowed' : 'pointer',
                                            width: '100%',
                                            textAlign: 'left',
                                            padding: '8px 16px',
                                            color: (!app.isInstalled || (!app.isPinned && totalPinnedApps >= 5)) ? '#666666' : '#f5bc59',
                                            opacity: (!app.isInstalled || (!app.isPinned && totalPinnedApps >= 5)) ? 0.5 : 1
                                        }}
                                        onMouseEnter={(e) => {
                                            if (app.isInstalled && (!(!app.isPinned && totalPinnedApps >= 5))) {
                                                e.currentTarget.style.backgroundColor = '#3a3a3a';
                                            }
                                        }}
                                        onMouseLeave={(e) => {
                                            if (app.isInstalled && (!(!app.isPinned && totalPinnedApps >= 5))) {
                                                e.currentTarget.style.backgroundColor = 'transparent';
                                            }
                                        }}
                                        disabled={isPinning || !app.isInstalled || (!app.isPinned && totalPinnedApps >= 5)}
                                    >
                                        {(() => {
                                            if (isPinning) {
                                                return 'Processing...';
                                            }
                                            if (app.isPinned) {
                                                return 'Unpin';
                                            }
                                            if (!app.isInstalled) {
                                                return 'Pin (Install first)';
                                            }
                                            return totalPinnedApps >= 5 ? 'Pin (Max 5 pinned)' : 'Pin';
                                        })()}
                                    </button>
                                </div>
                            </OverlayTrigger>
                        </li>
                        <li>
                            <button 
                                className="dropdown-item"
                                onClick={() => onDeleteClick()}
                                style={{ 
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    cursor: 'pointer',
                                    width: '100%',
                                    textAlign: 'left',
                                    padding: '8px 16px',
                                    color: '#ff4444'
                                }}
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.backgroundColor = '#3a3a3a';
                                    e.currentTarget.style.color = '#ff6666';
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.backgroundColor = 'transparent';
                                    e.currentTarget.style.color = '#ff4444';
                                }}
                            >
                                Remove application from account
                            </button>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};

// Component for the image carousel
const ImageCarousel = ({ images, currentImageIndex, onImageChange }) => {
    const getCurrentImage = () => images[currentImageIndex]?.data || '';

    return (
        <div className="position-relative mb-2">
            <div className="text-center">
                <img
                    src={`data:image/png;base64,${getCurrentImage()}`}
                    alt={`Screenshot ${currentImageIndex + 1}`}
                    style={{ 
                        maxHeight: '60vh',
                        maxWidth: '100%',
                        width: 'auto',
                        height: 'auto',
                        objectFit: 'contain'
                    }}
                />
                
                {images.length > 1 && (
                    <>
                        <button 
                            className="position-absolute top-50 start-0 translate-middle-y bg-transparent border-0 ps-3"
                            style={{ 
                                fontSize: '2rem', 
                                cursor: 'pointer',
                                color: '#f5bc59',
                                opacity: currentImageIndex === 0 ? 0.5 : 1,
                                transition: 'opacity 0.2s'
                            }}
                            onClick={() => onImageChange(Math.max(0, currentImageIndex - 1))}
                            disabled={currentImageIndex === 0}
                        >
                            ◀
                        </button>
                        <button 
                            className="position-absolute top-50 end-0 translate-middle-y bg-transparent border-0 pe-3"
                            style={{ 
                                fontSize: '2rem', 
                                cursor: 'pointer',
                                color: '#f5bc59',
                                opacity: currentImageIndex === images.length - 1 ? 0.5 : 1,
                                transition: 'opacity 0.2s'
                            }}
                            onClick={() => onImageChange(Math.min(images.length - 1, currentImageIndex + 1))}
                            disabled={currentImageIndex === images.length - 1}
                        >
                            ▶
                        </button>
                    </>
                )}
            </div>

            {images.length > 1 && (
                <div className="d-flex justify-content-center gap-2 pb-2">
                    {images.map((image) => (
                        <button
                            key={`image-dot-${image.id}`}
                            onClick={() => onImageChange(image.id)}
                            className="rounded-circle border-0"
                            style={{
                                width: '10px',
                                height: '10px',
                                backgroundColor: currentImageIndex === image.id ? '#f5bc59' : '#666',
                                cursor: 'pointer'
                            }}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

// Loading state component
const LoadingState = () => (
    <div className="min-vh-100 bg-dark">
        <Header showSideBarMenu={true} showDashboardMenu={false} height={56} showCredits={true} />
        <Container fluid className="py-4">
            <div className="text-white">Loading...</div>
        </Container>
    </div>
);

// Error state component
const ErrorState = () => (
    <div className="min-vh-100 bg-dark">
        <Header showSideBarMenu={true} showDashboardMenu={false} height={56} showCredits={true} />
        <Container fluid className="py-4">
            <div className="text-white">App not found</div>
        </Container>
    </div>
);

// Back button component
const BackButton = ({ onBack }) => (
    <Button
        variant="warning"
        onClick={onBack}
        className="d-flex align-items-center gap-2"
        style={{ 
            backgroundColor: '#f5bc59', 
            borderColor: '#f5bc59',
            padding: '8px 16px',
            fontSize: '1rem',
            minWidth: 'fit-content'
        }}
    >
        <span style={{ fontSize: '1.2rem', lineHeight: 1 }}>←</span>
        <span>Back</span>
    </Button>
);

// App header section component
const AppHeader = ({ app, user, onShowLicense, openDropdownId, onDropdownToggle, onDeleteClick, setApp, appId, onStatusChange }) => (
    <div className="d-flex justify-content-between align-items-start flex-grow-1" style={{ minWidth: 0 }}>
        <div className="d-flex gap-3" style={{ minWidth: 0, maxWidth: 'calc(100% - 140px)' }}>
            <CompanyLogo logo={app.CompanyLogo} company={app.Company} />
            <div style={{ minWidth: 0 }}>
                <h1 className="text-white mb-2" style={{ fontSize: '2rem' }}>{app.Title}</h1>
                <div className="d-flex flex-wrap gap-2">
                    <div style={{ color: '#f5bc59' }}>{app.Company}</div>
                    {Boolean(app.HighSideCompatible) && <HighSideCompatibilityBadge />}
                </div>
            </div>
        </div>
        <AppActions 
            app={app}
            user={user}
            onShowLicense={() => onShowLicense()}
            onDeleteClick={() => onDeleteClick()}
            openDropdownId={openDropdownId}
            onDropdownToggle={(e, id) => onDropdownToggle(e, id)}
            setApp={(updater) => setApp(updater)}
            appId={appId}
            onStatusChange={() => onStatusChange()}
        />
    </div>
);

// Description section component
const DescriptionSection = ({ description }) => (
    <div className="mb-4">
        <h2 className="text-white mb-3" style={{ fontWeight: 'normal' }}>Description</h2>
        <div style={{ backgroundColor: 'rgba(51, 51, 51, 0.95)' }} className="rounded p-4">
            <div 
                className="text-white" 
                style={{ lineHeight: '1.5' }}
                dangerouslySetInnerHTML={{ __html: description }}
            />
        </div>
    </div>
);

// What's New section component
const WhatsNewSection = ({ version, updatedAt, releaseNotes }) => (
    <div className="mb-4">
        <h2 className="text-white mb-3" style={{ fontWeight: 'normal' }}>What's New</h2>
        <div style={{ backgroundColor: 'rgba(51, 51, 51, 0.95)' }} className="rounded p-4">
            <div className="d-flex align-items-center gap-3 mb-3">
                {version && (
                    <>
                        <span className="text-white">Version {version}</span>
                        <span className="text-white opacity-75">|</span>
                    </>
                )}
                {updatedAt && (
                    <span className="text-white">
                        {new Date(updatedAt).toLocaleDateString('en-US', { 
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                        })}
                    </span>
                )}
            </div>
            {releaseNotes && (
                <div className="text-white ps-3">
                    {releaseNotes.split('\n').map((note, index) => (
                        <div key={`release-note-${btoa(note.substring(0, 20))}`}>- {note}</div>
                    ))}
                </div>
            )}
        </div>
    </div>
);

// Delete confirmation modal component
const DeleteConfirmationModal = ({ show, onHide, onConfirm }) => (
    <Modal
        show={show}
        onHide={onHide}
        centered
        backdrop="static"
        style={{ color: 'white' }}
    >
        <Modal.Header closeButton style={{ backgroundColor: '#2a2a2a', borderBottom: '1px solid #3a3a3a' }}>
            <Modal.Title>Remove Application</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: '#2a2a2a' }}>
            Are you sure you want to remove this application from your account?
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: '#2a2a2a', borderTop: '1px solid #3a3a3a' }}>
            <Button variant="secondary" onClick={onHide}>Cancel</Button>
            <Button variant="danger" onClick={onConfirm}>Remove</Button>
        </Modal.Footer>
    </Modal>
);

// Main SamApp component
const SamApp = () => {
    const [appState, setAppState] = useState({
        app: null,
        loading: true,
        currentImageIndex: 0,
        showDeleteModal: false,
        showRefreshModal: false,
        openDropdownId: null,
        showLicenseModal: false,
        showPurchaseModal: false
    });
    
    const { appId } = useParams();
    const navigate = useNavigate();
    const { getData, postData, user } = useContext(AppState);

    // Fetch app details
    useEffect(() => {
        const fetchAppDetails = async () => {
            if (!appId) return;

            try {
                const [catalogData, appDetails, userApps] = await Promise.all([
                    getData('apps'),
                    getData(`apps/${appId}`),
                    user.isGuest ? null : getData('user-apps')
                ]);
                
                const catalogApp = catalogData.find(app => app.Id === parseInt(appId));
                const userApp = userApps?.find(ua => ua.AppId === parseInt(appId));
                
                const mergedApp = mergeAppData(
                    appDetails,
                    catalogApp,
                    Boolean(userApp),
                    Boolean(userApp?.Installed),
                    Boolean(userApp?.Pinned)
                );

                setAppState(prev => ({
                    ...prev,
                    app: mergedApp,
                    loading: false
                }));
            } catch (error) {
                console.error("Error fetching app details:", error);
                setAppState(prev => ({ ...prev, loading: false }));
            }
        };

        fetchAppDetails();
    }, [appId, getData, user.isGuest]);

    const handleStateUpdate = (updates) => {
        setAppState(prev => ({ ...prev, ...updates }));
    };

    const handlePurchaseSuccess = async () => {
        if (appState.app?.Price > 0) {
            const updatedCredits = await postData('user-credits', { credits: appState.app.Price });
            if (!updatedCredits) throw new Error('Failed to update credits');
        }
        
        const purchaseResult = await postData(`apps/${appState.app.Id}/purchase`);
        if (purchaseResult?.ok) {
            handleStateUpdate({
                app: { ...appState.app, isPurchased: true },
                showPurchaseModal: false
            });
        } else {
            throw new Error('Purchase failed');
        }
    };

    const handleDeleteConfirm = async () => {
        try {
            await postData(`user-apps/${appState.app.Id}`, {}, 'DELETE');
            handleStateUpdate({
                app: {
                    ...appState.app,
                    isPurchased: false,
                    isInstalled: false,
                    isPinned: false
                },
                showDeleteModal: false
            });
        } catch (error) {
            handleStateUpdate({ showDeleteModal: false });
        }
    };

    if (appState.loading) return <LoadingState />;
    if (!appState.app) return <ErrorState />;

    return (
        <div className="min-vh-100 bg-dark d-flex flex-column">
            <Header showSideBarMenu={true} showDashboardMenu={false} height={56} showCredits={true} />
            
            <div className="overflow-auto" style={{ height: "calc(100vh - 56px)" }}>
                <div className="p-4 pb-2">
                    <div className="d-flex align-items-start mb-3" style={{ gap: 'clamp(2rem, 5vw, 8rem)' }}>
                        <BackButton onBack={() => navigate('/sam')} />
                        <AppHeader 
                            app={appState.app}
                            user={user}
                            onShowLicense={() => handleStateUpdate({ showLicenseModal: true })}
                            openDropdownId={appState.openDropdownId}
                            onDropdownToggle={(e, id) => handleStateUpdate({ openDropdownId: id })}
                            onDeleteClick={() => handleStateUpdate({ showDeleteModal: true })}
                            setApp={(updater) => handleStateUpdate({ app: updater(appState.app) })}
                            appId={appId}
                            onStatusChange={() => handleStateUpdate({ showRefreshModal: true })}
                        />
                    </div>
                </div>

                <Container fluid className="px-4">
                    <ImageCarousel 
                        images={appState.app.images}
                        currentImageIndex={appState.currentImageIndex}
                        onImageChange={(index) => handleStateUpdate({ currentImageIndex: index })}
                    />

                    <DescriptionSection description={appState.app.DescriptionLong} />
                    
                    <WhatsNewSection 
                        version={appState.app.Version}
                        updatedAt={appState.app.UpdatedAt}
                        releaseNotes={appState.app.ReleaseNotes}
                    />

                    {(appState.app.Company || appState.app.CompanyLogo) && (
                        <div className="mb-4">
                            <h2 className="text-white mb-3" style={{ fontWeight: 'normal' }}>About {appState.app.Company}</h2>
                            <div style={{ backgroundColor: 'rgba(51, 51, 51, 0.95)' }} className="rounded p-4">
                                <div className="d-flex gap-4 align-items-start">
                                    {appState.app.CompanyLogo && (
                                        <img 
                                            src={`data:image/png;base64,${appState.app.CompanyLogo}`}
                                            alt={`${appState.app.Company} logo`}
                                            style={{ 
                                                width: '120px',
                                                height: '120px',
                                                objectFit: 'contain'
                                            }}
                                        />
                                    )}
                                    <div>
                                        {appState.app.CompanyDescription ? (
                                            <div 
                                                className="text-white" 
                                                style={{ lineHeight: '1.5' }}
                                                dangerouslySetInnerHTML={{ __html: appState.app.CompanyDescription }}
                                            />
                                        ) : (
                                            <div className="text-white" style={{ lineHeight: '1.5' }}>
                                                {appState.app.Company} is a software provider for the Space Application Marketplace.
                                            </div>
                                        )}
                                        {appState.app.CompanyLink && (
                                            <a 
                                                href={appState.app.CompanyLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-decoration-none"
                                                style={{ color: '#f5bc59' }}
                                            >
                                                Visit Website →
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Container>
            </div>

            <DeleteConfirmationModal 
                show={appState.showDeleteModal}
                onHide={() => handleStateUpdate({ showDeleteModal: false })}
                onConfirm={handleDeleteConfirm}
            />

            <LicenseAgreementModal
                show={appState.showLicenseModal}
                onHide={() => handleStateUpdate({ showLicenseModal: false })}
                onAccept={() => handleStateUpdate({ showLicenseModal: false, showPurchaseModal: true })}
            />

            <PurchaseConfirmationModal
                show={appState.showPurchaseModal}
                onHide={() => handleStateUpdate({ showPurchaseModal: false })}
                onConfirm={handlePurchaseSuccess}
                app={appState.app}
            />

            <RefreshNotificationModal
                show={appState.showRefreshModal}
                onHide={() => handleStateUpdate({ showRefreshModal: false })}
            />
        </div>
    );
};

export default SamApp;
