import React, { useCallback, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { MdClose } from "react-icons/md";

const parseList = (items) => {
    return Array.from(new Set(
        items.split(",")
            .map((item) => item.trim())
            .filter((item) => item.length > 0)
        ));
}

const validList = (items, validator) => {
    return items && parseList(items).every(validator);
};

const MultiValueInput = ({ 
    typeText = "Satellites",
    selectedItems, setSelectedItems,
    placeholder="Search by name or ID...",
    validator=() => true
}) => {
    const [searchTerm, setSearchTerm] = useState("");

    const onAdd = useCallback(() => {
        if(searchTerm) {
            setSelectedItems((items) => 
                [...items, ...parseList(searchTerm).filter((item) => validator(item) && !selectedItems.includes(item))]);
        }
    }, [searchTerm, selectedItems, setSelectedItems, validator]);

    return <>
        <Form.Group className="mb-3">
            <Form.Label htmlFor="item-search">
                {typeText} 
                <small className="text-white-50 ms-2">
                    ({selectedItems.length} selected)
                </small>
            </Form.Label>
            <div className="d-flex justify-content-between align-items-center">
                <Form.Control
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyDown={(e) => {
                        if(e.key === "Enter") {
                            onAdd();
                        }
                    }}
                    placeholder={placeholder}
                    className="bg-dark text-white"
                    data-testid="multi-value-input"
                    isInvalid={searchTerm && (!validList(searchTerm, validator))}
                />
                <Button 
                    variant="primary"
                    onClick={() => onAdd()}
                    className="ms-3"
                    style={{
                        flexShrink: 0
                    }}
                >
                    Add
                </Button>
            </div>
        </Form.Group>
        <div className={selectedItems.length > 0 ? "mb-3" : ""}>
            {selectedItems.map((item) => (
                <div key={item} className="modal-tag">
                    <span>{item}</span> 
                    <MdClose 
                        className="modal-tag-remove"
                        data-testid="modal-tag-remove"
                        onClick={() => setSelectedItems((items) => items.filter((it) => it !== item))}
                    >X</MdClose>
                </div>
            ))}
        </div>
    </>;
};

export default MultiValueInput;