import React, { useState, useEffect, useMemo } from "react";
import { Form, Table } from "react-bootstrap";

const ModalSearchSection = ({ 
    typeText = "Satellites",
    itemOptions,
    selectedItems, setSelectedItems,
    filterFunction,
    placeholder="Search by name or ID...",
    columns=["SatNo", "Name"],
    idFunc = (item) => item.id,
    columnFunc = (sat) => [sat.id, sat.name]
}) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
    
    // Debounce search term updates
    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300); // 300ms delay

        return () => clearTimeout(timer);
    }, [searchTerm]);

    // Memoize the filtered items
    const filteredItems = useMemo(() => {
        return filterFunction(itemOptions, selectedItems, debouncedSearchTerm);
    }, [debouncedSearchTerm, selectedItems, itemOptions, filterFunction]);

    const getSearchLabelText = () => {
        if (filteredItems.length > 0) {
            return `(${filteredItems.length} matches)`;
        }
        if (selectedItems?.length > 0) {
            return `(${selectedItems.length} selected)`;
        }
        if (!debouncedSearchTerm || debouncedSearchTerm.length < 2) {
            return "(enter at least 2 characters to search)";
        }
        return "(0 matches)";
    };

    const handleSelectAll = () => {
        const filteredIds = filteredItems.map(idFunc);
        const allSelected = filteredIds.every(id => selectedItems?.includes(id));
        
        if (allSelected) {
            // Remove all filtered items from selection
            setSelectedItems(prev => 
                prev.filter(id => !filteredIds.includes(id))
            );
        } else {
            // Add all filtered items to selection
            setSelectedItems(prev => 
                [...new Set([...(prev ?? []), ...filteredIds])]
            );
        }
    };

    const handleCheckboxChange = (satId) => {
        setSelectedItems(prev => 
            prev?.includes(satId)
                ? prev.filter(id => id !== satId)
                : [...(prev ?? []), satId]
        );
    };

    const renderTableBody = () => {
        if (filteredItems.length === 0) {
            return (
                <tr>
                    <td role="cell" colSpan="3" className="text-center">
                        No matches found
                    </td>
                </tr>
            );
        }
        
        return filteredItems.map((item) => {
            const id = idFunc(item);
            return (
                <tr key={id}>
                    <td role="cell" className="text-center">
                        <Form.Check
                            type="checkbox"
                            checked={selectedItems?.includes(id) ?? false}
                            onChange={() => handleCheckboxChange(id)}
                            aria-label={`Select sat ${id}`}
                            id={`select-item-${id}`}
                            data-testid={`select-item-${id}`}
                        />
                    </td>

                    {columnFunc(item).map((val) => 
                        <td key={`td-${id}-${val}`} role="cell"
                        onClick={() => handleCheckboxChange(id)}>{val}</td>)}
                </tr>
            );
        });
    };

    return <>
        <Form.Group className="mb-3">
            <Form.Label htmlFor="item-search">
                Search {typeText} 
                <small className="text-white-50 ms-2">
                    {getSearchLabelText()}
                </small>
            </Form.Label>
            <Form.Control
                id="item-search"
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder={placeholder}
                className="bg-dark text-white"
                aria-label="Search items"
            />
        </Form.Group>
        <div style={{ maxHeight: "300px", overflowY: "auto" }} className="mb-3">
            <Table striped bordered hover variant="dark" className="search-table" data-testid={"search-table-"+typeText}>
                <thead style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#212529" }}>
                    <tr >
                        <th style={{ width: "50px" }}>
                            {filteredItems.length > 0 && (
                                <Form.Check
                                    type="checkbox"
                                    checked={filteredItems.length > 0 && 
                                        filteredItems.every(item => {
                                            return selectedItems?.includes(idFunc(item));
                                        })}
                                    onChange={handleSelectAll}
                                    className="text-center"
                                    aria-label="Select all items"
                                    id="select-all-checkbox"
                                />
                            )}
                        </th>
                        {columns.map((col) => <th key={`th-${col}`} scope="col">{col}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {renderTableBody()}
                </tbody>
            </Table>
        </div>
    </>;
};

export default ModalSearchSection;