export const processAlerts = (alerts) => {
    if (!alerts || !Array.isArray(alerts)) {
        return { blueAlerts: [], redAlerts: [] };
    }

    const blueAlerts = alerts.filter(alert => alert.Team === 'Blue');
    const redAlerts = alerts.filter(alert => alert.Team === 'Red');

    return { blueAlerts, redAlerts };
};

export const getAlertSats = (alert) => {
    return alert.Data.satnos;
};

export const hasSatNo = (array) => {
    return parseInt(array?.[0]) >= 0;
};

export const getAlertTimeRange = (lookbackDays) => {
    const now = new Date();
    const start = new Date();
    start.setDate(now.getDate() - lookbackDays);
    start.setHours(0, 0, 0, 0);

    return {
        start_timestamp: Math.floor(start.getTime()),
        end_timestamp: Math.floor(now.getTime())
    };
}; 