import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const AlertModal = ({ 
    show, 
    onHide, 
    onConfirm, 
    acknowledging 
}) => {
    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            backdrop="static"
            data-bs-theme="dark"
            className="text-white"
        >
            <Modal.Header closeButton className="bg-dark" data-testid="alert-modal-header">
                <Modal.Title>Confirm Acknowledgment</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-dark" data-testid="alert-modal-body">
                Acknowledging this alert will clear it from your list of alerts, are you sure?
            </Modal.Body>
            <Modal.Footer className="bg-dark" data-testid="alert-modal-footer">
                <Button 
                    variant="secondary" 
                    onClick={onHide}
                >
                    Cancel
                </Button>
                <Button 
                    variant="success" 
                    onClick={onConfirm}
                    disabled={acknowledging}
                >
                    {acknowledging ? 'Acknowledging...' : 'Yes, Acknowledge'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AlertModal; 