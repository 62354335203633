import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { AppState } from './AppProvider';
export const SatConfigureState = createContext();

export const SatConfigureDefaults = {
  "TRIC Manual": {numSats: 2, dataType: "All", default: [
    {id: "47967", name: "M2-A"},
    {id: "47973", name: "M2-B"}
  ]},
  "Residuals": {numSats: 1, dataType: "All", default: [
    {id: "44910", name: "SJ-20"}
  ]},
  "RPO": {numSats: 1, dataType: "TLE", default: [
    {id: "40258", name: "LUCH (OLYMP)"}
  ]},
};

const getSatsFromLink = (numSats, currentDashboardLink, getSatName) => {
  if(!currentDashboardLink) return null;

  const params = new URL(currentDashboardLink).searchParams;
  let satnos;
  if(numSats < 0) {
    satnos = params.get("var-satnos")?.split(",");
  } 
  else {
    satnos = [...new Array(numSats)].map((e, i) => params.get("var-satno"+(i === 0 ? "" : (i+1))));
  }

  const result = satnos?.map((s) => {
    if(s?.length > 0) {
      const satno = s.split(" - ")[0].trim();
      return {id: satno, name: getSatName(satno)};
    }
    return null;
  })?.filter((s) => s);

  return result?.length > 0 ? result : null;
}

function SatConfigureProvider({ children, satConfigDefault=null, dashboardName=null, currentDashboardLink=null }) {

  const [showConfigureModal, setShowConfigureModal] = useState(false);
  const [currentSatConfig, setCurrentSatConfig] = useState(null);
  const [keyToOpen, setKeyToOpen] = useState(null);
  const {getSatName} = useContext(AppState);

  useEffect(() => {
    const def = SatConfigureDefaults[dashboardName];
    setCurrentSatConfig(def ? {
      numSats: def.numSats, 
      dataType: def.dataType,
      satellites: getSatsFromLink(def.numSats, currentDashboardLink, getSatName) ?? def.default
    } : satConfigDefault);
  }, [dashboardName, satConfigDefault, currentDashboardLink, getSatName]);

  const store = useMemo(() => ({
    showConfigureModal, setShowConfigureModal, 
    currentSatConfig, setCurrentSatConfig,
    keyToOpen, setKeyToOpen
  }), [showConfigureModal, setShowConfigureModal, 
        currentSatConfig, setCurrentSatConfig, 
        keyToOpen, setKeyToOpen]);
  
  return (  
    <SatConfigureState.Provider value={store}>
      { children }
    </SatConfigureState.Provider>
  );
};

export default SatConfigureProvider;
