import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';

// Reusable react-select theme and styles (copied from CountrySelectionSettings for consistency)
const selectTheme = (theme) => ({
    // ... theme colors ... same as before
    ...theme,
    colors: {
        ...theme.colors,
        primary: '#2a2d30',       // Border color on focus
        primary25: '#1a1d20',    // Option hover background
        neutral0: '#2a2d30',     // Control background
        neutral80: 'white',     // Input text color
        neutral20: '#495057',  // Border and dropdown indicator color
        neutral30: '#495057',  // Border hover color
        danger: '#dc3545',      // Multi-value remove button background
        dangerLight: '#ffbd07' // Multi-value remove button hover background
    },
});

const selectStyles = {
    // ... select styles ... same as before
    control: (base) => ({
        ...base,
        backgroundColor: '#2a2d30',
        borderColor: '#495057',
        color: 'white',
    }),
    menu: (base) => ({
        ...base,
        backgroundColor: '#2a2d30',
    }),
    option: (base, state) => ({
        ...base,
        backgroundColor: state.isFocused ? '#1a1d20' : '#2a2d30',
        color: 'white',
    }),
    multiValue: (base) => ({
        ...base,
        backgroundColor: '#1a1d20', // Background of the multi-value container
    }),
    multiValueLabel: (base) => ({
        ...base,
        color: 'white', // Text color of the multi-value label
    }),
    multiValueRemove: (base) => ({
        ...base,
        color: '#adb5bd', // Color of the 'x' icon
        ':hover': {
            backgroundColor: '#dc3545', // Background on hover (danger color)
            color: 'white',
        },
    }),
    input: (base) => ({
        ...base,
        color: 'white',
    }),
    placeholder: (base) => ({
        ...base,
        color: '#adb5bd', // Placeholder text color
    }),
    singleValue: (base) => ({
        ...base,
        color: 'white',
    }),
};

const CountrySelectionModal = ({ show, onHide, countryMap, initialRedCountries, initialBlueCountries, onSave }) => {
    const [selectedRed, setSelectedRed] = useState([]);
    const [selectedBlue, setSelectedBlue] = useState([]);

    // Generate options from the map: { value: code, label: name }
    const countryOptions = Object.entries(countryMap).map(([code, name]) => ({ value: code, label: name }));

    // Map initial country codes to {value, label} format for react-select
    const mapCodesToOptions = useCallback((codes) => {
        return codes?.map(code => ({ value: code, label: countryMap[code] || code })) || [];
    }, [countryMap]);

    useEffect(() => {
        if (show) {
            setSelectedRed(mapCodesToOptions(initialRedCountries));
            setSelectedBlue(mapCodesToOptions(initialBlueCountries));
        }
    }, [show, initialRedCountries, initialBlueCountries, countryMap, mapCodesToOptions]);

    const handleSave = () => {
        const redValues = selectedRed.map(option => option.value);
        const blueValues = selectedBlue.map(option => option.value);
        onSave(redValues, blueValues);
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide} centered backdrop="static" data-bs-theme="dark">
            <Modal.Header closeButton>
                <Modal.Title className="text-white">Edit Country Assignments</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-3">
                    <label htmlFor="modalRedCountriesSelect" className="form-label text-white">Red Countries</label>
                    <Select
                        id="modalRedCountriesSelect"
                        isMulti
                        options={countryOptions} // Use generated options
                        value={selectedRed}
                        onChange={setSelectedRed}
                        placeholder="Select Red countries..."
                        className="react-select-container"
                        classNamePrefix="react-select"
                        theme={selectTheme}
                        styles={selectStyles}
                    />
                </div>
                <div>
                    <label htmlFor="modalBlueCountriesSelect" className="form-label text-white">Blue Countries</label>
                    <Select
                        id="modalBlueCountriesSelect"
                        isMulti
                        options={countryOptions} // Use generated options
                        value={selectedBlue}
                        onChange={setSelectedBlue}
                        placeholder="Select Blue countries..."
                        className="react-select-container"
                        classNamePrefix="react-select"
                        theme={selectTheme}
                        styles={selectStyles}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

CountrySelectionModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    countryMap: PropTypes.objectOf(PropTypes.string).isRequired, // Changed from allCountries array to countryMap object
    initialRedCountries: PropTypes.arrayOf(PropTypes.string),
    initialBlueCountries: PropTypes.arrayOf(PropTypes.string),
    onSave: PropTypes.func.isRequired,
};

export default CountrySelectionModal; 