import { AppState } from '../lib/context/AppProvider';
import { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { Button, Form, InputGroup, Row, Modal } from 'react-bootstrap';
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import logo from "../lib/assets/img/spacecockpit.png";
import Cookies from 'js-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../styles/Login.css';
import { render } from '../lib/Background.js';
import Footer from '../components/Footer.js';
import { FaRegAddressCard } from "react-icons/fa";
import { LOGIN_EXPIRATION } from '../util/Utils.js';
  
export const setGuestCookie = () => {
  const cookieDomain = window.location.hostname;
  Cookies.set('SBMS_GUEST', true, { secure: true, sameSite:'Strict', domain: cookieDomain});
};

export const hasGuestCookie = () => {
  return Cookies.get('SBMS_GUEST');
};

export const clearGuestCookie = () => {
  const cookieDomain = window.location.hostname;
  Cookies.remove('SBMS_GUEST', { domain: cookieDomain });
};

function Login() {
  const {user, setUser, pendingRedirect, getBaseUrl} = useContext(AppState);
  const [attemptingLogin, setAttemptingLogin] = useState(false);
  const attemptingLoginRef = useRef(false);
  const [inputUsername, setInputUsername] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const [renderRef, setRenderRef] = useState(null);
  const loginInProgress = useRef(false);
  const [showCACInfo, setShowCACInfo] = useState(false);

  const location = useLocation();
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
      setValidated(true);
      return;
    }
    
    if (attemptingLogin) return;
    setAttemptingLogin(true);
  
    const toastId = toast.loading('Logging in...');
    
    try {
      await login(inputUsername, inputPassword);
      toast.update(toastId, { 
        render: 'Login Successful!', 
        type: 'success',
        isLoading: false,
        autoClose: 3000
      });
    } catch (error) {
      toast.update(toastId, { 
        render: `Login failed: ${error.message}`, 
        type: 'error',
        isLoading: false,
        autoClose: 3000
      });
      console.error('Login failed:', error);
    } finally {
      setAttemptingLogin(false);
    }
  };

  const handleCACLogin = async () => {
    if (attemptingLogin) return;
    setAttemptingLogin(true);
    
    const toastId = toast.loading('Authenticating with CAC...');
    
    try {
      await login(null, null, true);
      toast.update(toastId, { 
        render: 'Login Successful!', 
        type: 'success',
        isLoading: false,
        autoClose: 3000
      });
    } catch (error) {
      toast.update(toastId, { 
        render: `CAC Login failed: ${error.message}`, 
        type: 'error',
        isLoading: false,
        autoClose: 3000
      });
      console.error('CAC Login failed:', error);
    } finally {
      setAttemptingLogin(false);
    }
  };

  const handleRedirect = useCallback(() => {
    const redir = pendingRedirect.current;
    if(redir && redir !== "/" && redir !== "" && redir.startsWith("/")) { // ensure local path
      navigate(pendingRedirect.current, { replace: true });
    }
    else {
      const query = location.search?.length > 0 ? location.search : "";
      navigate('/home'+query, { replace: true });
    }
    
    pendingRedirect.current = null;
  }, [location.search, navigate, pendingRedirect]);

  const login = useCallback(async (username, password, isCAC = false) => {
    if (loginInProgress.current) {
      return;
    }
    
    loginInProgress.current = true;
    
    try {
      if(hasGuestCookie()) {
        // guest bypass
        setUser({
          loggedIn: true,
          isGuest: true,
          access_token: "",
          username: "Guest",
          lastLoggedIn: Date.now(),
        });
      }
      else {
        // normal login
        const headers = {
          "Content-Type": "application/json"
        };
        if (username) headers["username"] = username;
        if (password) headers["password"] = password;

        headers["mmlogin"] = true; // request mattermost login

        const baseUrl = getBaseUrl(isCAC);
        const timestamp = new Date().getTime();
        const response = await fetch(`${baseUrl}/login?nocache=${timestamp}`, {
          method: 'POST',
          credentials: 'include',
          headers: headers,
        });
    
        const data = await response.json();

        if (!response.ok) {
          throw new Error(`${data.message || 'Unknown error'}`); // Use the JSON data for error message
        }

        setUser({
          loggedIn: true,
          uuid: data.uuid,
          access_token: data.jwt,
          CacId: data.cacId,
          isGuest: false,
          username: data.username,
          lastLoggedIn: Date.now(),
        });
      }
      
      handleRedirect();

      // Stop the animation after successful login
      if (renderRef) {
        renderRef.stop();
      }
    } catch (error) {
      console.error('Login request failed:', error);
      throw error;
    } finally {
      loginInProgress.current = false;
    }
  }, [handleRedirect, renderRef, setUser, getBaseUrl]);

  const loginAsGuest = useCallback(async () => {
    setGuestCookie();
    await login();
  }, [login]);

  // If the user has a stored token, attempt to login
  useEffect(() => {
    attemptingLoginRef.current = attemptingLogin;
  }, [attemptingLogin]);

  useEffect(() => {
    if ((!user.loggedIn || (Date.now() - user.lastLoggedIn) >= LOGIN_EXPIRATION) && 
      (Cookies.get('has_access_token') || hasGuestCookie()) 
        && !attemptingLoginRef.current && !loginInProgress.current) {
      const loginPromise = login();
      toast.promise(loginPromise, {
        loading: 'Logging in...',
        success: 'Login Successful!',
        autoClose: 3000,
        error: (err) => {
          return `Login failed: ${err.message}`;
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login]);


  useEffect(() => {
    const renderObject = render();
    setRenderRef(renderObject);
    
    // Add cleanup function to remove canvas and stop animation on unmount
    return () => {
      const canvas = document.getElementById('starfield');
      if (canvas) {
        canvas.remove();
      }
      if (renderObject) {
        renderObject.stop();
      }
    };
  }, []);

  const handleShowCACInfo = (event) => {
    event.preventDefault(); // Prevent form submission
    setShowCACInfo(true);
  };

  const handleCloseCACInfo = () => setShowCACInfo(false);

  return (
    <div className="loginscreen">
      <div className="place-center text-light" style={{
        maxWidth: "80%",
        marginTop: "-40px" // half the footer
      }}>
        <div className="d-flex flex-column flex-md-row align-items-center justify-content-center" style={{
          width: "100%"
        }}>
          <img className="login-logo" src={logo} alt="logo" width={350} style={{
            maxWidth: "90%"
          }}/>
          <div className="text-center pb-1 ms-md-3 mt-1 mt-md-0" style={{fontSize: "1.5rem"}}>
            Space Cockpit <br/>
            Battle Management System

          </div>
        </div>
        <Form 
          className="pt-4 responsive-form" 
          noValidate 
          validated={validated} 
          onSubmit={handleSubmit} 
          data-testid="login-form"
          style={{
            maxWidth: "100%"
          }}
        >
          <Row className="p-2">
            <Form.Group className="nav-loginform-input" controlId="formBasicUsername"
                onChange={(e) => setInputUsername(e.target.value)}>
               
                <Form.Control 
                    required
                    type="text"
                    name="username"
                    autoComplete="username"
                    placeholder="Username"
                    className="text-light" />
                <Form.Control.Feedback type="invalid">
                    Enter username.
                </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="p-2 pt-3">
            <Form.Group className="nav-loginform-input" controlId="formBasicPassword"
                onChange={(e) => setInputPassword(e.target.value)}>
            
                <InputGroup hasValidation >
                    <Form.Control 
                        required
                        name="password"
                        autoComplete="current-password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Password" 
                        className="text-light"
                    />
                    <Button 
                        variant="outline-secondary"
                        onClick={() => setShowPassword((prev) => !prev)}
                        aria-label="Toggle password visibility">
                        {showPassword
                            ? <i className="bi bi-eye-slash-fill" 
                                 style={{color: "white"}} 
                                 data-testid="eye-icon-hidden" 
                                 aria-hidden="true"/>
                            : <i className="bi bi-eye-fill" 
                                 style={{color: "white"}} 
                                 data-testid="eye-icon-visible" 
                                 aria-hidden="true"/>
                        }
                    </Button>
                    <Form.Control.Feedback type="invalid">
                        Enter password.
                    </Form.Control.Feedback>
                </InputGroup>
            </Form.Group>
          </Row>
          <Row className="place-center" style={{padding: "20px", paddingTop: "min(2vh, 48px)"}}>
            <Button variant="warning" type="submit" disabled={attemptingLogin}>
              { attemptingLogin
                ? <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </>
                : "Login"}
            </Button>

            <Button 
              variant="secondary" 
              onClick={handleCACLogin} 
              disabled={attemptingLogin}
              className="mt-2"
            >
              {attemptingLogin ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <>
                  Login with CAC / PKI
                  <FaRegAddressCard className="ms-2" />
                </>
              )}
            </Button>

            <div className="mt-2 p-0 text-center responsive-font">
            Need to link your CAC?  
              <button 
                onClick={handleShowCACInfo}
                style={{ 
                  background: 'none', 
                  border: 'none', 
                  color: '#ffc107',
                  textStyle: 'center',
                  textDecoration: 'underline', 
                  cursor: 'pointer' 
                }}
              >
                Click here
              </button>
            </div>

            <div className="mt-2 p-0 text-center responsive-font">
              Don't have an account? 
              <button 
                onClick={loginAsGuest}
                style={{ 
                  background: 'none', 
                  border: 'none', 
                  color: '#ffc107',
                  textStyle: 'center',
                  textDecoration: 'underline', 
                  cursor: 'pointer' 
                }}
              >
                Proceed as a guest user
              </button>
            </div>
          </Row>
        </Form>
      </div>
      <Footer/>

      <Modal show={showCACInfo} onHide={handleCloseCACInfo} dialogClassName="modal-dialog-centered" className="text-white">
        <Modal.Header closeButton className="bg-dark">
          <Modal.Title>How to link my CAC/PKI Card.</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark">
          You must first login using your UDL credentials. Then go to <b>Settings</b>, and click on the <b>"Link your CAC/PKI Card"</b> button.
        </Modal.Body>
        <Modal.Footer className="bg-dark">
          <Button variant="secondary" onClick={handleCloseCACInfo}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Login;