import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';

const InfoTooltip = ({ text, placement = "right" }) => {
    return (
        <OverlayTrigger
            placement={placement}
            overlay={
                <Tooltip>
                    {text}
                </Tooltip>
            }
        >
            <i 
                className="bi bi-info-circle text-info ms-2" 
                style={{ cursor: 'help' }}
                data-testid="info-icon"
            ></i>
        </OverlayTrigger>
    );
};

InfoTooltip.propTypes = {
    text: PropTypes.string.isRequired,
    placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left'])
};

export default InfoTooltip; 