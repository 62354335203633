// Calculate max height for components
export const getMaxHeight = (total, full) => {
    const headerHeight = 56;
    const topPadding = 12;
    const bottomPadding = 12;
    const accordionHeaderHeight = 68;
    const accordionMargin = 16;
    const accordionBorder = 2;
    const accordionPadding = 16;

    const fullHeight = `calc(100vh - ${headerHeight}px - ${topPadding}px - ${bottomPadding}px)`;
    const sectionHeight = `(${fullHeight} - ${accordionHeaderHeight * total}px - ${accordionMargin * total}px - ${accordionBorder * total}px - ${accordionPadding * total}px)`;
    
    return full > 0 ? `(${sectionHeight}/${full})` : sectionHeight;
};

// Calculate active sections
export const calcActive = (activeKeys, notsoCount, redCount, blueCount, launchCount) => {
    let total = 0;
    let full = 0;

    if (activeKeys.includes('notsos') && notsoCount > 0) {
        total++;
        full++;
    }

    if (activeKeys.includes('red-alerts') && redCount > 0) {
        total++;
        full++;
    }

    if (activeKeys.includes('blue-alerts') && blueCount > 0) {
        total++;
        full++;
    }

    if (activeKeys.includes('launches') && launchCount > 0) {
        total++;
        full++;
    }

    if (activeKeys.includes('space-weather')) {
        total++;
        full++;
    }

    return { total, full };
}; 