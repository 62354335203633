export const LOGIN_EXPIRATION = 30 * 60 * 1000; // 30 minutes

export const getDomainUrl = (subdomain) => {
    const isLocalDev = window.location.hostname === 'localhost';
    if (isLocalDev && (subdomain === 'spacecockpit' || subdomain === 'probe')) {
        return `https://${subdomain}.saberdevusa.xyz`;
    }
    if (isLocalDev && subdomain === 'spacefana') {
        return `http://localhost:3000`;
    }
    return window.location.origin.replace("sbms", subdomain);
};

export const plural = (word, count) => count === 1 ? word : `${word}s`;

export const checkMessageOrigin = (event) => {
    const spacefanaURL = getDomainUrl('spacefana');
    if(event.origin !== spacefanaURL) {
        console.warn("Got spacefana params response but from incorrect origin", event.origin, "vs", spacefanaURL);
        return false;
    }
    return true;
};

export const isValidEmail = (email) => {
    // simplified and doesn't use regex 
    // because of sonarqube "vulnerable to super-linear runtime due to backtracking" warning
    if(!email) return false;
    const i1 = email.indexOf("@");
    if(i1 <= 0) return false;
    const sub = email.substring(i1);
    const i2 = sub.indexOf(".");
    return i2 > 0 && i2 < sub.length-1;
};