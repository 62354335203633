import React, { useState, useEffect, useContext } from "react";
import { AppState } from "../lib/context/AppProvider";
import Header from "../components/Header";
import "../styles/page.css";
import ConstellationSettingsGroup from "../components/settings/ConstellationSettingsGroup";
import { Accordion, Button, ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import SettingsGroup, { GROUP_SECTION_CLASS } from "../components/settings/SettingsGroup";
import ProfileModal from "../components/settings/ProfileModal";
import CountrySelectionModal from "../components/settings/CountrySelectionModal";
import InfoTooltip from "../components/common/InfoTooltip";
import { plural } from "../util/Utils";
import { DASHBOARDS } from "../lib/context/DashboardProvider";

// Define country code map
const COUNTRY_CODE_MAP = {
  "US": "United States", "CN": "China", "RU": "Russia", "ITSO": "Intl Telecommunications Sat Org", "AB": "ArabSat", 
  "AC": "AsiaSat", "AE": "United Arab Emirates", "AO": "Angola", "AR": "Argentina", "AT": "Austria", "AU": "Australia", 
  "AZ": "Azerbaijan", "BD": "Bangladesh", "BE": "Belgium", "BG": "Bulgaria", "BM": "Bermuda", "BO": "Bolivia", 
  "BR": "Brazil", "BY": "Belarus", "CA": "Canada", "CH": "Switzerland", "CHBZ": "China/Brazil", "CL": "Chile", 
  "CO": "Colombia", "CR": "Costa Rica", "CZ": "Czech Republic", "DE": "Germany", "DK": "Denmark", "DZ": "Algeria", 
  "EC": "Ecuador", "EE": "Estonia", "EG": "Egypt", "ES": "Spain", "ESA": "European Space Agency", 
  "EUME": "EUMETSAT", "EUTE": "EUTELSAT", "FGER": "France/Germany", "FI": "Finland", "FR": "France", 
  "FRIT": "France/Italy", "GB": "United Kingdom", "GH": "Ghana", "GLOB": "Globalstar", "GR": "Greece", 
  "HU": "Hungary", "ID": "Indonesia", "IL": "Israel", "IM": "Isle of Man", "IN": "India", "IQ": "Iraq", "IR": "Iran", 
  "ISS": "Intl Space Station", "IT": "Italy", "JO": "Jordan", "JP": "Japan", "KE": "Kenya", "KP": "North Korea", 
  "KR": "South Korea", "KW": "Kuwait", "KZ": "Kazakhstan", "LA": "Laos", "LK": "Sri Lanka", "LT": "Lithuania", 
  "LU": "Luxembourg", "MA": "Morocco", "MD": "Moldova", "MM": "Myanmar", "MN": "Mongolia", "MU": "Mauritius", 
  "MX": "Mexico", "MY": "Malaysia", "NATO": "NATO", "NG": "Nigeria", "NICO": "New ICO", "NL": "Netherlands", 
  "NO": "Norway", "NP": "Nepal", "NZ": "New Zealand", "O3B": "O3b Networks", "ORB": "Orbcomm", "PE": "Peru", 
  "PH": "Philippines", "PK": "Pakistan", "PL": "Poland", "PR": "Puerto Rico", "PT": "Portugal", "PY": "Paraguay", 
  "QA": "Qatar", "RASC": "RASCOM", "RW": "Rwanda", "SA": "Saudi Arabia", "SD": "Sudan", "SE": "Sweden", 
  "SEAL": "Sea Launch", "SES": "SES", "SG": "Singapore", "SI": "Slovenia", "STCT": "SCD (Brazil)", "TH": "Thailand", 
  "TMMC": "Turkmenistan/Monaco", "TN": "Tunisia", "TR": "Turkey", "TW": "Taiwan", "UA": "Ukraine", "USBZ": "US/Brazil", 
  "UY": "Uruguay", "VE": "Venezuela", "VN": "Vietnam", "ZA": "South Africa"
};

const accordionBodyClass = "p-0 mt-3 ps-4 pe-4 text-white";

const profileNameHeader = (name, editButtonVariant, handleEditGroup, handleDeleteGroup, handleDuplicateGroup) => (
    <div className="d-flex justify-content-between align-items-center mb-2">
        <div>
            <h6 className="mb-1 text-white">{name}</h6>
        </div>
        <div>
            <Button
                variant={editButtonVariant}
                size="sm"
                className="me-2"
                onClick={() => handleEditGroup(name)}
            >
                Edit
            </Button>
            <Button
                variant="outline-warning"
                size="sm"
                className="me-2"
                onClick={() => handleDuplicateGroup(name)}
            >
                Duplicate
            </Button>
            <Button
                variant="outline-secondary"
                size="sm"
                onClick={() => handleDeleteGroup(name)}
            >
                Delete
            </Button>
        </div>
    </div>
);

const lenOrZero = (arr) => arr?.length ?? 0;

const ProfileSettings = ({satellites, sources}) => {
    const {profiles, setProfiles} = useContext(AppState);
    
    const editButtonVariant = "outline-info";
    
    const renderGroup = (
        name, group, {handleEditGroup, handleDeleteGroup, handleDuplicateGroup}
    ) => {
        if(!group) return null;
        const selectedSats = group.satellites;
        const selectedSources = group.sources;
        const selectedDashes = group.dashboards;

        const numSats = lenOrZero(selectedSats);
        const numSources = lenOrZero(selectedSources);
        const numDashes = lenOrZero(selectedDashes);

        return (
            <div 
                key={name}
                className="p-3 mb-2 rounded"
                style={{ backgroundColor: "#1a1d20" }}
            >
                {profileNameHeader(name, editButtonVariant, handleEditGroup, handleDeleteGroup, handleDuplicateGroup)}

                {/* SATELLITES  */}
                <div className={GROUP_SECTION_CLASS}>
                    <div>
                        <small className="text-light">
                            {numSats} {plural("satellite", selectedSats?.length, numSats)}
                        </small>
                    </div>
                </div>
                {numSats > 0 && (
                    <div className="settings-group-list" >
                        {selectedSats.map(satId => {
                            const satellite = satellites.find(s => s.id === satId);
                            return (
                                <div key={satId} className="small text-white ps-2">
                                    {satellite ? `${satId} - ${satellite.name}` : satId}
                                </div>
                            );
                        })}
                    </div>
                )}

                {/* SOURCES */}
                <div className={GROUP_SECTION_CLASS}>
                    <div>
                        <small className="text-light">
                            {numSources === 0 ? "All sources" : `${numSources} ${plural("source", numSources)}`}
                        </small>
                    </div>
                </div>
                {numSources > 0 && (
                    <div className="settings-group-list">
                        {selectedSources.map(source => {
                            return (
                                <div key={source} className="small text-white ps-2">
                                    {source}
                                </div>
                            );
                        })}
                    </div>
                )}

                {/* DASHES */}
                <div className={GROUP_SECTION_CLASS}>
                    <div>
                        <small className="text-light">
                            {numDashes === (DASHBOARDS.length+1) ? "All dashboards" : `${numDashes} ${plural("dashboard", numDashes)}`}
                        </small>
                    </div>
                </div>
                {numDashes > 0 && (
                    <div className="settings-group-list">
                        {selectedDashes.map(d => {
                            return (
                                <div key={d} className="small text-white ps-2">
                                    {d}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    };

    return (
        <Accordion.Item eventKey="profiles" className="bg-dark border-0 mt-3">
            <Accordion.Header className="settings-accordion-header">
                <h4 className="settings-section-title m-0">PROFILES</h4>
            </Accordion.Header>
            <Accordion.Body className={accordionBodyClass}>

                <SettingsGroup 
                    groupType="profile"
                    title={null}
                    description={"Each profile specifies a set of satellite and source selections that can be loaded into dashboards by default. You can switch between the profiles you create on the dashboard pages, and your last selected profile will be remembered."}
                    testid="profiles"
                    typeText={"Profile"}
                    buttonVariant="info"
                    editButtonVariant="outline-info"
                    enabled={true}
                    groups={profiles}
                    setGroups={(newGroups) => setProfiles(newGroups)}
                    saveGroups={(newGroups) => setProfiles(newGroups)}
                    modalType={ProfileModal}
                    modalProps={{satellites: satellites, sources: sources}}
                    renderGroup={renderGroup}
                />
            </Accordion.Body>
        </Accordion.Item>
    );
};

const HomeSettings = () => {
    const { user, userDetails, setUserDetails, postData } = useContext(AppState);
    const options = ["Space Cockpit", ...DASHBOARDS];

    const saveSettings = async (newSettings) => {
        setUserDetails((details) => ({
            ...details,
            Settings: {
                ...details?.Settings,
                ...newSettings
            }
        }));

        if(!user.isGuest) {
            try {
                await postData("user-settings", newSettings, "PATCH");
            } catch (error) {
                console.error("Failed to update settings:", error);
                throw error;
            }
        }
    };

    return (
        <Accordion.Item eventKey="general" className="bg-dark border-0 mt-3">
            <Accordion.Header className="settings-accordion-header">
                <h4 className="settings-section-title m-0">General</h4>
            </Accordion.Header>
            <Accordion.Body className={accordionBodyClass} style={{overflow: "visible"}}>
                <div style={{
                    minHeight: "40px",
                    display: "flex",
                    alignItems: "center",
                    gap: "16px"
                }}>
                    <div style={{ width: "400px" }}>
                        <p className="text-light mb-0">Choose the panel to display on the home page:</p>
                    </div>
                    <div style={{height: "38px"}}>
                        <DropdownButton
                            data-bs-theme="dark"
                            as={ButtonGroup}
                            title={userDetails?.Settings?.homeViz ?? "Space Cockpit"}
                            id="bg-nested-dropdown"
                            data-testid="settings-homeViz-dropdown"
                            variant="dark"
                        >
                            <div style={{
                                maxHeight: "30vh",
                                overflow: "scroll"
                            }}>
                                {options.map((name) => (
                                    <Dropdown.Item
                                        eventKey={name}
                                        onClick={(e) => saveSettings({homeViz: name})}
                                        data-testid={`settings-homeViz-${name.replace(/\s+/g, '-')}`}
                                        key={`dropdown-homeViz-${name.replace(/\s+/g, '-')}`}
                                    >
                                        {name}
                                    </Dropdown.Item>
                                ))}
                            </div>
                        </DropdownButton>
                    </div>
                </div>

                <div style={{
                    minHeight: "40px",
                    display: "flex",
                    alignItems: "center",
                    gap: "16px"
                }}>
                    <div style={{ width: "400px" }}>
                        <div className="d-flex align-items-center">
                            <p className="text-light mb-0">Pre-load Space Cockpit in background:</p>
                            <InfoTooltip text="Set to 'No' to improve performance on slower systems." />
                        </div>
                    </div>
                    <div style={{height: "38px"}}>
                        <DropdownButton
                            data-bs-theme="dark"
                            as={ButtonGroup}
                            title={userDetails?.Settings?.preloadSC ?? "Yes"}
                            id="bg-nested-dropdown"
                            variant="dark"
                        >
                            {["Yes", "No"].map((name) => (
                                <Dropdown.Item
                                    eventKey={name}
                                    onClick={(e) => saveSettings({preloadSC: name})}
                                    key={`dropdown-preloadSC-${name.replace(/\s+/g, '-')}`}
                                >
                                    {name}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </div>
                </div>

                <div style={{
                    minHeight: "40px",
                    display: "flex",
                    alignItems: "center",
                    gap: "16px"
                }}>
                    <div style={{ width: "400px" }}>
                        <div className="d-flex align-items-center">
                            <p className="text-light mb-0">Keep Space Cockpit loaded in background:</p>
                            <InfoTooltip text="Set to 'No' to improve performance on slower systems." />
                        </div>
                    </div>
                    <div style={{height: "38px"}}>
                        <DropdownButton
                            data-bs-theme="dark"
                            as={ButtonGroup}
                            title={userDetails?.Settings?.persistentSC ?? "Yes"}
                            id="bg-nested-dropdown"
                            variant="dark"
                        >
                            {["Yes", "No"].map((name) => (
                                <Dropdown.Item
                                    eventKey={name}
                                    onClick={(e) => saveSettings({persistentSC: name})}
                                    key={`dropdown-persistentSC-${name.replace(/\s+/g, '-')}`}
                                >
                                    {name}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </div>
                </div>
            </Accordion.Body>
        </Accordion.Item>
    );
}

// --- Country Assignment Component Section ---
const CountryAssignmentSettings = () => {
    const { user, userDetails, setUserDetails, postData } = useContext(AppState);
    const [showModal, setShowModal] = useState(false);

    const currentRed = userDetails?.Constellations?.redCountries || [];
    const currentBlue = userDetails?.Constellations?.blueCountries || [];

    const saveCountrySettings = async (newRed, newBlue) => {
        const updatedConstellations = {
            ...(userDetails?.Constellations || {}),
            redCountries: newRed,
            blueCountries: newBlue,
        };

        // Update local state immediately for responsiveness
        setUserDetails((details) => ({
            ...details,
            Constellations: updatedConstellations
        }));

        // Persist to backend if not guest
        if (!user.isGuest) {
            try {
                await postData("user-constellations", updatedConstellations, "PATCH");
            } catch (error) { 
                console.error("Failed to update country assignments:", error);
            }
        }
    };

    const renderSelectedCountries = (countries) => {
        if (!countries || countries.length === 0) {
            return <span className="text-muted fst-italic">None selected</span>;
        }
        // Map codes to names for display
        return countries.map(code => COUNTRY_CODE_MAP[code] || code).join(', ');
    };

    return (
        <Accordion.Item eventKey="countryAssignments" className="bg-dark border-0 mt-3">
            <Accordion.Header className="settings-accordion-header">
                <h4 className="settings-section-title m-0">COUNTRY TEAMS</h4>
            </Accordion.Header>
            <Accordion.Body className={accordionBodyClass} style={{overflow: "hidden"}}>
                 <div className="p-3 mb-2 rounded" style={{ backgroundColor: "#1a1d20" }}>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <p className="mb-0 text-white">Alerts will be grouped into RED or BLUE based on these country assignments.</p>
                        <Button 
                            variant="outline-primary" 
                            size="sm"
                            onClick={() => setShowModal(true)} 
                        >
                            Edit Assignments
                        </Button>
                    </div>
                    <div className="mb-2">
                        <strong className="text-danger me-2">Red Countries:</strong>
                        <span className="text-white">{renderSelectedCountries(currentRed)}</span>
                    </div>
                    <div>
                        <strong className="text-primary me-2">Blue Countries:</strong>
                        <span className="text-white">{renderSelectedCountries(currentBlue)}</span>
                    </div>
                </div>
                <CountrySelectionModal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    countryMap={COUNTRY_CODE_MAP}
                    initialRedCountries={currentRed}
                    initialBlueCountries={currentBlue}
                    onSave={saveCountrySettings}
                />
            </Accordion.Body>
        </Accordion.Item>
    );
}
// --- End Country Assignment Component Section ---

function Settings() {
    const { user, getData, satellites } = useContext(AppState);
    const [sources, setSources] = useState([]);
    const [activeKeys, setActiveKeys] = useState([]);

    useEffect(() => {
        if(window.location.hash?.length > 1) {
            const hash = window.location.hash.substring(1);
            if (["constellations", "windu", "profiles", "general", "countryAssignments"].includes(hash)) {
                setActiveKeys([hash]);
            }
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [sourceData] = await Promise.all([
                    getData("providers"),
                ]);

                if(sourceData) {
                    setSources(sourceData?.sort());
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        if(!user.isGuest) {
            fetchData();
        }
    }, [getData, user]);

    return (  
        <>
            <Header
                showSideBarMenu={true}
                showDashboardMenu={false}
                height={56} 
                className="pb-5"
            />
            <div id="page-background">
                <section className="settings-section">
                    <Accordion 
                        activeKey={activeKeys}
                        onSelect={(key) => {
                             if (typeof key === 'string') {
                                setActiveKeys(prevKeys => 
                                    prevKeys.includes(key) 
                                        ? prevKeys.filter(k => k !== key) 
                                        : [...prevKeys, key]
                                );
                            } else { 
                                setActiveKeys(key || []);
                            }
                        }}
                        className="mb-3 settings-accordion"
                        alwaysOpen
                    >
                        {/* Constellations Section */}
                        <Accordion.Item eventKey="constellations" className="bg-dark border-0">
                            <Accordion.Header className="settings-accordion-header">
                                <h4 className="settings-section-title m-0">CONSTELLATIONS</h4>
                            </Accordion.Header>
                            <Accordion.Body className={accordionBodyClass} style={{overflow: "hidden"}}>
                                <ConstellationSettingsGroup satellites={satellites ?? []} />
                            </Accordion.Body>
                        </Accordion.Item>

                        {/* Country Assignments Section */}
                        <CountryAssignmentSettings />

                        {/* Windu Section */}
                        <Accordion.Item eventKey="windu" className="bg-dark border-0 mt-3">
                            <Accordion.Header className="settings-accordion-header">
                                <h4 className="settings-section-title m-0">WINDU</h4>
                            </Accordion.Header>
                            <Accordion.Body className={accordionBodyClass}>
                                <p className="text-light">Windu is currently in development. Coming soon.</p>
                            </Accordion.Body>
                        </Accordion.Item>

                        {/* Profiles Section */}
                        <ProfileSettings satellites={satellites ?? []} sources={sources} />

                        {/* General Settings Section */}
                        <HomeSettings />
                    </Accordion>
                </section>
            </div>
        </>
    );
}

export default Settings;
