import React, { useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AlertRow from './AlertRow';
import AlertModal from './AlertModal';

const tableContainerStyle = {
    overflowY: 'auto',
};

const AlertsTable = ({ 
    color,
    constellations,
    alerts,
    loading,
    tableStyle,
    setSCScene,
    selectedAlertId,
    setSelectedAlertId,
    blueAlerts,
    patchData,
    showAcknowledged,
    setShowAcknowledged
}) => {
    const navigate = useNavigate();
    const [showAcknowledgeModal, setShowAcknowledgeModal] = useState(false);
    const [acknowledging, setAcknowledging] = useState(false);
    const [selectedAlert, setSelectedAlert] = useState(null);

    const handleAcknowledge = async (alert) => {
        setSelectedAlert(alert);
        setShowAcknowledgeModal(true);
    };

    const confirmAcknowledge = async () => {
        try {
            setAcknowledging(true);
            await patchData('alerts', {
                "unique_id": selectedAlert.UniqueId
            });
            toast.success('Alert acknowledged successfully');
            // Toggle showAcknowledged to trigger a reload
            setShowAcknowledged(current => !current);
            setTimeout(() => {
                setShowAcknowledged(current => !current);
            }, 100);
        } catch (error) {
            console.error('Error acknowledging alert:', error);
            toast.error('Failed to acknowledge alert');
        } finally {
            setAcknowledging(false);
            setShowAcknowledgeModal(false);
            setSelectedAlert(null);
        }
    };

    // Get all satellites from Blue Team alerts' Data objects
    const allBlueAlertSats = [...new Set(
        blueAlerts
            .map(alert => alert.Data?.satnos || [])
            .flat()
            .filter(Boolean)
    )];

    if (Object.keys(constellations || {}).length === 0) {
        const colorText = color.toLowerCase();
        return (
            <div className="text-center p-4">
                <p className="mb-2">No {colorText} constellations defined</p>
                <Button 
                    variant={color === "Blue" ? "outline-primary" : "outline-danger"}
                    size="sm"
                    onClick={() => navigate('/settings#constellations')}
                >
                    Add {color} Constellation
                </Button>
            </div>
        );
    }

    if (loading) {
        return (
            <div className="text-center p-4 d-flex align-items-center justify-content-center" style={{ height: '52px' }}>
                <div>
                    <div className="spinner-border spinner-border-sm me-2" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    Loading alerts...
                </div>
            </div>
        );
    }

    if (alerts.length === 0) {
        return (
            <div className="text-center p-4 d-flex align-items-center justify-content-center" style={{ height: '52px' }}>
                No alerts
            </div>
        );
    }

    return (
        <div 
            role="region" 
            aria-label={color.toLowerCase() + ' constellation alerts'}
            style={tableStyle}
        >
            <div style={tableContainerStyle}>
                <Table variant="dark" hover>
                    <thead>
                        <tr>
                            <th>Created</th>
                            <th>Description</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {alerts.map((alert) => (
                            <AlertRow
                                key={alert.UniqueId}
                                alert={alert}
                                isSelected={selectedAlertId === alert.UniqueId}
                                setSelectedAlertId={setSelectedAlertId}
                                setSCScene={setSCScene}
                                allBlueAlertSats={allBlueAlertSats}
                                onView={() => {
                                    const alertPath = '/alert/' + alert.UniqueId;
                                    navigate(alertPath);
                                }}
                                onAcknowledge={() => handleAcknowledge(alert)}
                            />
                        ))}
                    </tbody>
                </Table>
            </div>

            <AlertModal
                show={showAcknowledgeModal}
                onHide={() => {
                    setShowAcknowledgeModal(false);
                    setSelectedAlert(null);
                }}
                onConfirm={confirmAcknowledge}
                acknowledging={acknowledging}
            />
        </div>
    );
};

export default AlertsTable; 