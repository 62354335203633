import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getType, getCardColor } from '../../lib/context/AppProvider';
import { getRelativeTime } from '../../utils/time';

const NotsoRow = ({ notso, setSCScene, navigate }) => {
    const type = getType(notso.EventClass);
    const color = getCardColor(type);
    const hasSatNos = notso.SatNos && notso.SatNos.length > 0;
    
    const rowStyle = {
        borderLeft: '4px solid var(--bs-' + color + ')',
        backgroundColor: 'transparent',
        cursor: hasSatNos ? "pointer" : undefined
    };

    const handleRowClick = () => {
        if (hasSatNos) {
            setSCScene({
                sats: notso.SatNos,
                focus: notso.Satnos?.[0] ?? null,
                time: notso.CreatedAt
            });
        }
    };

    const tooltipOverlay = !hasSatNos ? (
        <Tooltip>
            SatNos have not been provided for this alert
        </Tooltip>
    ) : <></>;

    const handleViewClick = (e) => {
        e.stopPropagation();
        const eventPath = '/event/' + notso.IdUdl;
        navigate(eventPath);
    };

    return (
        <tr style={rowStyle} onClick={handleRowClick}>
            <td>{getRelativeTime(notso.CreatedAt)}</td>
            <td>{notso.EventClass}</td>
            <td>
                <OverlayTrigger placement="left" overlay={tooltipOverlay}>
                    <span>
                        <Button 
                            variant="primary" 
                            size="sm"
                            onClick={handleViewClick}
                            disabled={!hasSatNos}
                        >
                            View
                        </Button>
                    </span>
                </OverlayTrigger>
            </td>
        </tr>
    );
};

export default NotsoRow; 