import { useCallback, useEffect, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap"
import '../../styles/TlePicker.css';

const columns = ["Epoch", "Age", "SatNo", "Source", "Type"];
export const SV_TYPE = "State Vector (with Cov)";
export const TLE_TYPE = "TLE";

const formatDateUTC = (datePast) => {
    const dateNow = new Date();
    datePast = new Date(datePast);
    let seconds = Math.floor((dateNow - datePast)/1000);
    let minutes = Math.floor(seconds/60);
    let hours = Math.floor(minutes/60);
    const days = Math.floor(hours/24);

    hours = hours-(days*24);
    minutes = minutes-(days*24*60)-(hours*60);
    seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);

    return (days > 0 ? days+"d " : "") 
            + (hours>0 ? hours + "h " : "") 
            + (minutes>0 ? minutes +"m " : "") 
            + (seconds>0 ? seconds +"s ago" : " ago");
};

const compareValues = (a, b, ascending) => {
    if (typeof a === 'number' && typeof b === 'number') {
        return ascending ? a - b : b - a;
    } else if (a instanceof Date && b instanceof Date) {
        return ascending ? a - b : b - a;
    } else {
        return ascending ? String(a).localeCompare(String(b)) : String(b).localeCompare(String(a));
    }
}

const sortData = (data, sortOrder) => {
    let header = sortOrder.column;
    let ascending = sortOrder.ascending;

    if(header === "Age") {
        header = "Epoch";
        ascending = !ascending; // ascending Age means descending Epoch
    }

    const sorted = [...data.sort((a, b) => compareValues(a[header], b[header], ascending))];
    return sorted;
};

const getRowTleSv = (row) => {
    if(!row) return null;
    const epoch = row.Epoch.replace(" ", "T");

    const metadata = {
        epoch: epoch,
        source: row.Source
    };

    if(row.Type === SV_TYPE) {
        return {tle: null, sv: {
            epoch: epoch,
            position: [row.XPos, row.YPos, row.ZPos],
            velocity: [row.XVel, row.YVel, row.ZVel],
            covariance: JSON.stringify(row.Covariance),
            covarianceRefFrame: row.CovarianceRefFrame
        }, metadata};
    }
    else {
        return {tle: {
            line1: row.Line1,
            line2: row.Line2
        }, sv: null, metadata};
    }
};

const defaultSortOrder = {column: columns[0], ascending: false};

export const getDefaultTleRow = (data) => {
    const sortedData = sortData(data, defaultSortOrder);
    return getRowTleSv(sortedData?.[0]);
};

const RadioCell = ({selectedIndex, i, selectRow}) => {
    return (
        <td role="cell" className="text-center">
            <input
                type="radio"
                checked={i === selectedIndex}
                onChange={(e) => {
                    if(i >= 0) selectRow(i)
                }}
                aria-label={`Select TLE`}
                style={{
                    colorScheme: "dark",
                    cursor: "pointer"
                }}
            />
        </td>
    )
};

export const emptyTLE = {line1: "", line2: ""};

export const findSelectedRowIndex = (data, selected) => {
    if(!selected) return -1;
    return data.findIndex((row) => {
        if(row.Type === SV_TYPE && selected.sv) {
            return selected.sv.epoch === row.Epoch.replace(" ", "T") &&
                selected.sv.position[0] === row.XPos &&
                selected.sv.position[1] === row.YPos &&
                selected.sv.position[2] === row.ZPos &&
                selected.sv.velocity[0] === row.XVel && 
                selected.sv.velocity[1] === row.YVel && 
                selected.sv.velocity[2] === row.ZVel &&
                selected.sv.covariance === JSON.stringify(row.Covariance) &&
                selected.sv.covarianceRefFrame === row.CovarianceRefFrame;
        }
        if(row.Type === TLE_TYPE && selected.tle) {
            return row.Line1 === selected.tle.line1 && row.Line2 === selected.tle.line2;
        }
        return false;
    });
}

const handleDataChange = (sortedData, selected, selectRow, setSelectedIndex) => {
    if(sortedData) {
        if(!selected) {
            selectRow(0); // select first row when new first sorted data comes in
        }
        else {
            const ind = findSelectedRowIndex(sortedData, selected);
            // select row belonging to data
            if(ind >= 0) setSelectedIndex(ind);
        }
    }
};

const renderTlePicker = (params) => {
    const {sortedData, sat, textareaValue, selectRow, selectCustomTle,
        changeSortOrder, sortOrder, selected, selectedIndex} = params;

    const handleTle = (tle) => {
        const lines = tle.split('\n').map((l) => l.trim().replaceAll("\r", "")).filter((l) => l.length > 0);
        const existing = sortedData.findIndex((row) => row.Line1 === lines[0] && row.Line2 === lines[1]);
        if(existing >= 0) {
            selectRow(existing);
        }
        else {
            // custom
            /*
            1 40258U 14058A   25065.61835644 +.00000000 +00000+0 +00000+0 0 99999
            2 40258   0.6858  87.9028 0005086 252.0338  29.1171 01.00266647000007
            */
            selectCustomTle(lines[0], lines[1]);
        }
    };

    return !sortedData ? (
        <div 
            data-testid={`tle-picker-${sat}-loading`}
            style={{
                textAlign: "center"
            }}
        >
            <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            />
            &nbsp; Loading state vectors and TLEs for {sat}...
        </div>
    ) : (<div data-testid={`tle-picker-${sat}`}>
        <div 
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: "10px",
                gap: "10px",
                colorScheme: "dark"
            }}
        >
            <textarea id="tle" name="tle" rows="2" cols="69" className="tle" 
                style={{width: "100%"}}
                value={textareaValue}
                onChange={(event) => {}}
                onPaste={(event) => {
                    handleTle(event.clipboardData.getData('text'));
                }}>
            </textarea>
            <div style={{display:"none"}} id="custom-tle-descr">Custom TLE pasted</div> <br/>
        </div>
        <div style={{ 
            maxHeight: "150px", overflowY: "auto",
            fontSize: "14px"
        }} className="scrollit customscrollbar mb-3">
            <table className="tle-table">
                <thead style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#212529" }}>
                    <tr >
                        <th style={{ width: "50px" }}></th>
                        {columns.map((col) => 
                            <th 
                                key={`th-${col}`} 
                                scope="col"
                                style={{
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    changeSortOrder({
                                        column: col, 
                                        ascending: sortOrder.column === col ? !sortOrder.ascending : false
                                    });
                                }}
                            >
                                {col}
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {
                        selected?.custom && (
                            <tr 
                                key={`custom-row`}
                                style={{cursor: "pointer"}}
                                className="row-selected"
                            >
                                <RadioCell 
                                    selectedIndex={-1}
                                    i={-1}
                                    selectRow={selectRow} 
                                />
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>CUSTOM</td>
                                <td>TLE</td>
                            </tr>
                        )
                    }
                    {sortedData.map((row, i) => {
                        return (
                            <tr 
                                key={`${row.Epoch}-${row.Source}-${row.Type}`}
                                style={{cursor: "pointer"}}
                                className={selectedIndex === i ? "row-selected" : ""}
                                onClick={() => selectRow(i)}
                                data-testid={`row-${i}`}
                            >
                                <RadioCell 
                                    selectedIndex={selectedIndex}
                                    i={i}
                                    selectRow={selectRow} 
                                />
                                <td>{row.Epoch}</td>
                                <td>{formatDateUTC(row.Epoch)}</td>
                                <td>{row.SatNo}</td>
                                <td>{row.Source}</td>
                                <td>{row.Type}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    </div>);
}

const TlePicker = ({sat, index, data, setSatTle, selected}) => {
    const [sortedData, setSortedData] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [sortOrder, setSortOrder] = useState(defaultSortOrder);

    const selectRow = useCallback((index) => {
        setSelectedIndex(index);
        const tleSv = getRowTleSv(sortedData?.[index]);
        if(tleSv) {
            setSatTle(sat, tleSv.tle, tleSv.sv, tleSv.metadata);
        }
        else {
            setSatTle(sat, emptyTLE, null, null);
        }
    }, [sat, sortedData, setSatTle]);

    const selectCustomTle = useCallback((line1, line2) => {
        setSelectedIndex(-1);
        const metadata = {
            source: "Custom"
        };
        setSatTle(sat, {line1, line2}, null, metadata, {isCustom: true, index});
    }, [sat, setSatTle, index]);

    const changeSortOrder = useCallback((order) => {
        setSortOrder(order);
        setSortedData(sortData(data, sortOrder));
        selectRow(0);
    }, [data, selectRow, sortOrder]);

    useEffect(() => {
        if(data) {
            setSortedData(sortData(data, sortOrder));
        }
    }, [data, setSortedData, sortOrder]);

    useEffect(() => {
        if(selected?.custom) {
            setSelectedIndex(-1);
        }
    }, [selected]);

    useEffect(() => {
        handleDataChange(sortedData, selected, selectRow, setSelectedIndex);
    }, [sortedData, selectRow, selected, setSatTle, sat]);

    const textareaValue = useMemo(() => {
        if(selected?.tle) return `${selected.tle.line1}\n${selected.tle.line2}`;
        if(selected?.sv) return "A state vector is selected";
        return "";
    }, [selected]);

    return renderTlePicker({sortedData, sat, textareaValue, selectRow, selectCustomTle,
        changeSortOrder, sortOrder, selected, selectedIndex});
}

export default TlePicker;