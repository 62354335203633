import React, { useContext } from 'react';
import Header from "../components/Header";
import { AppState } from '../lib/context/AppProvider';

function Chat() {
    const { isLocalDev } = useContext(AppState);
    const chatUrl = isLocalDev ? "https://chat.saberdevusa.xyz" : window.location.origin.replace("sbms","chat");
    
    return (  
        <div data-testid="home-container" id="home-container">
            <Header
                showSideBarMenu={true}
                showDashboardMenu={false}
                height={56} 
                className="pb-5"
            />
            <iframe 
                title="SpaceCockpit"
                src={chatUrl}
                style={{
                    width: '100%',
                    height: 'calc(100vh - 56px - 12px)',
                    border: 'none'
                  }}
                allow='geolocation; clipboard-write'
            ></iframe>
        </div>
    );
};

export default Chat;
